import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { GridDataResult } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { ProjectSetupMilestoneService } from './project-setup-milestone.service';
import { UIControlTyp } from 'src/app/core/models/UIControlTyp';
import { ProjectMilestone } from 'src/app/core/models/ProjectMilestone';

@Component({
  selector: 'fdb-project-setup-milestone',
  templateUrl: './project-setup-milestone.component.html',
  styleUrls: ['./project-setup-milestone.component.scss'],
  providers: [
    ProjectSetupMilestoneService
  ]
})
export class ProjectSetupMilestoneComponent implements OnChanges {
  @Input() projectId = 0;
  public UIControlTyp = UIControlTyp;
  public view: Observable<GridDataResult>;
  public gridState: State = {
    sort: [],
    skip: 0,
    take: 10
  };
  editedRowIndex: any;
  editedRow: any;
  
  constructor(private formBuilder: FormBuilder, public componentService: ProjectSetupMilestoneService) {
    this.componentService.reset();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.projectId.currentValue > 0) {
      this.initValues();
    }
  }
  public initValues() {
    this.view = this.componentService
      .pipe(
        map(data =>  process(data, this.gridState))
      );

    this.componentService.read(true, this.projectId);
  }

  public onStateChange(state: State) {
    this.gridState = state;
    this.componentService.read(true, this.projectId);
  }

  public editHandler({ sender, rowIndex, dataItem }) {
    this.closeEditor(sender);

    this.editedRowIndex = rowIndex;
    this.editedRow = Object.assign({}, dataItem);

    sender.editRow(rowIndex, this.createFormGroup(dataItem));
  }

  public removeHandler({ sender, dataItem }) {
    this.componentService.remove(dataItem);
    this.componentService.saveChanges();
    sender.cancelCell();
  }

  public addHandler({ sender }) {
    const newItem = new ProjectMilestone();
    newItem.projectId = this.projectId;
    newItem.plannedDate = null;
    newItem.actualDate = null;
    newItem.progressInPercent = 0;
    sender.addRow(this.createFormGroup(newItem));
  }

  public cancelHandler({ sender, rowIndex }) {
    this.closeEditor(sender, rowIndex);
  }

  public saveHandler({ sender, formGroup, rowIndex, isNew }) {
    if (formGroup.valid) {
      if (isNew) {
        this.componentService.create(formGroup.value);
      } else {
        this.componentService.assignValues(this.editedRow, formGroup.value);
        this.componentService.update(this.editedRow);
      }
      this.closeEditor(sender, rowIndex);
      this.componentService.saveChanges();
    }
  }

  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.editedRow = undefined;
  }

  public createFormGroup(dataItem: ProjectMilestone): FormGroup {
    return this.formBuilder.group({
      id: [dataItem.id, Validators.required],
      projectId: [dataItem.projectId, Validators.required],
      name: [dataItem.name, Validators.required],
      plannedDate: dataItem.plannedDate,
      actualDate: dataItem.actualDate,
      progressInPercent: [dataItem.progressInPercent, Validators.min(0)]
    });
  }

  public checkName(name: string, id: number): boolean {
    return this.componentService.hasName(name, id);
  }
}
