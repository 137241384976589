import { AcademicPartner } from './../../core/models/AcademicPartner';
import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';

import { GridDataResult } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { ListAcademicPartnerService } from './list-academic-partner.service';

@Component({
  selector: 'fdb-list-academicpartner',
  templateUrl: './list-academicpartner.component.html',
  styleUrls: ['./list-academicpartner.component.scss'],
  providers: [
    ListAcademicPartnerService
  ]
})
export class ListAcademicPartnerComponent implements OnInit {

  public view: Observable<GridDataResult>;
  public gridState: State = {
    sort: [],
    skip: 0,
    take: 10
  };
  editedRowIndex: any;
  editedRow: any;

  constructor(private formBuilder: FormBuilder, public componentService: ListAcademicPartnerService) {
  }

  public ngOnInit(): void {
    this.view = this.componentService.pipe(
      map(data => process(data, this.gridState))
    );
    this.componentService.read(true);
  }

  public onStateChange(state: State) {
    this.gridState = state;
    this.componentService.read(true);
  }

  public editHandler({ sender, rowIndex, dataItem }) {
    this.closeEditor(sender);

    this.editedRowIndex = rowIndex;
    this.editedRow = Object.assign({}, dataItem);

    sender.editRow(rowIndex, this.createFormGroup(dataItem));
  }

  public addHandler({ sender }) {
    sender.addRow(this.createFormGroup(new AcademicPartner()));
  }

  public cancelHandler({ sender, rowIndex }) {
    this.closeEditor(sender, rowIndex);
  }

  public saveHandler({ sender, formGroup, rowIndex, isNew }) {
    if (formGroup.valid) {
      if (isNew) {
        this.componentService.create(formGroup.value);
      } else {
        this.componentService.assignValues(this.editedRow, formGroup.value);
        this.componentService.update(this.editedRow);
      }
      this.closeEditor(sender, rowIndex);
      this.componentService.saveChanges();
    }
  }

  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.editedRow = undefined;
  }

  public createFormGroup(dataItem: AcademicPartner): FormGroup {
    return this.formBuilder.group({
      id: [dataItem.id, Validators.required],
      name: [dataItem.name, Validators.required],
      isActive: [dataItem.isActive]
    });
  }

  public checkName(name: string, id: number): boolean {
    return this.componentService.hasName(name, id);
  }
}
