import { AzurePersonRoles } from 'src/app/core/models/AzurePersonRoles';
import { AppConfigService } from './../../core/services/app-config.service';
import { Component, OnInit } from '@angular/core';
import { AzurePerson } from 'src/app/core/models/AzurePerson';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'fdb-import-information',
  templateUrl: './import-information.component.html',
  styleUrls: ['./import-information.component.scss']
})
export class ImportInformationComponent implements OnInit {
  private currentUser: AzurePerson;
  public items: any;
  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.currentUser = this.route.snapshot.data.currentUser;
    if (this.currentUser) {
      this.ladeWerte();
    }
  }

  ladeWerte() {
    const roles = this.currentUser.roles.map(role => AzurePersonRoles[role]);
    this.items = [
      { key: 'MSAL.clientID', value: AppConfigService.settings.msal.clientID },
      { key: 'MSAL.authority', value: AppConfigService.settings.msal.authority },
      { key: 'MSAL.redirectUri', value: AppConfigService.settings.msal.redirectUri },
      { key: 'MSAL.validateAuthority', value: AppConfigService.settings.msal.validateAuthority },
      { key: 'MSAL.cacheLocation', value: AppConfigService.settings.msal.cacheLocation },
      { key: 'MSAL.postLogoutRedirectUri', value: AppConfigService.settings.msal.postLogoutRedirectUri },
      { key: 'MSAL.navigateToLoginRequestUrl', value: AppConfigService.settings.msal.navigateToLoginRequestUrl },
      { key: 'MSAL.popUp', value: AppConfigService.settings.msal.popUp },
      { key: 'MSAL.protectedResourceMap', value: AppConfigService.settings.msal.protectedResourceMap },
      { key: 'MSAL.clientID', value: AppConfigService.settings.msal.clientID },
      { key: 'MSAL.piiLoggingEnabled', value: AppConfigService.settings.msal.piiLoggingEnabled },
      { key: 'CurrentUser.email', value: this.currentUser.email },
      { key: 'CurrentUser.faxNumber', value: this.currentUser.faxNumber },
      { key: 'CurrentUser.roles', value: roles },
      { key: 'CurrentUser.id', value: this.currentUser.id },
      { key: 'CurrentUser.image', value: this.currentUser.image },
      { key: 'CurrentUser.isActive', value: this.currentUser.isActive },
      { key: 'CurrentUser.name', value: this.currentUser.name },
      { key: 'CurrentUser.phoneNumber', value: this.currentUser.phoneNumber },
    ];
  }

}
