import { DDBase } from './DDBase';
import { UserModel } from '../services/api-clients-generated.service';
export class ProjectTeamMember extends DDBase {
  projectId: number;
  userId: number;
  image: string;
  email: string;
  phone: string;
  fax: string;

  static mapFromUserModel(userModel: UserModel, projectId: number) {
    const newItem = new ProjectTeamMember();
    newItem.id = userModel.id;
    newItem.projectId = projectId;
    newItem.userId = userModel.id;
    newItem.name = userModel.lastName + ', ' + userModel.firstName;
    newItem.image = userModel.imageUrl;
    newItem.email = userModel.email;
    newItem.phone = userModel.phone;
    newItem.email = userModel.email;
    newItem.fax = userModel.fax;
    return newItem;
  }
  static mapFromUserModels(items: UserModel[], projectId: number): ProjectTeamMember[] {
    return items.map(item => ProjectTeamMember.mapFromUserModel(item, projectId));
  }
}
