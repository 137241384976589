import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SharedService } from '../shared.service';
import { PublicFunding } from 'src/app/core/models/PublicFunding';
import { UIControlTyp } from 'src/app/core/models/UIControlTyp';

@Component({
  selector: 'fdb-public-funding',
  templateUrl: './public-funding.component.html',
  styleUrls: ['./public-funding.component.scss']
})
export class PublicFundingComponent {
  public UIControlTyp = UIControlTyp;
  /** Hiermit können Sie den UIControlTyp entscheiden => Default DropDownList */
  @Input() uiController: UIControlTyp = UIControlTyp.DropDownList;
  // tslint:disable-next-line: no-input-rename
  @Input('values')
  get values() {
    const val = this.uiController === UIControlTyp.MultiSelectList ?
      this.PublicFundings.map(unit => Number(unit.id)) :
      Number(this.PublicFunding.id);
    return val;
  }
  set values(val: number | (string | number)[]) {
    this.uiController === UIControlTyp.MultiSelectList ?
      this.PublicFundings = (val as (string | number)[]).map(id => this.getObjectFromList(Number(id))) :
      this.PublicFunding = this.getObjectFromList(val as number);
  }
  @Output() valuesChange = new EventEmitter();

  /** Enthält die ausgewählten Werte bei UIControlTyp.MultiSelectList */
  public PublicFundings: PublicFunding[] = [];
  /** Enthält die ausgewählten Wert bei UIControlTyp.DropDownList */
  public PublicFunding: PublicFunding;
  /** Enthält die Werte die angezeigt werden dürfen */
  public PublicFundingList: PublicFunding[] = [];
  /** Enthält die Originalliste mit allen Werten */
  private OriginalValues: PublicFunding[] = [];

  constructor(private modulService: SharedService) {
    this.initPublicFunding();
  }

  initPublicFunding() {
    this.modulService.getPublicFundings()
      .subscribe(apiResult => {
        if (!apiResult.isSuccessful) {
          this.modulService.showNotification(apiResult.message, 'error');
        }
        if (apiResult.payload) {
          this.OriginalValues = apiResult.payload;
          this.PublicFundingList = this.OriginalValues.filter(s => s.isActive === true);
          if (this.uiController === UIControlTyp.DropDownList) {
            this.PublicFundingList.unshift(new PublicFunding().getEmptyModel());
          }
          if (this.uiController === UIControlTyp.MultiSelectList) {
            this.getRefreshSelectedValue();
          }
        }
      },
      err => this.modulService.showNotification(err, 'error'));
  }

  filterPublicFundingList(value: string) {
    this.PublicFundingList = this.OriginalValues
                                .filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1 && s.isActive === true);
  }

  valueChanged(event: any): void {
    this.valuesChange.emit(this.values);
  }

  getObjectFromList(id: number): PublicFunding {
    if (id === 0) { return new PublicFunding().getEmptyModel(); }
    const find = this.OriginalValues.find(unit => unit.id === id);
    return find ? find : new PublicFunding().setId(id);
  }
  getName() {
    const find = this.getObjectFromList(Number(this.PublicFunding.id));
    return find ? find.name : '';
  }
  getRefreshSelectedValue() {
    this.PublicFundings = this.PublicFundings.map(x => this.OriginalValues.find(y => y.id === x.id));
  }
}
