import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'fdb-alert-message',
  templateUrl: './alert-message.component.html',
  styleUrls: ['./alert-message.component.scss']
})
export class AlertMessageComponent implements OnInit {
  @Input() header = '';
  @Input() content = '';
  @Input() feed = '';
  @Input() type: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark' = 'info';

  public alertClass = 'alert alert-info';

  constructor() { }

  ngOnInit() {
    this.alertClass = 'alert alert-' + this.type;
  }

}
