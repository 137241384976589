<div class="row" style="margin-top: 50px;">
  <div class="col-sm center-block">
    <h4>Kostentragung (in 1.000 Euro)</h4>
  </div>
  <div class="col-sm d-flex flex-row-reverse">
    <ng-container *ngIf="buttonDisabled">
        <button class="btn btn-light" (click)="createEditValues()">Kosten planen</button>
    </ng-container>
  </div>
</div>
<div class="row" style="margin-top: 20px">
  <div class="col-sm-12">
    <kendo-grid [data]="GridEditData" [height]="200"
                (dataStateChange)="onStateChange($event)"
                (edit)="editHandler($event)"
                (cancel)="cancelHandler($event)"
                (save)="saveHandler($event)"
                >
      <ng-template ngFor [ngForOf]="getColumns()" let-column>
        <kendo-grid-column field="{{column.field}}" title="{{column.title}}" [width]="column.width">
          <ng-template *ngIf="column.isAction" kendoGridCellTemplate let-formGroup="formGroup" let-isNew="isNew" let-rowIndex="rowIndex">
            <button kendoGridEditCommand [disabled]="!isRowEditable(rowIndex, project.stateId)">
              <span class="k-icon k-i-pencil"></span>
            </button>
            <button kendoGridSaveCommand [disabled]="formGroup?.invalid">
              <ng-container *ngIf='isNew'>
                <span class="fa fa-pencil"></span><span class="k-icon k-i-plus"></span>
              </ng-container>
              <ng-container *ngIf='!isNew'>
                <span class="fa fa-pencil"></span><span class="k-icon k-i-save"></span>
              </ng-container>
            </button>
            <button kendoGridCancelCommand>
              <span class="fa fa-pencil"></span><span class="k-icon k-i-close"></span>
            </button>
          </ng-template>
        </kendo-grid-column>
      </ng-template>
    </kendo-grid>
  </div>
</div>
<div class="row" style="margin-top: 50px; ">
  <div class="col-xs-12 col-md-12">
  </div>
</div>
