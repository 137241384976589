import { AcademicPartnerModel, InsertAcademicPartnerModel } from '../services/api-clients-generated.service';
import { DDBase } from './DDBase';

export class AcademicPartner extends DDBase {

  static mapFromAcademicPartnerModels(items: AcademicPartnerModel[]): AcademicPartner[] {
    return items.map(item => {
      const newItem = new AcademicPartner();
      newItem.id = item.id;
      newItem.name = item.name;
      newItem.isActive = item.isActive;
      return newItem;
    });
  }
  static mapToAddAcademicPartnerModels(items: AcademicPartner[]): InsertAcademicPartnerModel[] {
    return items.map(item => {
      const newItem = new InsertAcademicPartnerModel();
      newItem.name = item.name;
      return newItem;
    });
  }
  static mapToAcademicPartnerModels(items: AcademicPartner[]): AcademicPartnerModel[] {
    return items.map(item => {
      const newItem = new AcademicPartnerModel();
      newItem.id = Number(item.id);
      newItem.name = item.name;
      newItem.isActive = item.isActive;
      return newItem;
    });
  }
}
