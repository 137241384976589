import { LogBookApiClient } from './../../../service/apiClients/LogBookApiClient';
import { Injectable } from '@angular/core';
import { ProjectService } from '../../project.service';
import { ListBehaviorService } from 'src/app/service/list-behavior.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectLogbookService extends ListBehaviorService {

  constructor(modulService: ProjectService) {
    super(new LogBookApiClient(modulService), modulService);
  }

  CreateAnchorTags (text: string): string {
    if (text === null || text === "") {
      return "";
    }

    let regExAllWords = /[^\s]+/g;
    regExAllWords.flags
    let result = text.replace(regExAllWords, function replacer(word, pos, completeText): string {
      let url = word;
      let dot = "";
      if (word.endsWith(".")) {
        dot = ".";
        word = word.substring(0, word.length-1);
      }
      try {
        let potentialUrl: URL = null;
        if (word.toLowerCase().startsWith("www.")) {
          potentialUrl = new URL("https://" + word);
        } else {
          potentialUrl = new URL(word);
        }
        if (potentialUrl.protocol.startsWith("http")) {
          let friendlyLink = potentialUrl.hostname;
          if (potentialUrl.port != "") {
            friendlyLink = friendlyLink + ":" + potentialUrl.port;
          }
          //potentialUrl.hostname + potentialUrl.pathname;
          if (potentialUrl.pathname.length > 1) {
            if (potentialUrl.pathname.lastIndexOf("/") < potentialUrl.pathname.lastIndexOf(".")) {
              friendlyLink = potentialUrl.pathname.substring(potentialUrl.pathname.lastIndexOf("/") + 1);
            } else {
              friendlyLink = friendlyLink + potentialUrl.pathname;
            }
          }

          url =  "<a href=\"" + potentialUrl.href + "\" target=\"_blank\" class=\"gridlink\">" + friendlyLink + "</a>" + dot;
        }
      } catch(e) {
        // ignore error
      }
      return url;
    });

    // mit RegEx
    // aus URL zunächst das https:// vorne dran setzen, falls notwendig (also an URLs der Form " www.\w.\w " oder der Form " www.\w.\w/ " )
    // aus URLs, die mit http:// oder https:// beginnen ein <A> Tag bauen, das die komplette URL im href-Parameter haben, und im Text nur das Dokument


    return result;
  }
}
