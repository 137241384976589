<h4 style="margin-top: 50px;">Transferverantwortlicher</h4>
<form class="form col-xs-12">
  <div class="row">
    <div class="col-xs-12 col-md-12">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group row">
            <div class="col-md-4">
              <label class="label" for="supervisor">Verantwortlicher
                <ng-container *ngIf="allHelpToolTips && allHelpToolTips.projectTransferResponsible"> 
                  <img [src]="imgname" [title]="allHelpToolTips.projectTransferResponsible" />
                </ng-container>
              </label>
            </div>
            <div class="col-md-8">
              <fdb-project-transfer-manager [uiController]="UIControlTyp.DropDownList"
                [(values)]='project.transferManagerId' (objectChange)="onChangeTransferManager($event)">
              </fdb-project-transfer-manager>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-md-4">
              <label class="label" for="orgUnit">Org.-Einheit
                <ng-container *ngIf="allHelpToolTips && allHelpToolTips.projectTransferUnit"> 
                  <img [src]="imgname" [title]="allHelpToolTips.projectTransferUnit" />
                </ng-container>
              </label>
            </div>
            <div class="col-md-8">
              <input class="form-control" type="text" [(ngModel)]="project.transferUnit" (change)="onModelChanged()" name="transferUnit" />
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group row">
            <div class="col-md-4">
              <label class="label" for="phoneNumber">Telefon
                <ng-container *ngIf="allHelpToolTips && allHelpToolTips.projectTransferManagerPhone"> 
                  <img [src]="imgname" [title]="allHelpToolTips.projectTransferManagerPhone" />
                </ng-container>
              </label>
            </div>
            <div class="col-md-8">
              <input class="form-control" type="text" [(ngModel)]="project.transferManagerPhone" (change)="onModelChanged()"
                name="transferManagerPhone" />
            </div>
          </div>
          <div class="form-group row">
            <div class="col-md-4">
              <label class="label" for="faxNumber">Fax
                <ng-container *ngIf="allHelpToolTips && allHelpToolTips.projectTransferManagerFax"> 
                  <img [src]="imgname" [title]="allHelpToolTips.projectTransferManagerFax" />
                </ng-container>
              </label>
            </div>
            <div class="col-md-8">
              <input class="form-control" type="text" [(ngModel)]="project.transferManagerFax" (change)="onModelChanged()"
                name="transferManagerFax" />
            </div>
          </div>
          <div class="form-group row">
            <div class="col-md-4">
              <label class="label" for="email">E-Mail
                <ng-container *ngIf="allHelpToolTips && allHelpToolTips.projectTransferManagerEmail"> 
                  <img [src]="imgname" [title]="allHelpToolTips.projectTransferManagerEmail" />
                </ng-container>
              </label>
            </div>
            <div class="col-md-8">
              <input class="form-control" type="email" [(ngModel)]="project.transferManagerEmail" (change)="onModelChanged()"
                name="transferManagerEmail" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12 col-md-12">
      <div class="form-group row">
        <div class="col-md-2">
          <label for="transferResult">Transferergebnis
            <ng-container *ngIf="allHelpToolTips && allHelpToolTips.projectTransferResult"> 
              <img [src]="imgname" [title]="allHelpToolTips.projectTransferResult" />
            </ng-container>
          </label>
        </div>
        <div class="col-md-10">
          <textarea class="form-control col-xs-12 col-md-12" [(ngModel)]="project.transferResult" (change)="onModelChanged()"  name="transferResult"></textarea>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12 col-md-12">
      <div class="form-group row">
        <div class="col-md-2">
          <label for="transferSuccess">Transfererfolg
            <ng-container *ngIf="allHelpToolTips && allHelpToolTips.projectTransferSuccess"> 
              <img [src]="imgname" [title]="allHelpToolTips.projectTransferSuccess" />
            </ng-container>
          </label>
        </div>
        <div class="col-md-10">
          <fdb-project-transfer-success [uiController]="UIControlTyp.DropDownList"
            [(values)]='project.transferSuccessId' (valuesChange)="onModelChanged()"></fdb-project-transfer-success>
        </div>
      </div>
    </div>
  </div>
</form>
