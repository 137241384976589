import { SharedService } from './../shared.service';
import { Component, Input, Output, EventEmitter} from '@angular/core';
import { UIControlTyp } from 'src/app/core/models/UIControlTyp';
import { ProjectState } from 'src/app/core/models/ProjectState';

@Component({
  selector: 'fdb-project-state',
  templateUrl: './project-state.component.html',
  styleUrls: ['./project-state.component.scss']
})
export class ProjectStateComponent{
  
  public UIControlTyp = UIControlTyp;
  /** Hiremit können Sie das UIControl auf Readonly setzen */
  @Input() readonly = false;
  /** Hiermit können Sie den UIControlTyp entscheiden => Default DropDownList */
  @Input() uiController: UIControlTyp = UIControlTyp.DropDownList;
  // tslint:disable-next-line: no-input-rename
  @Input('values')
  get values() {
    const val = this.uiController === UIControlTyp.MultiSelectList ?
      this.ProjectStates.map(unit => Number(unit.id)) :
      Number(this.ProjectState.id);
    return val;
  }
  set values(val: number | number[]) {
    this.uiController === UIControlTyp.MultiSelectList ?
      this.ProjectStates = (val as number[]).map(id => this.getObjectFromList(id)) :
      this.ProjectState = this.getObjectFromList(val as number);
  }
  @Output() valuesChange = new EventEmitter();

  /** Enthält die ausgewählten Werte bei UIControlTyp.MultiSelectList */
  public ProjectStates: ProjectState[] = [];
  /** Enthält die ausgewählten Wert bei UIControlTyp.DropDownList */
  public ProjectState: ProjectState;
  /** Enthält die Werte die angezeigt werden dürfen */
  public ProjectStateList: ProjectState[] = [];
  /** Enthält die Originalliste mit allen Werten */
  public OriginalValues: ProjectState[] = [];

  constructor(private modulService: SharedService) { this.initProjectStates(); }

  initProjectStates() {
    this.modulService.getProjectState()
      .subscribe(apiResult => {
        if (!apiResult.isSuccessful) {
          this.modulService.showNotification(apiResult.message, 'error');
        }
        if (apiResult.payload) {
          this.OriginalValues = apiResult.payload;
          this.ProjectStateList = this.OriginalValues.filter(s => s.isActive === true);
          if (this.uiController === UIControlTyp.DropDownList) {
            this.ProjectStateList.unshift(new ProjectState().getEmptyModel());
          }
          if (this.uiController === UIControlTyp.MultiSelectList) {
            this.getRefreshSelectedValue();
          }
        }
      },
        err => this.modulService.showNotification(err, 'error')
      );
  }

  filterProjectStateList(value: string) {
    this.ProjectStateList = this.OriginalValues
      .filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1 && s.isActive === true);
  }

  valueChanged(): void {
    this.valuesChange.emit(this.values);
  }

  getObjectFromList(id: number): ProjectState {
    if (id === 0) { return new ProjectState().getEmptyModel(); }
    const find = this.OriginalValues.find(unit => unit.id === id);
    return find ? find : new ProjectState().setId(id);
  }
  getName() {
    const find = this.getObjectFromList(Number(this.ProjectState.id));
    return find ? find.name : '';
  }
  getRefreshSelectedValue() {
    this.ProjectStates = this.ProjectStates.map(x => this.OriginalValues.find(y => y.id === x.id));
  }
}
