<!-- Horizontal Steppers -->
<div class="row">
  <div class="col-md-4">
    <!-- Stepers Wrapper -->
    <ul class="stepper stepper-horizontal">
      <!-- First Step -->
      <li [class.active]="displayMasterDate">
        <a (click)="goToLocation(routingPath.projectNewMasterdata)">
          <span class="circle">1</span>
          <span class="label">Stammdaten</span>
        </a>
      </li>
      <!-- Second Step -->
      <li [class.active]="displayDescription">
        <a (click)="goToLocation(routingPath.projectNewDescription)">
          <span class="circle">2</span>
          <span class="label">Beschreibung</span>
        </a>
      </li>
    </ul>
    <!-- /.Stepers Wrapper -->
  </div>
  <!-- Steppers Navigation -->

</div>

<div class="row">
  <div class="col-sm-12" [hidden]="!displayMasterDate">
    <div class="step-content">
      <div class="row mt-1">
        <div class="col-md-12 text-left">
          <h4>Stammdaten</h4>
        </div>
      </div>
      <fdb-project-masterdata [(project)]='project' [setValidation]='setValidation'></fdb-project-masterdata>
    </div>
  </div>
  <div class="col-sm-12" [hidden]="!displayDescription">
    <div class="step-content">
      <div class="row mt-1">
        <div class="col-md-12 text-left">
          <h4>Beschreibung</h4>
        </div>
      </div>
      <fdb-project-description [(project)]='project'></fdb-project-description>
    </div>
  </div>
</div>

<div class="row mt-1">
  <div class="col-md-12 text-right">
    <button class="btn btn-dark btn-sm" (click)="cancel()">Abbrechen</button>
    <button [hidden]="!displayMasterDate" class="btn btn-dark btn-sm"
      (click)="goToLocation(routingPath.projectNewDescription)">Weiter</button>
    <button [hidden]="!displayDescription" class="btn btn-dark btn-sm"
      (click)="goToLocation(routingPath.projectNewMasterdata)">Zurück</button>
    <button class="btn btn-dark btn-sm" (click)="onSubmit()">Speichern</button>
  </div>
</div>
<!-- /.Horizontal Steppers -->
