<div style="margin:20px 50px">
  <div class="row">
    <div class="col-xs-10 col-md-10">
      <ng-container *ngIf="allHelpToolTips && allHelpToolTips.projectName && editMode">
        Projektname: <img [src]="imgname" [title]="allHelpToolTips.projectName" />
      </ng-container>
      <h1>
        <fdb-switch-input [(values)]="project.name"
          [uiController]="!editMode ? UIControlTyp.GridSingleValueField : UIControlTyp.InputField"></fdb-switch-input>
      </h1>
    </div>
    <div class="col-xs-2 col-md-2">
      <button class="btn btn-light float-right"
        (click)="editHeadValue()">{{!editMode ? 'Kopfdaten anpassen' : 'Speichern'}} </button>
      <button *ngIf="isAdmin && oldState <= 2" class="btn btn-light float-right" (click)="deleteProject()">Projekt löschen</button>
    </div>
  </div>
  <div class="row">
    <div class="col-3">
      <span>Betreuer: </span>
      <span>
        <fdb-advisor [(values)]="project.advisorId"
          [uiController]="!editMode ? UIControlTyp.GridSingleValueField : UIControlTyp.DropDownList"></fdb-advisor>
      </span>
    </div>
    <div class="col-3">
      <span>Projektleiter: </span>
      <span>
        <fdb-project-manager [(values)]="project.projectManagerId"
          [uiController]="!editMode ? UIControlTyp.GridSingleValueField : UIControlTyp.DropDownList"
          (objectChange)="projectManagerChange($event)">
        </fdb-project-manager>
      </span>
    </div>
    <div class="col-3">
      <span>Telefon:
        <ng-container *ngIf="allHelpToolTips && allHelpToolTips.projectManagerPhoneNumber && editMode">
          <img [src]="imgname" [title]="allHelpToolTips.projectManagerPhoneNumber" />
        </ng-container>
      </span>
      <span>
        <fdb-switch-input [(values)]="project.projectManagerPhoneNumber"
          [uiController]="!editMode ? UIControlTyp.GridSingleValueField : UIControlTyp.InputField"></fdb-switch-input>
      </span>
    </div>
    <div class="col-3">
      <span>Fax:
        <ng-container *ngIf="allHelpToolTips && allHelpToolTips.projectManagerFaxNumber && editMode">
          <img [src]="imgname" [title]="allHelpToolTips.projectManagerFaxNumber" />
        </ng-container>
      </span>
      <span>
        <fdb-switch-input [(values)]="project.projectManagerFaxNumber"
          [uiController]="!editMode ? UIControlTyp.GridSingleValueField : UIControlTyp.InputField"></fdb-switch-input>
      </span>
    </div>
    <div class="col-3">
      <span>Verantw. EnBW Einheit:
        <ng-container *ngIf="allHelpToolTips && allHelpToolTips.enbwUnit && editMode">
          <img [src]="imgname" [title]="allHelpToolTips.enbwUnit" />
        </ng-container>
      </span>
      <span>
        <fdb-enbwunit [(values)]="project.enbwUnitId"
          [uiController]="!editMode ? UIControlTyp.GridSingleValueField : UIControlTyp.DropDownList">
        </fdb-enbwunit>
      </span>
    </div>
    <div class="col-3">
      <span>Org. Einheit:
        <ng-container *ngIf="allHelpToolTips && allHelpToolTips.projectOrgaUnit && editMode">
          <img [src]="imgname" [title]="allHelpToolTips.projectOrgaUnit" />
        </ng-container>
      </span>
      <span>
        <fdb-switch-input [(values)]="project.orgaUnit"
          [uiController]="!editMode ? UIControlTyp.GridSingleValueField : UIControlTyp.InputField"></fdb-switch-input>
      </span>
    </div>
    <div class="col-3">
      <span>E-Mail:
        <ng-container *ngIf="allHelpToolTips && allHelpToolTips.projectManagerEmail && editMode">
          <img [src]="imgname" [title]="allHelpToolTips.projectManagerEmail" />
        </ng-container>
      </span>
      <span>
        <fdb-switch-input [(values)]="project.projectManagerEmail"
          [uiController]="!editMode ? UIControlTyp.GridSingleValueField : UIControlTyp.InputField"></fdb-switch-input>
      </span>
    </div>
    <div class="col-3"><ng-container *ngIf="editMode"><br/></ng-container>
      <span>Projektnummer:</span>
      <span>{{project.number}}</span>
    </div>
    <!---->
  </div>
  <div class="row" style="margin-top: 50px;">
    <div class="col-8">
      <button [class]="activeTab === getCorrectPath(routingPath.projectDescription) ? 'btn btn-dark' : 'btn btn-light'"
        (click)="switchTabs(getCorrectPath(routingPath.projectDescription))">
        Beschreibung
      </button>
      <button [class]="activeTab === getCorrectPath(routingPath.projectLogbook) ? 'btn btn-dark' : 'btn btn-light'"
        (click)="switchTabs(getCorrectPath(routingPath.projectLogbook))">
        Logbuch
      </button>
      <button [class]="activeTab === getCorrectPath(routingPath.projectCosts) ? 'btn btn-dark' : 'btn btn-light'"
        (click)="switchTabs(getCorrectPath(routingPath.projectCosts))">
        Kostentragung
      </button>
      <button [class]="activeTab === getCorrectPath(this.routingPath.projectSetup) ? 'btn btn-dark' : 'btn btn-light'"
        (click)="switchTabs(getCorrectPath(this.routingPath.projectSetup))">
        Weitere
      </button>
      <ng-container *ngIf="project.isTransferableId === 1">
        <button [class]="activeTab === getCorrectPath(routingPath.projectTransfer) ? 'btn btn-dark' : 'btn btn-light'"
          (click)="switchTabs(getCorrectPath(routingPath.projectTransfer))">
          Transfer
        </button></ng-container>
    </div>
    <div class="col-2 float-right">
      <!-- button class="btn btn-light float-right" (click)="infoPurchase()">E-Mail Einkauf</button -->
    </div>
    <div class="col-2 float-right">
      <span>Status:</span>
      <fdb-project-state [(values)]="project.stateId"
        [uiController]="UIControlTyp.DropDownList"
        (valuesChange)="stateChanged($event)">
      </fdb-project-state>
    </div>
  </div>
  <div class="col-sm-12" [hidden]="!checkCurrentTab(routingPath.projectDescription)">
    <fdb-project-description [project]="project"
        (projectChange)="projectOnChanged($event)"
                             [allHelpToolTips]="allHelpToolTips" (projectRequested)="projectOnRequested($event)"
                             ></fdb-project-description>
  </div>
  <div class="col-sm-12" [hidden]="!checkCurrentTab(routingPath.projectLogbook)">
    <fdb-project-logbook [projectId]="project.id"></fdb-project-logbook>
  </div>
  <div class="col-sm-12" [hidden]="!checkCurrentTab(routingPath.projectCosts)">
    <fdb-project-costs [project]="project"></fdb-project-costs>
  </div>
  <div class="col-sm-12" [hidden]="!checkCurrentTab(this.routingPath.projectSetup)">
    <fdb-project-setup [projectId]="project.id"></fdb-project-setup>
  </div>
  <div class="col-sm-12" [hidden]="!checkCurrentTab(routingPath.projectTransfer)">
    <fdb-project-transfer [project]="project" [changed]="edited" (projectChange)="projectOnChanged($event)" [allHelpToolTips]="allHelpToolTips"></fdb-project-transfer>
  </div>
  <div class="row float-right">
    <div class="col-sm-12 float-right">{{ versionInfo }}</div>
  </div>
 </div>
<fdb-dialog-info-purchase [opened]="openInfoBox" (closed)="closeInfoPurchase($event)" (sendMail)="sendMailToPurchasing($event)" ></fdb-dialog-info-purchase>
