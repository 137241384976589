import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'fdb-custom-notification',
  templateUrl: './custom-notification.component.html',
  styleUrls: ['./custom-notification.component.scss']
})
export class CustomNotificationComponent {

  @Output() public ignore: EventEmitter<any> = new EventEmitter();

  public message = '!Leere Message';

  public ignoreNotification(event: Event): void {
      event.preventDefault();
      this.ignore.emit();
  }

}
