import { ProjectTeamMemberApiClient } from './../service/apiClients/ProjectTeamMemberApiClient';
import { YesNoApiClient } from './../service/apiClients/YesNoApiClient';
import { HelpToolTipApiClient } from './../service/apiClients/HelpToolTipApiClient';
import { PublicFundingApiClient } from './../service/apiClients/PublicFundingApiClient';
import { EnBWUnitApiClient } from 'src/app/service/apiClients/EnBWUnitApiClient';
import { AdvisorApiClient } from './../service/apiClients/AdvisorApiClient';
import { AcademicPartnerApiClient } from './../service/apiClients/AcademicPartnerApiClient';
import { IApiClient } from './../core/models/IApiClient';
import { Injectable } from '@angular/core';
import { AppService } from '../service/app.service';
import { Observable } from 'rxjs';
import { ProjectManagerApiClient } from '../service/apiClients/ProjectManagerApiClient';
import { ProjectStateApiClient } from '../service/apiClients/ProjectStateApiClient';
import { ProjectTypeApiClient } from '../service/apiClients/ProjectTypeApiClient';
import { TagApiClient } from '../service/apiClients/TagApiClient';
import { ProjectRiskRatingApiClient } from '../service/apiClients/ProjectRiskRatingApiClient';
import { ApiResult } from '../core/models/ApiResult';
import { AzurePersonRoles } from '../core/models/AzurePersonRoles';
import { ProjectTransferSuccesApiClient } from '../service/apiClients/ProjectTransferSuccesApiClient';

@Injectable({
  providedIn: 'root'
})
export class SharedService extends AppService {

  getAcademicPartners(includeInActive: boolean = false): Observable<ApiResult> {
    return this.getAll(new AcademicPartnerApiClient(this), includeInActive);
  }
  getAzurePerson(roles: AzurePersonRoles): Observable<ApiResult> {
    let client: IApiClient;
    if (roles === AzurePersonRoles.Advisor) {
      client = new AdvisorApiClient(this);
    } else {
      client = new ProjectTeamMemberApiClient(this);
    }
    return this.getAll(client);
  }

  getEnBWUnits(includeInActive: boolean = false): Observable<ApiResult> {
    return this.getAll(new EnBWUnitApiClient(this), includeInActive);
  }
  getHelpToolTips(): Observable<ApiResult> {
    return this.getAll(new HelpToolTipApiClient(this));
  }
  getManagers(): Observable<ApiResult> {
    return this.getAll(new ProjectManagerApiClient(this));
  }
  getProjectState(): Observable<ApiResult> {
    return this.getAll(new ProjectStateApiClient(this));
  }
  getProjectType(): Observable<ApiResult> {
    return this.getAll(new ProjectTypeApiClient(this));
  }
  getPublicFundings(): Observable<ApiResult> {
    return this.getAll(new PublicFundingApiClient(this));
  }
  getTags(): Observable<ApiResult> {
    return this.getAll(new TagApiClient(this));
  }
  getYesNos(): Observable<ApiResult> {
    return this.getAll(new YesNoApiClient(this));
  }
  getProjectRiskRatings(): Observable<ApiResult> {
    return this.getAll(new ProjectRiskRatingApiClient(this));
  }
  getTransferSuccesses(): Observable<ApiResult> {
    return this.getAll(new ProjectTransferSuccesApiClient(this));
  }
}
