import { CostAmountModel } from './../services/api-clients-generated.service';
import { Base } from './Base';
export class ProjectCostItemAmount extends Base {
  year: number;
  amount: number;

  static MapFromCostAmountModels(costAmounts: CostAmountModel[]): any {
    return costAmounts.map(amount => this.MapFromCostAmountModel(amount));
  }

  static MapFromCostAmountModel(item: CostAmountModel): ProjectCostItemAmount {
    const newItem = new ProjectCostItemAmount();
    newItem.id = item.id;
    newItem.amount = item.amount;
    newItem.year = item.year;
    return newItem;
  }
  
  static MapToUpdateCostAmountModels(costId: number, items: ProjectCostItemAmount[]): CostAmountModel[] {
    return items.map(item => this.MapToUpdateCostAmountModel(costId, item));
  }

  static MapToUpdateCostAmountModel(costId: number, item: ProjectCostItemAmount): CostAmountModel {
    const newItem = new CostAmountModel();
    newItem.id = Number(item.id);
    newItem.amount = item.amount;
    newItem.year = item.year;
    newItem.costId = costId;
    return newItem;
  }
}
