<!--The content below is only a placeholder and can be replaced.-->
<ng-progress [color]="'orange'" [thick]="true" [spinner]="false"></ng-progress>
<ng-container *ngIf="loginDisplay">
  <fdb-navigation></fdb-navigation>
  <router-outlet *ngIf="!isIframe"></router-outlet>
</ng-container>
<ng-container *ngIf="!loginDisplay">
</ng-container>


