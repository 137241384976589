import { AppService } from './../app.service';
import { IApiClient } from 'src/app/core/models/IApiClient';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IHelpToolTipClient, HelpToolTipClient } from 'src/app/core/services/api-clients-generated.service';
import { HelpToolTip } from 'src/app/core/models/HelpToolTip';
import { ApiResult } from 'src/app/core/models/ApiResult';

export class HelpToolTipApiClient implements IApiClient {
  private helpToolTipClient: IHelpToolTipClient;
  constructor(private appService: AppService) { }

  getAll(): Observable<ApiResult> {
    return this.getHelpToolTipClient().get()
      .pipe(
        map(response => {
          return this.appService.mapToApiResult(response, HelpToolTip.mapFromHelpToolTipModels(response.payload));
        })
      );
  }

  getFromId(id: number): Observable<ApiResult> {
    return this.appService.getErrorApiResult('HelpToolTip => Method not implemented\.');
  }
  getFromProjectId(projectId: number): Observable<ApiResult> {
    return this.appService.getErrorApiResult('HelpToolTip => Method not implemented\.');
  }
  create(model: any): Observable<ApiResult> {
    return this.appService.getErrorApiResult('HelpToolTip => Method not implemented\.');
  }
  createList(models: any[]): Observable<ApiResult> {
    return this.appService.getErrorApiResult('HelpToolTip => Method not implemented\.');
  }
  remove(id: number): Observable<ApiResult> {
    return this.appService.getErrorApiResult('HelpToolTip => Method not implemented\.');
  }
  removeList(ids: number[]): Observable<ApiResult> {
    return this.appService.getErrorApiResult('HelpToolTip => Method not implemented\.');
  }
  update(model: any): Observable<ApiResult> {
    return this.appService.getErrorApiResult('HelpToolTip => Method not implemented\.');
  }
  updateList(models: any[]): Observable<ApiResult> {
    return this.appService.getErrorApiResult('HelpToolTip => Method not implemented\.');
  }

  private getHelpToolTipClient(): IHelpToolTipClient {
    if (!this.helpToolTipClient) {
      this.helpToolTipClient = new HelpToolTipClient(this.appService.http, this.appService.apiUrl);
    }
    return this.helpToolTipClient;
  }
}
