import { UIControlTyp } from './../../../core/models/UIControlTyp';
import { Component, ElementRef, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewChecked } from '@angular/core';
import { Project } from 'src/app/core/models/Project.Model';
import { firstDayOfMonth, lastDayOfMonth } from '@progress/kendo-date-math';
import { NgForm } from '@angular/forms';
import { AllHelpToolTips } from 'src/app/core/models/AllHelpToolTips';
import { ProjectWorkspaceService } from 'src/app/service/project-workspace.service';
import { ProjectService } from '../../project.service';

declare var require: any;

@Component({
  selector: 'fdb-project-description',
  templateUrl: './project-description.component.html',
  styleUrls: ['./project-description.component.scss']
})
export class ProjectDescriptionComponent implements OnInit, AfterViewChecked {
  @ViewChild('form', { static: true }) form: NgForm;

  public UIControlTyp = UIControlTyp;

  @Input() project: Project;
  @Input() allHelpToolTips: AllHelpToolTips;
  @Output() projectChange = new EventEmitter();
  @Output() projectRequested = new EventEmitter();
  public graphApiCallVisible = false; // TODO: temporär
  public requestMsg: string = "Budgetdeckung wurde mit Forschungscontroller abgestimmt";

  private isAfterViewChecked = true;
  public imgname = "/assets/img/question.png";
  public urlToWorkspace : string  = '';

  constructor(
    private projectWorkspaceService: ProjectWorkspaceService,
    private modulService: ProjectService
  ) {
  }

  ngOnInit() {
    if (this.project && this.project.projectWorkspaceUrl) {
      this.urlToWorkspace = this.project.projectWorkspaceUrl;
      this.onValuesChange()
    }

    if (this.project) {
      this.graphApiCallVisible = (this.project?.projectManagerEmail === "christoph.mueller@bridging-it.de" ||
                                  this.project?.projectManagerEmail === "mohamed.habbassi@bridging-it.de" ||
                                  this.project?.projectManagerEmail === "nooshin.shokrollahi@bridging-it.de");
    }
  }


  ngAfterViewChecked(): void {
    if (this.isAfterViewChecked) {
      this.isAfterViewChecked = false;
    }
  }

  onStartDateChange(event) {
    if (event) {
      this.onValuesChange();
      this.project.startDate = firstDayOfMonth(event);
    }
  }

  onEndDateChange(event) {
    if (event) {
      this.onValuesChange();
      this.project.endDate = lastDayOfMonth(event);
    }
  }

  onValuesChange() {
      this.urlToWorkspace = this.createWebLink();
      if (!this.isAfterViewChecked) {
        this.projectChange.emit();
      }
  }

  onProjectRequest() {
    this.projectRequested.emit({msg :this.requestMsg, url:this.urlToWorkspace});
  }

  public createWebLink(): string
  {
    if (this.project && this.project.projectWorkspaceUrl) {
      //const url = this.projectWorkspaceService.normalizeSharePointLink(this.project.projectWorkspaceUrl);
      const url = this.project.projectWorkspaceUrl;
      if (url.length > 4 && url.toLowerCase().substring(0, 4) === "www.") {
        return "https://" + url;
      }
      if (url.length > 4 && url.toLowerCase().substring(0, 19) === "teams.microsoft.com") {
        return "https://" + url;
      }
      if (url.length > 4 && url.toLowerCase().substring(0, 21) === "myenbw.sharepoint.com") {
        return "https://" + url;
      }
      return url;
    }
    return "";
  }
}
