import { UIControlTyp } from './../../../core/models/UIControlTyp';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, AfterContentInit } from '@angular/core';
import { Project } from 'src/app/core/models/Project.Model';
import { AzurePerson } from 'src/app/core/models/AzurePerson';
import { AllHelpToolTips } from 'src/app/core/models/AllHelpToolTips';

declare var require: any;

@Component({
  selector: 'fdb-project-transfer',
  templateUrl: './project-transfer.component.html',
  styleUrls: ['./project-transfer.component.scss']
})
export class ProjectTransferComponent implements OnChanges {
  public UIControlTyp = UIControlTyp;
  @Input() changed: boolean;
  @Input() project: Project;
  @Input() allHelpToolTips: AllHelpToolTips;
  @Output() projectChanges = new EventEmitter<Project>();
  @Output() projectChange = new EventEmitter();

  //public imgname = require("file-loader!../../../../assets/img/question.png");
  public imgname = "/assets/img/question.png";

  ngOnChanges(): void {
    this.projectChanges.emit(this.project);
  }

  onModelChanged(){
    if (!this.changed){
      this.projectChange.emit();
      this.changed = true;
    }
  }

  onChangeTransferManager(transferManager: AzurePerson) {
    this.project.transferManagerPhone = transferManager.phoneNumber;
    this.project.transferManagerEmail = transferManager.email;
    this.project.transferManagerFax = transferManager.faxNumber;
    this.onModelChanged();
  }
}
