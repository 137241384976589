//import { element } from 'protractor';
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { UIControlTyp } from 'src/app/core/models/UIControlTyp';
import { AzurePersonRoles } from 'src/app/core/models/AzurePersonRoles';
import { AzurePerson } from 'src/app/core/models/AzurePerson';

@Component({
  selector: 'fdb-project-manager',
  templateUrl: './project-manager.component.html',
  styleUrls: ['./project-manager.component.scss']
})

export class ProjectManagerComponent {

  /** Hiermit können Sie bestimmen ob eine DropDown Liste angezeigt wird oder eine MultiSelect Eingabefeld */
  @Input() uiController: UIControlTyp = UIControlTyp.DropDownList;
  @Input() values: string | number | number[];
  roles: AzurePersonRoles = AzurePersonRoles.Any;
  @Output() valuesChange = new EventEmitter();
  @Output() objectChange = new EventEmitter<AzurePerson>();

  constructor() {
        this.roles = AzurePersonRoles.Any;
      }

  onChange(newValue: any) {
    this.values = newValue;
    this.valuesChange.emit(this.values);
  }

  objectChanged(newValue: any) {
    this.objectChange.emit(newValue);
  }
}
