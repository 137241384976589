import { DDBase } from './DDBase';
import { RiskRatingModel } from '../services/api-clients-generated.service';
export class ProjectRiskRating extends DDBase {
  static MapFromRiskRatingModels(items: RiskRatingModel[]): ProjectRiskRating[] {
    return items.map(item => this.MapFromRiskRatingModel(item));
  }
  static MapFromRiskRatingModel(item: RiskRatingModel): any {
    const newItem = new ProjectRiskRating();
    newItem.id = item.id;
    newItem.name = item.name;
    return newItem;
  }
}
