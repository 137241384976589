<ng-container *ngIf="uiController === UIControlTyp.MultiSelectList">
    <kendo-multiselect
      textField="name"
      valueField="id"
      [data]="ProjectTypeList"
      [filterable]="true"
      (filterChange)="filteTypeList($event)"
      [(ngModel)]="ProjectTypes"
      placeholder="Wählen Sie einen Wert aus"
      (valueChange)="valueChanged()">
    </kendo-multiselect>
  </ng-container>
  <ng-container *ngIf="uiController === UIControlTyp.DropDownList">
      <kendo-dropdownlist
      class="form-control"
      textField="name"
      valueField="id"
      [data]="ProjectTypeList"
      [(ngModel)]="ProjectType"
      (valueChange)="valueChanged()">
    </kendo-dropdownlist>
  </ng-container>
  <ng-container *ngIf="uiController === UIControlTyp.GridSingleValueField">
    {{ getName() }}
  </ng-container>
