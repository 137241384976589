import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';

import { DocumentModule } from './document/document.module';
import { AdloginService } from 'src/app/service/adlogin.service';
import { CoreModule } from './core/core.module';
import { PendingChangesGuard } from './core/middleware/pending-changes.guard';
import { FormsModule } from '@angular/forms';
import { ProjectModule } from './project/project.module';
import { AdminModule } from './admin/admin.module';
import { ReportsModule } from './reports/reports.module';
import { NavigationComponent } from './navigation/navigation.component';
import { AppcommonModule } from './appcommon/appcommon.module';
import { HelpToolTipService } from './service/helptooltip.service';
import { NgProgressModule } from '@ngx-progressbar/core';
import { NgProgressHttpModule } from '@ngx-progressbar/http';
import { NgProgressRouterModule } from '@ngx-progressbar/router';
import { AppConfigService } from './../app/core/services/app-config.service';
import { DialogModule } from "@progress/kendo-angular-dialog";

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
  // console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  const msalSettings = AppConfigService.settings.msal;
  return new PublicClientApplication({
    auth: {
      clientId: msalSettings.clientID,
      authority: msalSettings.authority,
      redirectUri: msalSettings.redirectUri,
      postLogoutRedirectUri: msalSettings.postLogoutRedirectUri
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: true
      }
    }
  });
}


/**
 * MSAL Angular will automatically retrieve tokens for resources
 * added to protectedResourceMap. For more info, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/initialization.md#get-tokens-for-web-api-calls
 */
 export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const msalSettings = AppConfigService.settings.msal;
  const anyMap: any = msalSettings.protectedResourceMap;
  const anyArray: Array<any> = anyMap;
  const protectedResourceMap = new Map<string, Array<string>>();
  //protectedResourceMap.set("https://localhost:5001/", ["api://5559d902-5c8b-4a92-a772-422a3ba10937/user_impersonation"]);
  for (let i = 0; i < anyArray.length / 2; i++) {
    const key: string = anyArray[i * 2];
    const value: Array<string> = anyArray[i * 2 + 1];
    protectedResourceMap.set(key, value);
  };


  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return { interactionType: InteractionType.Redirect };
}

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent
  ],
  imports: [
    DialogModule,
    NgProgressModule,
    NgProgressHttpModule,
    NgProgressRouterModule,
    CoreModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    AppRoutingModule,
    AppcommonModule,
    MsalModule,
    ProjectModule,
    AdminModule,
    ReportsModule,
    DocumentModule
  ],
  providers: [
    MsalService,
    HelpToolTipService,
    AdloginService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalGuard,
    MsalBroadcastService,
    PendingChangesGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor() { }
}
