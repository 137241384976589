import { Component, Input, Output, EventEmitter} from '@angular/core';
import { UIControlTyp } from 'src/app/core/models/UIControlTyp';
import { AzurePersonRoles } from 'src/app/core/models/AzurePersonRoles';
import { AzurePerson } from 'src/app/core/models/AzurePerson';

@Component({
  selector: 'fdb-project-team-member',
  templateUrl: './project-team-member.component.html',
  styleUrls: ['./project-team-member.component.scss']
})

export class ProjectTeamMemberComponent  {
   /** Hiermit können Sie bestimmen ob eine DropDown Liste angezeigt wird oder eine MultiSelect Eingabefeld */
   @Input() uiController: UIControlTyp = UIControlTyp.DropDownList;
   @Input() values: number | number[];
   roles: AzurePersonRoles = AzurePersonRoles.Any;
   @Output() valuesChange = new EventEmitter();
   @Output() objectChange = new EventEmitter<AzurePerson>();

   onChange(newValue: any) {
     this.values = newValue;
     this.valuesChange.emit(this.values);
   }

   objectChanged(newValue: any) {
     this.objectChange.emit(newValue);
   }
}
