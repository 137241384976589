import { LogbooksClient } from './../../core/services/api-clients-generated.service';
import { AppService } from './../app.service';
import { IApiClient } from 'src/app/core/models/IApiClient';
import { Observable } from 'rxjs';
import { ApiResult } from 'src/app/core/models/ApiResult';
import { ILogbooksClient } from 'src/app/core/services/api-clients-generated.service';
import { map } from 'rxjs/operators';
import { LogbookItem } from 'src/app/core/models/LogbookItem';
import { catchError } from 'rxjs/operators';

export class LogBookApiClient implements IApiClient {
  private logbooksClient: ILogbooksClient;
  private controller = 'LogBooks';
  constructor(private appService: AppService) { }

  getAll(includeInactive: boolean): Observable<ApiResult> {
    return this.appService.getAllFromController(this.controller);
  }

  getFromId(id: number): Observable<ApiResult> {
    return this.appService.getErrorApiResult('LogBook => Method getFromId not implemented\.');
  }

  getFromProjectId(projectId: number): Observable<ApiResult> {
    return this.GetLogbooksClient()
      .get(projectId)
      .pipe(
        map(items =>
          this.appService.mapToApiResult(items, LogbookItem.MapFromLogbookModels(items.payload))
        ),
        catchError(err => this.appService.setApiErrorHandling(err, 'LogBookApiClient: getFromProjectId'))
      );
  }

  createList(models: any[]): Observable<ApiResult> {
    return this.GetLogbooksClient()
      .insertList(LogbookItem.MapToInsertLogbookModels(models))
      .pipe(
        map(items =>
          this.appService.mapToApiResult(items, LogbookItem.MapFromLogbookModels(items.payload))
        ),
        catchError(err => this.appService.setApiErrorHandling(err, 'LogBookApiClient: createList'))
      );
  }

  removeList(ids: number[]): Observable<ApiResult> {
    return this.GetLogbooksClient()
    .deleteList(ids)
    .pipe(
      map(items =>
        this.appService.mapToApiResult(items, [])
        ),
        catchError(err => this.appService.setApiErrorHandling(err, 'LogBookApiClient: removeList'))
    );
  }

  updateList(models: any[]): Observable<ApiResult> {
    return this.GetLogbooksClient()
      .updateList(LogbookItem.MapToUpdateLogbookModels(models))
      .pipe(
        map(items =>
          this.appService.mapToApiResult(items, LogbookItem.MapFromLogbookModels(items.payload))
        ),
        catchError(err => this.appService.setApiErrorHandling(err, 'LogBookApiClient: updateList'))
      );
  }

  private GetLogbooksClient() {
    if (!this.logbooksClient) {
      this.logbooksClient = new LogbooksClient(this.appService.http, this.appService.apiUrl);
    }
    return this.logbooksClient;
  }
}
