import { AppService } from './../app.service';
import { IApiClient } from 'src/app/core/models/IApiClient';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IPublicFundingsClient, PublicFundingsClient } from 'src/app/core/services/api-clients-generated.service';
import { PublicFunding } from 'src/app/core/models/PublicFunding';
import { ApiResult } from 'src/app/core/models/ApiResult';

export class PublicFundingApiClient implements IApiClient {
  private publicFundingClient: IPublicFundingsClient;
  constructor(private appService: AppService) { }

  getAll(includeInactive: boolean): Observable<ApiResult> {
    return this.getPublicFundingClient().get(includeInactive)
      .pipe(
        map(response => {
          return this.appService.mapToApiResult(response, PublicFunding.mapFromPublicFundingModel(response.payload));
        })
      );
  }

  getFromId(id: number): Observable<ApiResult> {
    return this.appService.getErrorApiResult('PublicFunding => Method not implemented\.');
  }
  getFromProjectId(projectId: number): Observable<ApiResult> {
    return this.appService.getErrorApiResult('PublicFunding => Method not implemented\.');
  }
  create(model: any): Observable<ApiResult> {
    return this.appService.getErrorApiResult('PublicFunding => Method not implemented\.');
  }
  createList(models: any[]): Observable<ApiResult> {
    return this.appService.getErrorApiResult('PublicFunding => Method not implemented\.');
  }
  remove(id: number): Observable<ApiResult> {
    return this.appService.getErrorApiResult('PublicFunding => Method not implemented\.');
  }
  removeList(ids: number[]): Observable<ApiResult> {
    return this.appService.getErrorApiResult('PublicFunding => Method not implemented\.');
  }
  update(model: any): Observable<ApiResult> {
    return this.appService.getErrorApiResult('PublicFunding => Method not implemented\.');
  }
  updateList(models: any[]): Observable<ApiResult> {
    return this.appService.getErrorApiResult('PublicFunding => Method not implemented\.');
  }

  private getPublicFundingClient(): IPublicFundingsClient {
    if (!this.publicFundingClient) {
      this.publicFundingClient = new PublicFundingsClient(this.appService.http, this.appService.apiUrl);
    }
    return this.publicFundingClient;
  }
}
