import { ProjectOverviewModel } from "../services/api-clients-generated.service";
import { Project } from './Project.Model';
import { ProjectOverviewStatistic } from './ProjectOverviewStatistic';

export class ProjectOverview{
    projects: Project[];
    statistics: ProjectOverviewStatistic;

    static mapFromProjectOverviewModel(items: ProjectOverviewModel){
        const newItem = new ProjectOverview();
        newItem.projects = Project.mapFromProjectListItemModels(items.projects);
        newItem.statistics = items.statistics ? ProjectOverviewStatistic.mapFromProjectStatisticsModel(items.statistics) : new ProjectOverviewStatistic();
        return newItem;
    }
}
