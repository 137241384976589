import { YesNo } from './../../core/models/YesNo';
import { AppService } from './../app.service';
import { IApiClient } from 'src/app/core/models/IApiClient';
import { Observable } from 'rxjs';
import { ApiResult } from 'src/app/core/models/ApiResult';

export class YesNoApiClient implements IApiClient {
  constructor(private appService: AppService) {}

  getAll(includeInactive: boolean): Observable<ApiResult> {
    return YesNo.getAll();
  }
  getFromId(id: number): Observable<ApiResult> {
    return this.appService.getErrorApiResult('YesNoApiClient => Method not getFromId implemented\.');
  }
  getFromProjectId(projectId: number): Observable<ApiResult> {
    return this.appService.getErrorApiResult('YesNoApiClient => Method not getFromProjectId implemented\.');
  }
  create(model: any): Observable<ApiResult> {
    return this.appService.getErrorApiResult('YesNoApiClient => Method not create implemented\.');
  }
  createList(models: any[]): Observable<ApiResult> {
    return this.appService.getErrorApiResult('YesNoApiClient => Method not createList implemented\.');
  }
  remove(id: number): Observable<ApiResult> {
    return this.appService.getErrorApiResult('YesNoApiClient => Method not remove implemented\.');
  }
  removeList(ids: number[]): Observable<ApiResult> {
    return this.appService.getErrorApiResult('YesNoApiClient => Method not removeList implemented\.');
  }
  update(model: any): Observable<ApiResult> {
    return this.appService.getErrorApiResult('YesNoApiClient => Method not update implemented\.');
  }
  updateList(models: any[]): Observable<ApiResult> {
    return this.appService.getErrorApiResult('YesNoApiClient => Method not updateList implemented\.');
  }
}
