import { DDBase } from './DDBase';
import { ProjectStateModel } from '../services/api-clients-generated.service';

export class ProjectState extends DDBase {
  static mapFromProjectStateModel(items: ProjectStateModel[]): ProjectState[] {
    return items.map(item => {
      const newItem = new ProjectState();
      newItem.id = item.id;
      newItem.name = item.name;
      newItem.isActive = item.isActive;
      return newItem;
    });
  }
}
