import { ProjectStatesClient, IProjectStatesClient, ProjectStateModel } from './../../core/services/api-clients-generated.service';
import { AppService } from './../app.service';
import { IApiClient } from 'src/app/core/models/IApiClient';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiResult } from 'src/app/core/models/ApiResult';
import { ProjectState } from 'src/app/core/models/ProjectState';

export class ProjectStateApiClient implements IApiClient {
  private projectStateClient: IProjectStatesClient;
  constructor(private appService: AppService) { }

  getAll(includeInactive: boolean): Observable<ApiResult> {
    return this.GetProjectStateClient().get(includeInactive)
    .pipe(
      map(response => {
        return this.appService.mapToApiResult(response, ProjectState.mapFromProjectStateModel(response.payload));
      })
    );
  }

  getFromId(id: number): Observable<ApiResult> {
    return this.appService.getErrorApiResult('ProjectStates => Method not implemented\.');
  }
  getFromProjectId(projectId: number): Observable<ApiResult> {
    return this.appService.getErrorApiResult('ProjectStates => Method not implemented\.');
  }
  create(model: any): Observable<ApiResult> {
    return this.appService.getErrorApiResult('ProjectStates => Method not implemented\.');
  }
  createList(models: any[]): Observable<ApiResult> {
    return this.appService.getErrorApiResult('ProjectStates => Method not implemented\.');
  }
  remove(id: number): Observable<ApiResult> {
    return this.appService.getErrorApiResult('ProjectStates => Method not implemented\.');
  }
  removeList(ids: number[]): Observable<ApiResult> {
    return this.appService.getErrorApiResult('ProjectStates => Method not implemented\.');
  }
  update(model: any): Observable<ApiResult> {
    return this.appService.getErrorApiResult('ProjectStates => Method not implemented\.');
  }
  updateList(models: any[]): Observable<ApiResult> {
    return this.appService.getErrorApiResult('ProjectStates => Method not implemented\.');
  }

  private GetProjectStateClient(): IProjectStatesClient {
    if (!this.projectStateClient) {
      this.projectStateClient = new ProjectStatesClient(this.appService.http, this.appService.apiUrl);
    }
    return this.projectStateClient;
  }
}
