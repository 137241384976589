import { map } from 'rxjs/operators';
import { Client, FetchOptions, FileObject, Options } from '@microsoft/microsoft-graph-client';
import { Injectable } from '@angular/core';
import { Project } from '../core/models/Project.Model';
import { AppService } from './app.service';
import { ReportClient } from '../core/services/api-clients-generated.service';
import { AdloginService } from './adlogin.service';
import { off } from 'process';
import { HttpHeaders } from '@angular/common/http';
import { Stream } from 'stream';

export enum UrlType {
  folderAsParameter,
  straightForward,
  sharePointSpecialWithColon
}

class SharePointUrl {
  public originalUrl: string;
  public straightForwardUrl: string;
  public urlType: UrlType;
  public protocol: string;
  public server: string;
  public siteName: string;
  public docLib: string;
  public folderPath: string;
  public document: string;
}

@Injectable({
  providedIn: 'root'
})
export class ProjectWorkspaceService {

  constructor(public appService: AppService,
              public adloginService: AdloginService) {
  }

  async checkNKVFile(pathUrl: string, projectNummer: string): Promise<boolean | undefined> {

    if (!pathUrl || pathUrl === "") {
      console.warn("No teamspace given");
      return null;
    }

    pathUrl = pathUrl.toLowerCase();
    if (!pathUrl.startsWith("https://")) {
      console.error('Teamspace has no https-protocoll')
      return null;
    }

    const sharePointUrl = this.normalizeSharePointLink(pathUrl, true);
    const straightForwardUrl = sharePointUrl.straightForwardUrl.toLowerCase();

    const siteSearchPath = '/sites/' + sharePointUrl.server + ':/sites/' + sharePointUrl.siteName;

    const site = await this.getGraphObject(siteSearchPath);
    if (site === undefined || site === null) {
      console.warn("Site not found");
      return false;
    }
    const siteIds = site.id;
    const siteIdArray = siteIds.split(',');
    const siteId = siteIdArray[1];
    const sitePath = '/sites/' + siteId + '/lists';

    // Find List
    const lists = await this.getGraphObject(sitePath);
    if (lists === undefined || lists === null) {
      console.warn("List not found");
      return false;
    }

    let listId: string = "";
    for (let i = 0; i < lists.value.length; i++) {
      let list = lists.value[i];
      if (straightForwardUrl.startsWith(list.webUrl.toLowerCase())) {
        listId = list.id;
        break;
      }
    }
    if (listId === "") {
      console.warn("Document library not found");
      return false;
    }
    
    const listPath = '/sites/' + siteId + '/lists/' + listId;
    let result = await this.searchFolderHierarchy(listPath, straightForwardUrl);

    if (!result) {
      console.warn("did not find subfolder");
      return false;
    }

    let nkvFileExist = false;
    
    let contentPath = result.parentReference.path + '/'+ result.name + ':/children';

    while (contentPath) {
      const contentItems = await this.getGraphObject(contentPath);

      for (let j = 0; j < contentItems.value.length; j++) {
        let contentItem = contentItems.value[j];
        if (this.isNkvFile(contentItem.name, projectNummer))
        {
          nkvFileExist = true;
          break;
        }
      }

      if (nkvFileExist) {
        contentPath = null;
      } else {
        contentPath = contentItems['@odata.nextLink'];  // Paging: next page
      }
    }

    console.info("Result of NKV file test:" + nkvFileExist);

    return nkvFileExist;
  }

  async searchFolderHierarchy(listPath: string, filename: string) : Promise<any | undefined> {
    let folderPath = listPath + '/items';
    const folderItems = await this.getGraphObject(folderPath);

    let result = null;
    for (let i = 0; i < folderItems.value.length; i++) {
      let item = folderItems.value[i];
      const webUrl: string = item.webUrl.toLowerCase();

      if (filename === webUrl) {
        result = await this.searchFolderHierarchyAsDrive(listPath, item.id, filename);
        break;
      }

      if (item.contentType?.name === "Folder" || item.folder) {
        if (filename.startsWith(webUrl + "/")) {
          result = await this.searchFolderHierarchyAsDrive(listPath, item.id, filename);
          break;
        }
      }
    }

    return result;
  }

  async searchFolderHierarchyAsDrive(listPath: string, folderId: string, filename: string) : Promise<any | undefined> {
    let folderPath = listPath + '/items/' + folderId + '/driveItem';

    const folderItem = await this.getGraphObject(folderPath);

    if (folderItem.webUrl.toLowerCase() === filename) {
      return folderItem;
    }

    const relativeFilePath : string = filename.substring(folderItem.webUrl.length - folderItem.name.length);
    const resultPath = '/drives/' + folderItem.parentReference.driveId + '/root:/' + relativeFilePath;

    let result = await this.getGraphObject(resultPath);

    return result;
  }

  public isNkvFile(filename: string, projectNummer: string): boolean
  {
    return filename.includes('NKV') && filename.includes(projectNummer);
  }

  public normalizeSharePointLink(url: string, isFolderLink: boolean): SharePointUrl {

    if (!url) {
      return null;
    }

    
    let result: SharePointUrl = {
      originalUrl: url,
      straightForwardUrl: null,
      urlType: UrlType.straightForward,
      protocol: null,
      server: null,
      siteName: null,
      docLib: null,
      folderPath: null,
      document: null
    }

    const http = "http://";
    const https = "https://";
    const fColonR = "/:f:/r/";
    const forms = "/forms/allitems.aspx?rootfolder=";
    const lowerCaseUrl = url.toLowerCase();

    result.protocol = https;
    if (lowerCaseUrl.startsWith(http)) {
      result.protocol = http;
    }

    let remainder = lowerCaseUrl.substring(result.protocol.length);
    let pos = remainder.indexOf("/");
    result.server = remainder.substring(0, pos);
    remainder = remainder.substring(pos);

    result.urlType = UrlType.straightForward;
    if (remainder.startsWith(fColonR)) {
      result.urlType = UrlType.sharePointSpecialWithColon;
    } else if (remainder.indexOf(forms) > 1) {
      result.urlType = UrlType.folderAsParameter;
    }

    let rootFolder = "";
    if (result.urlType == UrlType.folderAsParameter) {
      pos = remainder.indexOf(forms);
      const siteAndDocLib = remainder.substring(0, pos);  // /sites/siteName/doclib
      remainder = remainder.substring(pos + forms.length);
      const parameters = remainder.split("&");
      for (let i = 0; i < parameters.length; i++) {
        const param = parameters[i];
        const keyValuePair = param.split("=");
        if (keyValuePair[0] === "rootfolder") {
          rootFolder = keyValuePair[1].replace("%2f", "/")
                                      .replace("%5f", "_")
                                      .replace("%2d", "-");
        }
      }
    }
    else {
      if (result.urlType == UrlType.sharePointSpecialWithColon) {
        remainder = remainder.replace(fColonR, "/");
      }

      pos = remainder.indexOf("?");
      if (pos > 0) {
        remainder = remainder.substring(0, pos);
      }

      rootFolder = remainder;
    }

    result.straightForwardUrl = result.protocol + result.server + rootFolder;

    if (rootFolder.startsWith("/sites/")) {
      remainder = rootFolder.substring(7);
      pos = remainder.indexOf("/");
      const siteName = remainder.substring(0, pos);
      result.siteName = siteName;

      remainder = remainder.substring(pos+1);
      pos = remainder.indexOf("/");
      const docLib = remainder.substring(0, pos);
      result.docLib = docLib;

      remainder = remainder.substring(pos+1);
      if (remainder.endsWith("/") || isFolderLink) {
        result.folderPath = remainder;
      }
      else {
        pos = remainder.lastIndexOf("/");
        result.folderPath = remainder.substring(0, pos);
        result.document = remainder.substring(pos);
      }
    }

    return result;
  }
  
  async createAndSafeReportOnApproval(project: Project): Promise<string | undefined> {
    const reportService = new ReportClient(this.appService.http, this.appService.apiUrl);

    const reportServiceCall = reportService.get(project.id)
      .pipe(
          map(res => {
            return {
              filename: 'ReportProjekt-' + project.number.toString() + '.pdf',
              data: res.data
            };
          }))
      .subscribe(res => {
          if (res.data.size > 0) {
            console.log('start download:',res);
            // Hier der Upload nach SharePoint
            this.uploadToSharePoint(project, res.data);
          }
          else
          {
            console.log('downloading project failed, no data');
          }
        }, error => {
          console.log('download error:', JSON.stringify(error));
        }, () => {
          console.log('Completed file download.')
      });

    return null;
  }

  async uploadToSharePoint(project: Project, data: Blob): Promise<any> {

    const sharePointUrl = this.normalizeSharePointLink(project.projectWorkspaceUrl, true);
    const straightForwardUrl = sharePointUrl.straightForwardUrl.toLowerCase();
    const siteSearchPath = '/sites/' + sharePointUrl.server + ':/sites/' + sharePointUrl.siteName;
    const site = await this.getGraphObject(siteSearchPath);
    const siteIds = site.id;
    const siteIdArray = siteIds.split(',');
    const siteId = siteIdArray[1];
    const sitePath = '/sites/' + siteId + '/drives';

    // Find document library (drive)
    const drives = await this.getGraphObject(sitePath);
    let driveId: string = "";
    let drive: any;
    for (let i = 0; i < drives.value.length; i++) {
      let drive = drives.value[i];
      if (straightForwardUrl.startsWith(drive.webUrl.toLowerCase())) {
        driveId = drive.id;
        break;
      }
    }

    if (driveId === "") {
      console.warn("Document library not found");
      return null;
    }

    // Find the folder
    const drivePath = '/drives/' + driveId + '/root:/' + sharePointUrl.folderPath;
    const subfolder = await this.getGraphObject(drivePath);    

    if (!subfolder) {
      return null;
    }

    const contentPath             = '/sites/' + siteId + '/drive/items/'+subfolder.id+':/ReportProjekt-' + project.number + '.pdf:/content';
    const createUploadSessionPath = '/sites/' + siteId + '/drive/items/'+subfolder.id+':/ReportProjekt-' + project.number + '.pdf:/createUploadSession';

    let result: Promise<any>;
    try {
      result = await this.postGraphObject(createUploadSessionPath, data);
    }
    catch(x) {
      console.warn(x);
      result = null;
    }

    if (!result) {
      console.info("putGraphUser: Error uploading to SharePoint, Graph API path: " + contentPath);
    }

    return result;
  }

  async getGraphObject(path: string): Promise<any | undefined> {
    if (this.adloginService.getMsalInstance().getAllAccounts().length == 0) {
      console.warn("getGraphUser: not authenticated");
      return undefined;
    }

    const h: HeadersInit = { Prefer: 'HonorNonIndexedQueriesWarningMayFailRandomly' };
    const fo: FetchOptions = { headers: h }

    const graphClient = this.getInitializedGraphClient(false, fo);

    let graphObject: any;

    try
    {
      // Get the user from Graph (GET /me)
      graphObject = await graphClient.api(path)
                                     .get();
    }
    catch {
      graphObject = undefined;
    }
    return graphObject;
  }

  async postGraphObject(path: string, data: Blob): Promise<any | undefined> {
    if (!path) {
      console.warn("putGraphUser: no upload path given");
      return undefined;
    }

    if (this.adloginService.getMsalInstance().getAllAccounts().length == 0) {
      console.warn("putGraphUser: not authenticated");
      return undefined;
    }

    const headers: HeadersInit = {
      "Content-Type": "application/pdf",
      "Content-Length": data.size.toString(),
      "Content-Range": "bytes 0-" + (data.size-1).toString() + "/" + data.size.toString()
    };
    let requestInit: RequestInit = {
      //headers: headers
    };

    const graphClient = this.getInitializedGraphClient(true, requestInit);

    let view = new Uint8Array(await data.arrayBuffer());
  
    const arrayString = this.uint8ArrayToString(view, data.size);

    const fileContentAsBinary = require('buffer/').Buffer.from(arrayString, 'base64');

    let graphObject: any;

    try {
      let url = await graphClient.api(path).post('');
      graphObject = await graphClient.api(url.uploadUrl).headers(headers).put(fileContentAsBinary);
    }
    catch(x) {
      console.warn(x);
      graphObject = null;
    }

    return graphObject;
  }

  async putGraphObject(path: string, data: Blob): Promise<any | undefined> {
    if (!path) {
      console.warn("putGraphUser: no upload path given");
      return undefined;
    }

    if (this.adloginService.getMsalInstance().getAllAccounts().length == 0) {
      console.warn("putGraphUser: not authenticated");
      return undefined;
    }

    const headers: HeadersInit = {
      "Content-Type": "application/pdf",
      "Content-Length": data.size.toString(),
      "Content-Range": "bytes 0-" + (data.size-1).toString() + "/" + data.size.toString()
    };
    let requestInit: RequestInit = {
      //headers: headers
    };

    const graphClient = this.getInitializedGraphClient(true, requestInit);

    let view = new Uint8Array(await data.arrayBuffer());
  
    const arrayString = this.uint8ArrayToString(view, data.size);

    const fileContentAsBinary = require('buffer/').Buffer.from(arrayString, 'base64');

    let graphObject: any;
    try {
      graphObject = await graphClient.api(path)
                                     .headers(headers)
                                     .put(fileContentAsBinary);
    }
    catch(x) {
      console.warn(x);
      graphObject = null;
    }


    return graphObject;
  }

  uint8ArrayToString(view: Uint8Array, size: number): string {
    let result: string = '';
    const realArray = Array.from(view);
    const arrayLen = size;
    for (let i = 0; i <= arrayLen / 1000; i++) {
      const offset = i * 1000;
      const partArrayLen = ((arrayLen - offset) > 1000) ? 1000 : arrayLen % 1000;

      let partArray: Number[] = new Array(partArrayLen);
      for (let j = 0; j < partArrayLen; j++) {
        partArray[j] = realArray[offset + j];
      }
      result = result + String.fromCharCode.apply(null, partArray);
      const strlen = result.length;
    }
    const base64 = window.btoa(result);
    return base64;
  }

  getInitializedGraphClient(readWrite: boolean = false, fetchOptions: FetchOptions = null) : Client {
    let accessRight = ["https://graph.microsoft.com/Sites.Read.All"];
    if (readWrite) {
      accessRight = ["https://graph.microsoft.com/Files.ReadWrite.All"];
    }

    let options: Options = {
      authProvider: async(done) => {
        const token = await this.adloginService.getAccessToken(accessRight)
          .catch((reason) => {
            done(reason, null);
          });

        if (token)
        {
          done(null, token);
        } else {
          done("Could not get an access token", null);
        }
      },
    };

    if (fetchOptions) {
      options.fetchOptions = fetchOptions;
    }

    const graphClient = Client.init(options);

    return graphClient;
  }
}
