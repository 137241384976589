import { environment } from 'src/environments/environment';
import { IAppConfig } from '../models/app-config.model';


export class AppConfigService {
  static settings: IAppConfig;

  static load(success: () => void): void {

    const request = new XMLHttpRequest();

    request.addEventListener('error', (res: any) => {
      console.log('Error: ', res);
    });

    request.addEventListener('load', (res: any) => {
      let fdbClientSettingRetrievalCount = (Number)(localStorage.getItem("fdbClientSettingRetrievalCount"));
      fdbClientSettingRetrievalCount++;
      console.log('Loading AppConfig..., attempt # ' + fdbClientSettingRetrievalCount.toString());
      localStorage.setItem("fdbClientSettingRetrievalCount", fdbClientSettingRetrievalCount.toString())

      let responseString : string = res.currentTarget.response;
      if (responseString.startsWith("<!DOCTYPE html>")) {
        console.warn(res.currentTarget.responseURL);
        console.warn(responseString);
      }
      AppConfigService.settings = JSON.parse(res.currentTarget.response);
      const parseValue = JSON.parse(AppConfigService.settings.msal.protectedResourceMap);
      AppConfigService.settings.msal.protectedResourceMap = parseValue;

      if (fdbClientSettingRetrievalCount >= 5) {
        console.log('Loading AppConfig..., too many attempts necessary (' + fdbClientSettingRetrievalCount.toString() + ')');
      }

      if (fdbClientSettingRetrievalCount < 8) {
        success();
      } else {
        console.log('Loading AppConfig..., stopping attempts, resetting counter');
        localStorage.setItem("fdbClientSettingRetrievalCount", "0");
      }
    });
    console.log('requesting ' + environment.backendApiUrl + '/api/ClientSetting');
    request.open('GET', environment.backendApiUrl + '/api/ClientSetting');  // request application settings synchronous
    request.send(null);
  }
}
