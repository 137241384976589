import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { UIControlTyp } from 'src/app/core/models/UIControlTyp';
import { AzurePersonRoles } from 'src/app/core/models/AzurePersonRoles';
import { AzurePerson } from 'src/app/core/models/AzurePerson';
import { Project } from 'src/app/core/models/Project.Model';


@Component({
  selector: 'fdb-project-masterdata',
  templateUrl: './project-masterdata.component.html',
  styleUrls: ['./project-masterdata.component.scss']
})
export class ProjectMasterdataComponent implements OnInit, OnChanges {
  @Input() project: Project;
  @Input() setValidation = false;
  @Output() projectChange = new EventEmitter<Project>();

  public UIControlTyp = UIControlTyp;
  public AzurePersonRoles = AzurePersonRoles;
  constructor(private fb: FormBuilder) {}

  ngOnInit() {
  }

  ngOnChanges(): void {
    this.projectChange.emit(this.project);
  }

  changeProjectmanger(projectManager: AzurePerson) {
    if (Array.isArray(projectManager) === false) {
      this.project.projectManagerPhoneNumber = projectManager.phoneNumber;
      this.project.projectManagerEmail = projectManager.email;
      this.project.projectManagerFaxNumber = projectManager.faxNumber;
    } else {
      console.log('Error in Model');
    }
  }
}
