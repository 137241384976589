<div class="row" style="margin-top: 50px;">
  <div class="col-xs-9 col-md-9">
    <h4>Projekt Team Mitglieder</h4>
  </div>
</div>
<div class="row" style="margin-top: 20px">
  <div class="col-sm-12">
    <kendo-multiselect
      textField="name"
      valueField="userId"
      [filterable]="true"
      (filterChange)="filterPotentialTeamMember($event)"
      [data]="potentialTeamMember"
      [(ngModel)]="teamMember"
      (valueChange)="valueChanged($event)"
    ></kendo-multiselect>
  </div></div>
