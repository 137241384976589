<ng-container *ngIf="uiController === UIControlTyp.MultiSelectList">
    <kendo-multiselect
      textField="name"
      valueField="id"
      [data]="PublicFundingList"
      [filterable]="true"
      (filterChange)="filterPublicFundingList($event)"
      [(ngModel)]="PublicFundings"
      placeholder="Bisher keine öffentliche Förderung ausgewählt. Bitte wählen Sie einen oder mehrere Werte aus."
      (valueChange)="valueChanged($event)">
    </kendo-multiselect>
  </ng-container>
  <ng-container *ngIf="uiController === UIControlTyp.DropDownList">
    <kendo-dropdownlist
      class="form-control"
      textField="name"
      valueField="id"
      [data]="PublicFundingList"
      [(ngModel)]="PublicFunding"
      (valueChange)="valueChanged($event)">
    </kendo-dropdownlist>
  </ng-container>
  <ng-container *ngIf="uiController === UIControlTyp.GridSingleValueField">
    {{ getName() }}
  </ng-container>

