import { AppService } from './../../service/app.service';
import { AdloginService } from '../../service/adlogin.service';
import { CanDeactivateComponent } from './../../core/middleware/pending-changes.guard';
import { AzurePerson } from './../../core/models/AzurePerson';
import { Component, OnDestroy, OnInit, AfterViewInit, AfterContentInit, AfterViewChecked } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { RoutingPath } from '../../app-routing.module';
import { HelpToolTipService } from '../../service/helptooltip.service';
import { ProjectService } from '../project.service';
import { Project } from '../../core/models/Project.Model';
import { UIControlTyp } from 'src/app/core/models/UIControlTyp';
import { Observable } from 'rxjs';
import { ApiResult } from 'src/app/core/models/ApiResult';
import { AppConfigService } from 'src/app/core/services/app-config.service'
import { AllHelpToolTips } from 'src/app/core/models/AllHelpToolTips';
import { ProjectWorkspaceService } from 'src/app/service/project-workspace.service';
import { environment } from 'src/environments/environment';

declare var require: any;

@Component({
  selector: 'fdb-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss']
})

export class ProjectDetailsComponent implements OnDestroy, OnInit, CanDeactivateComponent {
  public isDeleted = false;
  public edited = false;
  public routingPath = RoutingPath;
  public project: Project;
  public activeTab: string;
  private currentId: number;
  public UIControlTyp = UIControlTyp;
  public editMode = false;
  public oldState: number;
  public openInfoBox = false;
  //public imgname = require("file-loader!../../../assets/img/question.png");
  public imgname = "/assets/img/question.png";
  public assemblyVersion: string = "";
  public versionInfo: string = "";
  public isAdmin: boolean = false;
  public currentUser: AzurePerson;
  public allHelpToolTips: AllHelpToolTips;


  constructor(private adLoginService: AdloginService,
              private appService: AppService,
              private router: Router,
              private route: ActivatedRoute,
              private location: Location,
              private modulService: ProjectService,
              private projectWorkspaceService: ProjectWorkspaceService,
              private helpToolTipService: HelpToolTipService) {
    this.project = new Project();

    this.initProject();


    adLoginService.getCurrentUser()
                  .subscribe((currentUser: AzurePerson) => {
      if (currentUser) {
        this.currentUser = currentUser;
        this.isAdmin = currentUser.isUserAdmin();
      }
    });
  }

  ngOnInit() {
    this.checkIfUserHasRoles();

    const apiResult = this.route.snapshot.data.project ?? null;

    if (!apiResult.isSuccessful) {
      this.modulService.showNotification(apiResult.message, 'error');
    }
    if (apiResult.payload) {
      this.project = apiResult.payload;
      this.oldState = this.project.stateId;
    }
    this.loadHelpToolTips();
    this.assemblyVersion = AppConfigService.settings.assemblyVersion;
    const stg = AppConfigService.settings;
    let environmentDisplayName = environment.displayName;
    if ((environmentDisplayName === 'Development Stage') && (stg.stage != '1')) {
      if (stg.stage === '2') {
        environmentDisplayName = 'Test Stage';
      }
      else if (stg.stage === '3') {
        environmentDisplayName = '';  // Production
      }
    }
    this.versionInfo = ((environmentDisplayName != '') ? ' ' + environmentDisplayName + ', ' : '') + 'Version ' + stg.assemblyVersion +
                       ((stg.ignoreMail) ? ', Test-Umgebung: kein Versand von Infomails' : 
                             ((stg.mailRedirect && stg.mailRedirect != '') ? ', Test-Umgebung: Mailversand immer an ' + stg.mailRedirect : ''));
  }

  ngOnDestroy(): void {
    if (!this.isDeleted) {
      this.saveProject();
    }
  }

  initProject() {
    this.route.params.subscribe(params => {
      this.currentId = params.id;
      this.appService.currentProjectId = this.currentId;
      const checkComp = this.router.url.substring(1, this.router.url.length);
      this.activeTab = checkComp.split('/').length > 2 ? checkComp : this.getCorrectPath(this.routingPath.projectDescription);
    });
  }

  public checkIfUserHasRoles() {
    if(this.currentUser.roles.length === 0){
      this.router.navigateByUrl('/' + RoutingPath.projectSearch);
    }
  }

  loadHelpToolTips() {
    const that = this;
    this.helpToolTipService.getHelpToolTips().subscribe(apiResult => {
      if (!apiResult.isSuccessful) {
        that.modulService.showNotification(apiResult.message, 'error');
      }
      if (apiResult.payload) {
        this.allHelpToolTips = this.helpToolTipService.getAllHelpToolTips(apiResult.payload);
      }
    },
      err => that.modulService.showNotification(err, 'error')
    );
  }

  projectOnChanged(event: any) {
    if (!this.edited) {
      this.edited = true;
      AppService.isProjectChange = true;
    }
  }

  projectOnRequested(event: any) {
 
    if (!event || !event.url || event.url === "") {
      this.modulService.showNotification('Fehler : Link auf den Projektworkspace liegt noch nicht vor', 'error');
      return;
    }

    this.projectWorkspaceService.checkNKVFile(event.url, this.project.number).then((exist) => {
      if(exist)
      {
        let requestMsg: string = event.msg;
        let callfunc: Observable<ApiResult>;
        let text: string;
        callfunc = this.modulService.requestProject(this.project.id, this.project.stateId, requestMsg);
        text = 'Projekt wurde beantragt';
        callfunc.subscribe(apiResult => {
          if (apiResult.isSuccessful) {          
             this.modulService.showNotification(text, 'success'); 
             this.project.stateId = 2;          
          } else {
            this.modulService.showNotification('Fehler : ' + apiResult.message, 'error');
            this.project.stateId = this.oldState;
          }
        },
        err => this.modulService.showNotification('Probleme : ' + err, 'error'));
        this.project.stateId = this.oldState;
      }
      else
      {
        if (this.project.projectWorkspaceUrl.toLowerCase().indexOf("/allitems.aspx?") > 0) {
          const docuUrl: string = "https://forschungsdberzeugung.blob.core.windows.net/docs/ForschungsDB-Benutzerdokumentation.pdf";
          const msg: string = 'Fehler : Die NKV Datei wurde nicht gefunden, der Link auf den Projektworkspace ist möglicherweise fehlerhaft. <br/>Klicken Sie  <a href="' + 
                              docuUrl + '" target="_new" class="fdb-custom-notification" />hier</a>, um die Benutzerdokumentation zu öffnen und beachten Sie insbesondere Abschnitt 2.2.3.7. ';
          this.modulService.showNotification(msg, 'error');
        } else {
          this.modulService.showNotification('Fehler : NKV Datei liegt noch nicht vor', 'error');
        }
        this.project.stateId = this.oldState;
      }
    });
  }

  switchTabs(tab: string) {
    if (this.checkCurrentTab(this.routingPath.projectDescription)) {
      if (this.changeStateToRequested() === false) {
        return;
      }
    }

    if (this.edited) {
      this.updateProject(false);
    }
    this.activeTab = tab;
    this.location.go(tab);
  }

  private changeEditMode(state: boolean) {
    this.editMode = state;
  }

  editHeadValue(): void {
    this.changeEditMode(!this.editMode);
    if (!this.editMode) {
      this.updateProject(true);
    }
  }

  saveProject(): void {
    if (this.editMode) {
      this.editHeadValue();
    }
    this.updateProject(false);
  }

  checkCurrentTab(route: string): boolean {
    return (this.activeTab === this.getCorrectPath(route));
  }

  getCorrectPath(route: string): string {
    return route + '/' + this.currentId;
  }

  projectManagerChange(person: AzurePerson) {
    console.log('this.projectManagerChange');
    this.project.projectManagerEmail = person.email;
    this.project.projectManagerFaxNumber = person.faxNumber;
    this.project.projectManagerPhoneNumber = person.phoneNumber;
  }

  private changeStateToRequested(): boolean {
    if (!(this.project.isTransferableId === 1 || this.project.isTransferableId === 2)) {
      this.modulService.showNotification('Fehler : ' + 'Bitte geben Sie an, ob ein Ergebnistransfer vorgesehen ist', 'error');
      return false;
    }
    return true;
  }

  infoPurchase() {
    this.openInfoBox = true;
  }

  closeInfoPurchase(event: any) {
    this.openInfoBox = false;
  }

  sendMailToPurchasing(event: any) {
    this.openInfoBox = false;
    this.modulService.sendPurchaseDepartmentMail(this.project.id).subscribe(apiResult => {
      if (apiResult.isSuccessful) {
        this.modulService.showNotification('Information an den Einkauf : ' + apiResult.message, 'success');
      } else {
        this.modulService.showNotification('Information an den Einkauf : ' + apiResult.message, 'error');
      }
    });
  }

  changeStateToCompleted(): boolean {
    if (this.project.isTransferableId === 1) {
      if (this.project.transferResult && this.project.transferSuccessId) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  }

  stateChanged(state: number) {
   
    if(this.project.projectWorkspaceUrl && (state === 2 || state === 4))
    {
      this.projectWorkspaceService.checkNKVFile(this.project.projectWorkspaceUrl, this.project.number).then((exist) => {
        if(exist)
          {
            this.modulService.showNotification('NKV Datei liegt vor', 'success');
            this.updateProject(false, true);
          }
          else
          {
            this.modulService.showNotification('Fehler : ' + 'NKV Datei liegt noch nicht vor', 'error');
            this.project.stateId = this.oldState;
          }
        }
      );
    }
    else {
      this.updateProject(false, true);
    }
  }

  private updateProject(headerOnly: boolean, updateState = false) {
    let callfunc: Observable<ApiResult>;
    if (headerOnly) {
      callfunc = this.modulService.updateProjectHeader(this.project);
    } else {
      callfunc = this.modulService.updateProject(this.project);
    }
    callfunc.subscribe(apiResult => {
      if (apiResult.isSuccessful) {
        if (headerOnly) {
          this.changeEditMode(false);
        }
        if (updateState) {
         
          this.updateState();
          return;
        }
        this.edited = false;
        AppService.isProjectChange = false;
        if (apiResult.payload.readyForMailToPurchasing) {
          this.infoPurchase();
        }
      } else {
        this.modulService.showNotification('Fehler : ' + apiResult.message, 'error');
      }
    },
      err => this.modulService.showNotification('Probleme : ' + err, 'error'));
  }

  private updateState() {
    let callfunc: Observable<ApiResult>;
    let text: string;
    callfunc = this.modulService.updateState(this.project);
    text = 'Status wurde erfolgreich geändert';
    callfunc.subscribe(apiResult => {
      if (apiResult.isSuccessful) {
        this.oldState = this.project.stateId;  
        this.modulService.showNotification(text, 'success');      
        
      } else {
        this.project.stateId = this.oldState;
        this.modulService.showNotification('Fehler : ' + apiResult.message, 'error');
      }
    },
    err => this.modulService.showNotification('Probleme : ' + err, 'error'));
  }

  hasChanges() {
    if (this.isDeleted) {
      return false;
    }
    this.updateProject(false);
    return this.edited;
  }

  deleteProject() {
    const that = this;
    let callfunc: Observable<ApiResult>;
    let text: string;
    callfunc = this.modulService.deleteProject(this.project.id);
    text = 'Projekt wurde erfolgreich aus der Datenbank gelöscht'
    callfunc.subscribe(apiResult => {
      text = apiResult.message;
      if (apiResult.isSuccessful) {
        that.isDeleted = true;
        that.project = new Project();
        that.currentId = 0;
        that.edited = false;
        AppService.isProjectChange = false;
        that.modulService.showNotification(text, 'success');
        that.router.navigateByUrl('/' + RoutingPath.projectSearch);
      } else {
        that.modulService.showNotification('Fehler : ' + text, 'error');
      }
    },
      err => that.modulService.showNotification('Probleme : ' + err, 'error'));
  }
}
