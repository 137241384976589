<fdb-azure-person
  [uiController]="uiController"
  [values]="values"
  (valuesChange)="onChange($event)"
  [roles]="roles"
  (objectChange)="objectChanged($event)"
  >
</fdb-azure-person>


