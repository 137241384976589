import { ProjectMilestoneApiClient } from './../../../../service/apiClients/ProjectMilestoneApiClient';
import { Injectable } from '@angular/core';
import { ListBehaviorService } from 'src/app/service/list-behavior.service';
import { ProjectService } from 'src/app/project/project.service';


@Injectable({
  providedIn: 'root'
})
export class ProjectSetupMilestoneService extends ListBehaviorService {

  constructor(modulService: ProjectService) {
    super(new ProjectMilestoneApiClient(modulService), modulService);
  }
}
