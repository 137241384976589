import { Base } from './Base';
export class DDBase extends Base {
  name: string;
  isActive = true;
  getEmptyModel(name: string = '--- Bitte Auswählen ---') {
    this.id = 0;
    this.name = name;
    this.isActive = true;
    return this;
  }
}
