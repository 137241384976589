<div style="margin:20px 50px 0px 50px;">
  <form>
    <div class="row">
      <div class="col-sm-8">
        <div class="row">
          <div class="form-group col-sm-4">
            <label for="Projektname">Projektname</label>
            <input type="text" kendoTextBox class="form-control" name="Projektname" [(ngModel)]="viewModel.name"
              #ctrl="ngModel" placeholder="Geben Sie einen Wert ein!">
          </div>
          <div class="form-group col-sm-4">
            <label for="Jahr">Jahr</label>
            <kendo-datepicker class="form-control" [format]="'yyyy'" [formatPlaceholder]="{ year: 'Jahr', month: 'Monat', day: 'Tag', hour: '0', minute: '0', second: '0' }" [(ngModel)]="viewModel.year" name="year"
              [bottomView]="'decade'"></kendo-datepicker>
          </div>
          <div class="form-group col-sm-3">
            <label for="Betreuer">Betreuer</label>
            <fdb-advisor [uiController]="UIControlTyp.DropDownList" [(values)]="viewModel.advisorId"></fdb-advisor>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-sm-4">
            <label for="Organisationseinheit">Organisationseinheit</label>
            <input type="text" kendoTextBox class="form-control" name="orgaUnit" [(ngModel)]="viewModel.orgaUnit"
              #ctrl="ngModel" placeholder="Geben Sie einen Wert ein!">
          </div>
          <div class="form-group col-sm-4">
            <label for="Einheit">Verantwortliche EnBW Einheit</label>
            <fdb-enbwunit [uiController]="UIControlTyp.DropDownList" [(values)]="viewModel.enbwUnitId">
            </fdb-enbwunit>
          </div>
          <div class="form-group col-sm-3">
            <label for="Projektstatus">Projektstatus</label>
            <fdb-project-state [uiController]="UIControlTyp.DropDownList" [(values)]="viewModel.stateId">
            </fdb-project-state>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-sm-4">
            <label for="Projektleiter">Projektleiter</label>
            <fdb-project-manager [uiController]="UIControlTyp.DropDownList" [(values)]="viewModel.projectManagerId">
            </fdb-project-manager>
          </div>
          <div class="form-group col-sm-7">
            <label for="Freitext">Freitext</label>
            <input type="text" kendoTextBox class="form-control" name="Freitext" [(ngModel)]="viewModel.freetext"
              #ctrl="ngModel" placeholder="Geben Sie einen Wert ein!">
          </div>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="row">
          <label class="col-sm-10">Statistik aktuelles Jahr</label><label class="col-sm-1"></label>
          <label class="col-sm-10">Anzahl geplanter Projekte in {{currentYear}} </label><label
            class="col-sm-1">{{ viewModel.statistik.plannedProjectCount }}</label>
          <label class="col-sm-10">Anzahl laufender Projekte in {{currentYear}} </label><label
            class="col-sm-1">{{ viewModel.statistik.activeProjectCount }}</label>
          <label class="col-sm-10">Anzahl abgeschlossener Projekte in {{currentYear}}</label><label
            class="col-sm-1">{{ viewModel.statistik.closedProjectCount }}</label>
          <label class="col-sm-10">Anzahl Projekte in Transfer in {{currentYear}}</label><label
            class="col-sm-1">{{ viewModel.statistik.inTransferProjectCount }}</label>
        </div>
        <div class="row" style="margin-top: 40px">
          <div class="col-sm-1">
            <button type="button" class="btn btn-dark" (click)='startSearch()'>Suchen</button>
          </div>
        </div>
      </div>

    </div>
    <div class="row align-items-end">
      <div class="col-sm-4">
        <h3 style="font-weight:bold">Projektübersicht</h3>
      </div>
      <div class="offset-sm-5 col-sm-2">
        <!--<label>Filter anwenden ({{viewModel.numberFilter}})</label>>-->
      </div>
    </div>
  </form>
  <div class="row">
    <div class="col-sm-12">
      <kendo-grid [data]="gridView" [pageSize]="pageSize" [skip]="skip" [pageable]="{ buttonCount: 5, info: true, pageSizes: pageSizes, previousNext: true }" [height]="540"
                  (pageChange)="pageChange($event)" (edit)="editHandler($event)"  (cellClick)="editHandler($event)"
                  [sortable]="true" [sort]="sort" (sortChange)="sortChange($event)" >
        <kendo-grid-column [width]="200" field="name" title="Projektname">
          <kendo-grid-messages
              pagerPage="Seite"
              pagerOf="von"
              pagerItems="Einträge"
              pagerItemsPerPage="Einträge pro Seite"
          >
          </kendo-grid-messages>
          <ng-template kendoGridCellTemplate let-dataItem>
            <span style="cursor: pointer">
             {{ dataItem.name }}
            </span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="100" field="number" title="Projektnummer"></kendo-grid-column>
        <kendo-grid-column [width]="100" field="endDate" title="Projektende" format="{0: yyyy}"></kendo-grid-column>
        <kendo-grid-column [width]="500" field="shortDescription" title="Info" encoded="false">
          <ng-template kendoGridCellTemplate let-dataItem="dataItem">
            <div [innerHTML]="logbookService.CreateAnchorTags(dataItem.shortDescription)"></div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="120" field="createdDate" title="Datum Info" format="{0: dd MMM yyyy}"></kendo-grid-column>
        <kendo-grid-column [width]="80"  field="stateName" title="Status"></kendo-grid-column>
        <kendo-grid-column [width]="150" field="enbwUnitName" title="Verantwortl. EnBW Einheit"></kendo-grid-column>
        <kendo-grid-column [width]="150" field="advisorName" title="Betreuer"></kendo-grid-column>
      </kendo-grid>
    </div>
  </div>
  <div class="row float-right">
    <div class="col-sm-12 float-right">{{ versionInfo }}</div>
  </div>
</div>
