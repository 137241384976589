import { AzurePerson } from 'src/app/core/models/AzurePerson';
import { AppService } from './../app.service';
import { Observable } from 'rxjs';
import { IUsersClient, UsersClient } from 'src/app/core/services/api-clients-generated.service';
import { map, catchError } from 'rxjs/operators';
import { ApiResult } from 'src/app/core/models/ApiResult';

export class UserApiClient {
  private userClient: IUsersClient;
  constructor(private appService: AppService) { }

  getCurrentUser(): Observable<ApiResult> {
    return this.getUserClient()
      .getCurrentUser()
      .pipe(
        map(items => {
          if (items.isSuccessful) {
            return this.appService.mapToApiResult(items, AzurePerson.mapFromUserModel(items.payload));
          }
          return items;
        }
        ),
        catchError(err => this.appService.setApiErrorHandling(err, 'ProjectTeamMemberApiClient: getAll'))
      );
  }

  getUserRoles(): Observable<string[]> {
    return this.getUserClient()
      .getCurrentUserRoles();
  }

  private getUserClient(): IUsersClient {
    if (!this.userClient) {
      this.userClient = new UsersClient(this.appService.http, this.appService.apiUrl);
    }
    return this.userClient;
  }
}
