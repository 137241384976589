import { Base } from './Base';
import { HelpToolTipModel } from '../services/api-clients-generated.service';

export class HelpToolTip extends Base {
  id: number;
  angularComponent: string;
  fieldId: string;
  toolTip: string;

  static mapFromHelpToolTipModels(items: HelpToolTipModel[]): HelpToolTip[] {
    return items.map(item => this.mapFromHelpToolTipModel(item));
  }

  static mapFromHelpToolTipModel(item: HelpToolTipModel): HelpToolTip {
    const newItem = new HelpToolTip();
    newItem.id = item.id;
    newItem.angularComponent = item.angularComponent;
    newItem.fieldId = item.fieldId;
    newItem.toolTip = item.toolTip;
    return newItem;
  }
}
