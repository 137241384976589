import { ITagsClient, TagsClient, TagListResult, TagModel } from './../../core/services/api-clients-generated.service';
import { AppService } from './../app.service';
import { IApiClient } from 'src/app/core/models/IApiClient';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiResult } from 'src/app/core/models/ApiResult';
import { Tag } from 'src/app/core/models/Tag';

export class TagApiClient implements IApiClient {
  private tagClient: ITagsClient;
  constructor(private appService: AppService) { }

  getAll(includeInactive: boolean): Observable<ApiResult> {
    return this.GetTagClient().get()
      .pipe(
        map(apiResult => {
          return this.appService.mapToApiResult(apiResult, Tag.mapToTags(apiResult.payload));
        })
      );
  }
  getFromId(id: number): Observable<ApiResult> {
    return this.appService.getErrorApiResult('PublicTag => Method not implemented\.');
  }
  getFromProjectId(projectId: number): Observable<ApiResult> {
    return this.appService.getErrorApiResult('PublicTag => Method not implemented\.');
  }
  create(model: any): Observable<ApiResult> {
    return this.appService.getErrorApiResult('PublicTag => Method not implemented\.');
  }
  createList(models: any[]): Observable<ApiResult> {
    return this.appService.getErrorApiResult('PublicTag => Method not implemented\.');
  }
  remove(id: number): Observable<ApiResult> {
    return this.appService.getErrorApiResult('PublicTag => Method not implemented\.');
  }
  removeList(ids: number[]): Observable<ApiResult> {
    return this.appService.getErrorApiResult('PublicTag => Method not implemented\.');
  }
  update(model: any): Observable<ApiResult> {
    return this.appService.getErrorApiResult('PublicTag => Method not implemented\.');
  }
  updateList(models: any[]): Observable<ApiResult> {
    return this.appService.getErrorApiResult('PublicTag => Method not implemented\.');
  }

  private GetTagClient(): ITagsClient {
    if (!this.tagClient) {
      this.tagClient = new TagsClient(this.appService.http, this.appService.apiUrl);
    }
    return this.tagClient;
  }
}
