import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';

export const RoutingPath = {
  project: 'project',
  projectComp: 'project/:comp',
  projectSearch: 'project/search',
  projectDescription: 'project/description',
  projectSetup: 'project/setup',
  projectLogbook: 'project/logbook',
  projectCosts: 'project/costs',
  projectTransfer: 'project/transfer',
  projectDocuments: 'project/documents',
  projectNew: 'project/new',
  projectNewMasterdata: 'project/new/masterdata',
  projectNewDescription: 'project/new/description',
  projectApproval: 'project/approval',
  report: 'report',
  reportBlackReport: 'report/blackreport',
  reportAllProject: 'report/allproject',
  reportMyProject: 'report/myproject',
  reportActualProject: 'report/actualproject',
  document: 'document',
  admin: 'admin',
  unknowUser: 'appcommon/unknowUser',
  importInfo: 'importInfoOverview',
  unauthorized: 'appcommon/unauthorized'
};

const routes: Routes = [
  { path: '', redirectTo: RoutingPath.projectSearch, pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
