import { AppService } from 'src/app/service/app.service';
import { CoreModule } from './../core/core.module';
import { AdminRoutingModule } from './admin-routing.module';
import { NgModule } from '@angular/core';
import { AdminComponent } from './admin.component';
import { ListAcademicPartnerComponent } from './list-academicpartner/list-academicpartner.component';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { AdminService } from './service/admin.service';
import { ListEnbwUnitComponent } from './list-enbw-unit/list-enbw-unit.component';
import { ImportInformationComponent } from './import-information/import-information.component';

@NgModule({
  declarations: [
    AdminComponent,
    ListAcademicPartnerComponent,
    ListEnbwUnitComponent,
    ImportInformationComponent
  ],
  imports: [
    CoreModule,
    AdminRoutingModule,
    HttpClientModule,
    HttpClientJsonpModule
  ],
  providers: [
    AdminService,
    AppService
  ]
})
export class AdminModule { }
