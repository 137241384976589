<ng-container *ngIf="uiController === UIControlTyp.MultiSelectList">
  <kendo-multiselect
    textField="name"
    valueField="id"
    [data]="AzurePersonList"
    [filterable]="true"
    (filterChange)="filterAzurePersons($event)"
    (valueChange)="valueChanged()"
    placeholder="Wählen Sie einen User aus!"
    [(ngModel)]="AzurePersons">
    <ng-template *ngIf="false" kendoDropDownListItemTemplate let-dataItem>
      <img src="{{ dataItem.image }}" alt="picture" width="40"> {{ dataItem.name }}
    </ng-template>
  </kendo-multiselect>
</ng-container>
<ng-container *ngIf="uiController === UIControlTyp.DropDownList">
  <kendo-dropdownlist
    class="form-control k-widget"
    textField="name"
    valueField="id"
    [data]="AzurePersonList"
    (valueChange)="valueChanged()"
    [(ngModel)]="AzurePerson">
    <ng-template *ngIf="false" kendoDropDownListItemTemplate let-dataItem>
      <img src="{{ dataItem.image }}" alt="" width="40"> {{ dataItem.name }}
    </ng-template>
  </kendo-dropdownlist>
</ng-container>
<ng-container *ngIf="uiController === UIControlTyp.GridSingleValueField">
  {{ getName() }}
</ng-container>
