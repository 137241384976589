import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { zip } from 'rxjs';
import { AppService } from 'src/app/service/app.service';
import { IApiClient } from '../core/models/IApiClient';
import { Observable } from 'rxjs';
import { ApiResult } from '../core/models/ApiResult';
import { Base } from '../core/models/Base';

const itemIndex = (item: any, data: any[]): number => {
  for (let idx = 0; idx < data.length; idx++) {
    if (data[idx].id === item.id) {
      return idx;
    }
  }

  return -1;
};
const cloneData = (data: any[]) => data.map(item => Object.assign({}, item));

@Injectable()
export class ListBehaviorService extends BehaviorSubject<any[]> {

  public data: any[] = [];
  private originalData: any[] = [];
  private createdItems: any[] = [];
  private updatedItems: any[] = [];
  private deletedItems: any[] = [];
  private projectId = 0;

  // tslint:disable-next-line: ban-types
  constructor(private apiClient: IApiClient, private appService: AppService) {
    super([]);
  }

  public read(reloadFromBackend: boolean = true, loadFromProjectId: number = 0) {
    this.projectId = loadFromProjectId;
    if (this.data.length) {
      return super.next(this.data);
    }
    if (reloadFromBackend) {
      let loadFunction: Observable<ApiResult>;
      if (loadFromProjectId !== 0) {
        loadFunction = this.appService.getFromProjectId(this.apiClient, loadFromProjectId);
      } else {
        loadFunction = this.appService.getAll(this.apiClient, true);
      }

      loadFunction.subscribe(apiResult => {
        if (!apiResult.isSuccessful) {
          this.appService.showNotification(apiResult.message, 'error');
        }
        if (apiResult.payload) {
          this.data = apiResult.payload;
          this.originalData = cloneData(apiResult.payload);
          super.next(this.data);
        }
      },
        err => this.appService.showNotification(err, 'error'));

    } else {
      this.data = cloneData(this.originalData);
      super.next(this.data);
    }
  }

  public create(item: any): void {
    this.createdItems.push(item);
    this.data.unshift(item);

    super.next(this.data);
  }

  public update(item: any): void {
    if (!this.isNew(item)) {
      const index = itemIndex(item, this.updatedItems);
      if (index !== -1) {
        this.updatedItems.splice(index, 1, item);
      } else {
        this.updatedItems.push(item);
      }
    } else {
      const index = this.createdItems.indexOf(item);
      this.createdItems.splice(index, 1, item);
    }
  }

  public remove(item: any): void {
    let index = itemIndex(item, this.data);
    this.data.splice(index, 1);

    index = itemIndex(item, this.createdItems);
    if (index >= 0) {
      this.createdItems.splice(index, 1);
    } else {
      this.deletedItems.push(item);
    }

    index = itemIndex(item, this.updatedItems);
    if (index >= 0) {
      this.updatedItems.splice(index, 1);
    }

    super.next(this.data);
  }

  public isNew(item: any): boolean {
    return !item.id;
  }

  public hasChanges(): boolean {
    return Boolean(this.deletedItems.length || this.updatedItems.length || this.createdItems.length);
  }

  /** Hiermit werden die Änderungen an das Backend weitergeleitet */
  public saveChanges(): void {
    if (!this.hasChanges()) {
      return;
    }

    const completed = [];

    if (this.deletedItems.length) {
      const deleteItem = this.deletedItems;
      completed.push(
        this.appService.removeList(this.apiClient, this.deletedItems.map((delItem: Base) => Number(delItem.id)))
          .pipe(
            map(response => {
              this.appService.showNotification(response.message, 'info');
              this.removeItem(deleteItem);
            })
          )
      );
    }

    if (this.updatedItems.length) {
      completed.push(
        this.appService.updateList(this.apiClient, this.updatedItems)
          .pipe(
            map(response => {
              this.appService.showNotification(response.message, 'info');
              this.updateItem(response.payload);
            })
          )
      );
    }

    if (this.createdItems.length) {
      completed.push(
        this.appService.addList(this.apiClient, this.createdItems)
          .pipe(
            map(response => {
              this.appService.showNotification(response.message, 'info');
              this.originalData.push(...response.payload);
            })
          )
      );
    }

    this.reset();

    zip(...completed)
      .subscribe(
        () => this.read(true, this.projectId),
        err => this.appService.showNotification(err.response, 'error')
      );
  }

  /** Hiermit wird aus der aktuellen Liste ein string erzeugt der die Id´s kommasepariert enthält */
  public getStringWithIds(): string {
    return this.data.map(item => item.userId).join();
  }

  public cancelChanges(): void {
    this.reset();

    this.data = this.originalData;
    this.originalData = cloneData(this.originalData);
    super.next(this.data);
  }

  public assignValues(target: any, source: any): void {
    Object.assign(target, source);
  }

  public hasName(name: string, id: number): boolean {
    return Boolean(
      this.findInOriginal(name, id) ||
      this.createdItems.find(item => item.id !== id && item.name === name) ||
      this.updatedItems.find(item => item.id !== id && item.name === name)
    );
  }

  public findInOriginal(name: string, id: number): boolean {
    if (this.originalData.find(item => item.id !== id && item.name === name)) {
      return (!this.deletedItems.find(item => item.id !== id && item.name === name));
    }
    return false;
  }

  reset() {
    this.data = [];
    this.deletedItems = [];
    this.updatedItems = [];
    this.createdItems = [];
  }

  private updateItem(items: any[]) {
    items.forEach(item => {
      const updateItem = this.originalData.find(newItem => newItem.id === item.id);
      const index = this.originalData.indexOf(updateItem);
      this.originalData[index] = item;
    });
  }
  private removeItem(items: any[]) {
    items.forEach(item => {
      const index = this.originalData.findIndex(x => x.id === item.id);
      this.originalData = this.originalData.slice(index, 1);
    });
  }
}
