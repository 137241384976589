import { ProjectLogbookService } from './project-logbook.service';
import { Component, Input, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { GridDataResult} from '@progress/kendo-angular-grid';
import { DialogService, DialogRef, DialogCloseResult } from "@progress/kendo-angular-dialog";
import { Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { State, process } from '@progress/kendo-data-query';
import { map } from 'rxjs/operators';
import { LogbookItem } from 'src/app/core/models/LogbookItem';
import { AdloginService } from 'src/app/service/adlogin.service';
import { AzurePerson } from 'src/app/core/models/AzurePerson';

@Component({
  selector: 'fdb-project-logbook',
  templateUrl: './project-logbook.component.html',
  styleUrls: ['./project-logbook.component.scss']
})
export class ProjectLogbookComponent implements OnInit, OnChanges {

  @Input() projectId: number;

  public view: Observable<GridDataResult>;
  public gridState: State = {
    sort: [],
    skip: 0,
    take: 10
  };
  editedRowIndex: any;
  editedRow: any;
  private currentUser: AzurePerson;
  public userCanRemoveEntries: boolean;

  constructor(
              private formBuilder: FormBuilder,
              public componentService: ProjectLogbookService,
              private adLoginService: AdloginService,
              private dialogService: DialogService) {
    this.componentService.reset();
    this.adLoginService.getCurrentUser()
                       .subscribe(person => this.currentUser = person);
  }

  ngOnInit() {
    this.userCanRemoveEntries = this.currentUser.isUserAdmin();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.projectId.currentValue > 0) {
      this.initValues();
    }
  }

  public initValues() {
    this.view = this.componentService
                    .pipe(map(data => process(data, this.gridState)));
    this.componentService.read(true, this.projectId);
  }

  public onStateChange(state: State) {
    this.gridState = state;
    this.componentService.read(true, this.projectId);
  }

  public editHandler({ sender, rowIndex, dataItem }) {
    this.closeEditor(sender);

    this.editedRowIndex = rowIndex;
    this.editedRow = Object.assign({}, dataItem);
    sender.editRow(rowIndex, this.createFormGroup(dataItem));
  }

  public addHandler({ sender }) {
    const newItem = new LogbookItem();
    newItem.projectId = this.projectId;
    newItem.date = new Date();
    newItem.userFullName = this.getCurrenUserFullName();
    sender.addRow(this.createFormGroup(newItem));
  }

  public cancelHandler({ sender, rowIndex }) {
    this.closeEditor(sender, rowIndex);
  }

  public removeHandler({ dataItem }) {
    this.confirmRemove(dataItem);
  }

  public saveHandler({ sender, formGroup, rowIndex, isNew }) {
    if (formGroup.valid) {
      if (isNew) {
        this.componentService.create(formGroup.value);
      } else {
        this.componentService.assignValues(this.editedRow, formGroup.value);
        this.componentService.update(this.editedRow);
      }
      this.closeEditor(sender, rowIndex);
      this.componentService.saveChanges();
    }
  }

  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.editedRow = undefined;
  }

  private removeEntry(dataItem) {
    this.componentService.remove(dataItem);
    this.componentService.saveChanges();
  }

  public createFormGroup(dataItem: LogbookItem): FormGroup {
    return this.formBuilder.group({
      id: [dataItem.id, Validators.required],
      projectId: [dataItem.projectId, Validators.required],
      date: dataItem.date,
      info: dataItem.info,
      userFullName: dataItem.userFullName,
    });
  }

  private getCurrenUserFullName(): string {
    return this.currentUser.name;
  }

  public autoGrow(event: any) {
    const textArea = event.target;

    if (textArea.offsetHeight != textArea.scrollHeight) {
      textArea.style.height = (textArea.scrollHeight) + "px";
    }
  }

  public confirmRemove(dataItem) {
    const dialog: DialogRef = this.dialogService.open({
      title: "Bitte bestätigen Sie",
      content: "Logbuch-Eintrag löschen?",
      actions: [{ text: "Abbrechen" }, { text: "Löschen", primary: true }],
      width: 450,
      height: 200,
      minWidth: 250,
    });

    dialog.result.subscribe((result) => {
      var yesNo = (result as any).text;
      if (yesNo === "Löschen") {
        this.removeEntry(dataItem);
      }
    });
  }
}
