import { Base } from './Base';
import { ProjectCostType } from './ProjectCostType';
import { ProjectCostItemAmount } from './ProjectCostItemAmount';
import { CostModel } from '../services/api-clients-generated.service';
export class ProjectCostItem extends Base {
  projectId: number;
  costType: ProjectCostType;
  date: Date;
  sum: number;
  amounts: ProjectCostItemAmount[] = [];

  static MapFromCostModels(items: CostModel[]): ProjectCostItem[] {
    return items.map(item => this.MapFromCostModel(item));
  }

  static MapFromCostModel(item: CostModel): ProjectCostItem {
    const newItem = new ProjectCostItem();
    newItem.id = item.id;
    newItem.projectId = item.projectId;
    newItem.costType = ProjectCostType.MapFromCostTypeModel(item.costType);
    newItem.date = item.date.toDate();
    newItem.amounts = item.costAmounts ? ProjectCostItemAmount.MapFromCostAmountModels(item.costAmounts) : [];
    newItem.sum = item.sumAmount;
    return newItem;
  }
}
