<mdb-navbar SideClass="navbar navbar-expand-sm navbar-dark bg-dark" [containerInside]="false">
  <!-- Navbar brand -->
  <links>
    <ul class="navbar-nav mr-auto">
      <li class="nav-item dropdown" dropdown routerLinkActive="active">
        <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
          Projekte<span class="caret"></span></a>
        <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
          <a class="dropdown-item waves-light" mdbWavesEffect routerLink='{{routingPath.projectSearch}}'
            routerLinkActive="active">Projektübersicht</a>
          <a  *ngIf="hasRoles" class="dropdown-item waves-light" mdbWavesEffect routerLink='{{routingPath.projectNewMasterdata}}'
            routerLinkActive="active">Neues
            Projekt</a>
        </div>
      </li>
      <li class="nav-item dropdown" dropdown routerLinkActive="active">
        <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
          Reports<span class="caret"></span></a>
        <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
          <a *ngIf="(isAdmin || isAdvisor || isApprover)" class="dropdown-item waves-light" mdbWavesEffect (click)='downloadReportBlacklist()' target="new"
            routerLinkActive="active">Blacklist Report</a>
          <a *ngIf="(isAdmin || isApprover)" class="dropdown-item waves-light" mdbWavesEffect (click)='downloadReportManagementBlacklist()' target="new"
            routerLinkActive="active">Management Blacklist Report</a>
          <a *ngIf="(isAdmin || isAdvisor || isApprover)" class="dropdown-item waves-light" mdbWavesEffect (click)="downloadReportAlleProjekte()" target="new"
            routerLinkActive="active">Alle
            Projekte</a>
          <a *ngIf="(isAdmin || isAdvisor || isApprover)" class="dropdown-item waves-light" mdbWavesEffect (click)="downloadReportAlleProjekteExcel()" target="new"
          routerLinkActive="active">Alle
          Projekte exportieren</a>

          <a class="dropdown-item waves-light" mdbWavesEffect (click)="downloadReportMeineProjekte()" target="new"
            routerLinkActive="active">Meine
            Projekte</a>
          <a *ngIf="appService.currentProjectId > 0" class="dropdown-item waves-light" mdbWavesEffect (click)="downloadReportAktuellesProjekt()"
            routerLinkActive="active">Aktuelles Projekt</a>
        </div>
      </li>
      <li class="nav-item dropdown" dropdown routerLinkActive="active">
        <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
          Dokumente<span class="caret"></span></a>
        <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
          <a class="dropdown-item waves-light" mdbWavesEffect href="https://teams.microsoft.com/_#/files/300_Projekte?threadId=19%3A1877691641814695bc913ce947b94d05%40thread.skype&ctx=channel&context=380_Projektdatenbank%252F381_NKV"
            routerLinkActive="active" target="_blank">Template NKV Datei</a>
        </div>
      </li>
      <li *ngIf="isAdmin" class="nav-item dropdown" dropdown routerLinkActive="active">
        <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
          Administration<span class="caret"></span></a>
        <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
          <a class="dropdown-item waves-light" mdbWavesEffect routerLink='{{routingPath.admin}}'
            routerLinkActive="active">Hilfslisten</a>
          <a class="dropdown-item waves-light" mdbWavesEffect routerLink='{{routingPath.importInfo}}'
            routerLinkActive="active">Wichtige Informationen</a>
          <a class="dropdown-item waves-light" mdbWavesEffect routerLink='{{routingPath.projectApproval}}/1'
            routerLinkActive="active">Freigabe Test Seite</a>
        </div>
      </li>
    </ul>
    <ul class="navbar-nav ml-auto nav-flex-icons">
      <li *ngIf="true" class="nav-item">
        <a class="nav-link">
          {{ AppService.isProjectChange ? 'Änderungen vorhanden' : 'Projekt gespeichert' }}<mdb-icon fas icon="{{ AppService.isProjectChange ? 'exclamation-circle' : 'check' }}"></mdb-icon>
        </a>
      </li>
      <li class="nav-item dropdown" dropdown routerLinkActive="active">
        <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
          <i class="fas fa-user"></i> {{ username }}<span class="caret"></span></a>
        <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
          <a class="dropdown-item waves-light" (click)="logout()" mdbWavesEffect>Logout</a>
        </div>
      </li>
    </ul>
  </links>
  <mdb-navbar-brand><a class="navbar-brand" routerLink='{{routingPath.projectSearch}}'></a></mdb-navbar-brand>
</mdb-navbar>
