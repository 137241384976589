<ng-container *ngIf="uiController === UIControlTyp.MultiSelectList">
  <kendo-multiselect
    textField="name"
    valueField="id"
    [filterable]="true"
    (filterChange)="filterAcademicPartnerList($event)"
    [data]="AcademicPartnerList"
    [(ngModel)]="AcademicPartners"
    placeholder="Wählen Sie einen Wert aus!"
    (valueChange)="valueChanged()">
  </kendo-multiselect>
</ng-container>
<ng-container *ngIf="uiController === UIControlTyp.DropDownList">
    <kendo-dropdownlist
    class="form-control"
    textField="name"
    valueField="id"
    [data]="AcademicPartnerList"
    [(ngModel)]="AcademicPartner"
    (valueChange)="valueChanged()">
  </kendo-dropdownlist>
</ng-container>
<ng-container *ngIf="uiController === UIControlTyp.GridSingleValueField">
    {{ getName() }}
</ng-container>
