import { ApiResult } from './../../core/models/ApiResult';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AdloginService } from 'src/app/service/adlogin.service';
import { AzurePerson } from 'src/app/core/models/AzurePerson';
import { RoutingPath } from 'src/app/app-routing.module';
import { ProjectService } from '../project.service';
import { Project } from 'src/app/core/models/Project.Model';
import { ProjectWorkspaceService } from 'src/app/service/project-workspace.service';

@Component({
  selector: 'fdb-approval',
  templateUrl: './approval.component.html',
  styleUrls: ['./approval.component.scss']
})
export class ApprovalComponent implements OnInit {
  private currentUser: AzurePerson;
  public currentId: number;
  public currentStateId: number;
  public project: Project;
  public comment: string;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private adLoginService: AdloginService,
              private projectService: ProjectService,
              private projectWorkspaceService: ProjectWorkspaceService) {
    adLoginService.getCurrentUser()
      .subscribe((currentUser: AzurePerson) => {
        if (currentUser) {
          this.currentUser = currentUser;
          if (this.currentUser.isUserApprovers() === false) {
            router.navigateByUrl('/' + RoutingPath.unauthorized);
          }
        }
      });

    route.params.subscribe(params => {
      this.currentId = params.id;
      this.currentStateId = params.stateId;
    });
   }

  ngOnInit() {
    this.projectService.getProjectFromId(this.currentId)
      .subscribe((result: ApiResult) => {
        if (result.isSuccessful) {
          this.project = result.payload;
        }
      })
  }

  approval() {
    this.projectService.approveProject(this.currentId, this.currentStateId, this.comment)
      .subscribe((result: ApiResult) => {
        if (result.isSuccessful) {
          this.projectWorkspaceService.createAndSafeReportOnApproval(this.project);
          this.router.navigateByUrl('/' + RoutingPath.projectSearch);
          this.projectService.showNotification(result.message, 'success');
        }
        else {
          this.projectService.showNotification(result.message, 'error');
        }
      });
    return;
  }

  rejection() {
    this.projectService.rejectProject(this.currentId, this.currentStateId, this.comment)
      .subscribe((result: ApiResult) => {
        if (result.isSuccessful) {
          this.router.navigateByUrl('/' + RoutingPath.projectSearch);
          this.projectService.showNotification(result.message, 'success');
        }
        else {
          this.projectService.showNotification(result.message, 'error');
        }
      });
    return;
  }
}
