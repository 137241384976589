import { AppService } from './../app.service';
import { IApiClient } from 'src/app/core/models/IApiClient';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { IEnBWUnitsClient, EnBWUnitsClient } from 'src/app/core/services/api-clients-generated.service';
import { ApiResult } from 'src/app/core/models/ApiResult';
import { EnBWUnit } from 'src/app/core/models/EnBWUnit';

export class EnBWUnitApiClient implements IApiClient {
  private enbwUnitClient: IEnBWUnitsClient;

  constructor(private appService: AppService) { }

  getAll(includeInactive: boolean): Observable<ApiResult> {
    return this.GetEnBWUnitClient()
      .get(includeInactive)
      .pipe(
        map(items =>
          this.appService.mapToApiResult(items, EnBWUnit.mapFromEnBWUnitModels(items.payload))
        ),
        catchError(err => this.appService.setApiErrorHandling(err, 'EnBWUnitApiClient: getAll'))
      );
  }

  getFromId(id: number): Observable<ApiResult> {
    return this.appService.getErrorApiResult('EnBWUnit => Method not implemented\.');
  }

  getFromProjectId(projectId: number): Observable<ApiResult> {
    return this.appService.getErrorApiResult('EnBWUnit => Method not implemented\.');
  }

  createList(models: any[]): Observable<ApiResult> {
    return this.GetEnBWUnitClient()
      .insertList(EnBWUnit.mapToInsertEnBWUnitModel(models))
      .pipe(
        map(items => {
          return this.appService.mapToApiResult(items, EnBWUnit.mapFromEnBWUnitModels(items.payload));
        }),
        catchError(err => this.appService.setApiErrorHandling(err, 'EnBWUnitApiClient: createList'))
      );
  }

  removeList(ids: number[]): Observable<ApiResult> {
    return this.appService.getErrorApiResult('EnBWUnit => Method not implemented\.');
  }

  updateList(models: any[]): Observable<ApiResult> {
    return this.GetEnBWUnitClient()
      .updateList(EnBWUnit.mapToEnBWUnitModels(models))
      .pipe(
        map(items => {
          return this.appService.mapToApiResult(items, EnBWUnit.mapFromEnBWUnitModels(items.payload));
        }),
        catchError(err => this.appService.setApiErrorHandling(err, 'EnBWUnitApiClient: updateList'))
      );
  }

  private GetEnBWUnitClient(): IEnBWUnitsClient {
    if (!this.enbwUnitClient) {
      this.enbwUnitClient = new EnBWUnitsClient(this.appService.http, this.appService.apiUrl);
    }
    return this.enbwUnitClient;
  }
}
