import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RoutingPath } from '../app-routing.module';
import { ReportsComponent } from './reports.component';
import { MsalGuard } from '@azure/msal-angular';
import { AdloginService } from '../service/adlogin.service';

const routes: Routes = [
  {
    path: RoutingPath.report, component: ReportsComponent, canActivate: [MsalGuard],
    resolve: { currentUser: AdloginService }
  },
  {
    path: RoutingPath.reportBlackReport, component: ReportsComponent, canActivate: [MsalGuard],
    resolve: { currentUser: AdloginService }
  },
  {
    path: RoutingPath.reportAllProject, component: ReportsComponent, canActivate: [MsalGuard],
    resolve: { currentUser: AdloginService }
  },
  {
    path: RoutingPath.reportMyProject, component: ReportsComponent, canActivate: [MsalGuard],
    resolve: { currentUser: AdloginService }
  },
  {
    path: RoutingPath.reportActualProject, component: ReportsComponent, canActivate: [MsalGuard],
    resolve: { currentUser: AdloginService }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportsRoutingModule { }
