import { AppService } from './../app.service';
import { IApiClient } from 'src/app/core/models/IApiClient';
import { Observable } from 'rxjs';
import { ApiResult } from 'src/app/core/models/ApiResult';
import { IRisksClient, RisksClient } from 'src/app/core/services/api-clients-generated.service';
import { ProjectRisk } from 'src/app/core/models/ProjectRisk';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';

export class ProjectRiskApiClient implements IApiClient {
  private projectriskClient: IRisksClient;
  constructor(private appService: AppService) { }

  getAll(includeInactive: boolean): Observable<ApiResult> {
    return this.appService.getErrorApiResult('ProjectRisk => Method getAll not implemented\.');
  }
  
  getFromId(id: number): Observable<ApiResult> {
    return this.appService.getErrorApiResult('ProjectRisk => Method getFromIdnot implemented\.');
  }

  getFromProjectId(projectId: number): Observable<ApiResult> {
    return this.getProjectRiskClient()
      .get(projectId)
      .pipe(
        map(items =>
          this.appService.mapToApiResult(items, ProjectRisk.MapFromRiskModels(items.payload))
        ),
        catchError(err => this.appService.setApiErrorHandling(err, 'ProjectRiskApiClient: getFromProjectId'))
      );
  }

  createList(models: any[]): Observable<ApiResult> {
    return this.getProjectRiskClient()
      .insertList(ProjectRisk.MapToInsertRiskModels(models))
      .pipe(
        map(items =>
          this.appService.mapToApiResult(items, ProjectRisk.MapFromRiskModels(items.payload))
        ),
        catchError(err => this.appService.setApiErrorHandling(err, 'ProjectRiskApiClient: createList'))
      );
  }

  removeList(ids: number[]): Observable<ApiResult> {
    return this.getProjectRiskClient()
    .deleteList(ids)
    .pipe(
      map(items =>
        this.appService.mapToApiResult(items, [])
        ),
        catchError(err => this.appService.setApiErrorHandling(err, 'ProjectRiskApiClient: removeList'))
    );
  }

  updateList(models: any[]): Observable<ApiResult> {
    return this.getProjectRiskClient()
      .updateList(ProjectRisk.MapToUpdateRiskModels(models))
      .pipe(
        map(items =>
          this.appService.mapToApiResult(items, ProjectRisk.MapFromRiskModels(items.payload))
        ),
        catchError(err => this.appService.setApiErrorHandling(err, 'ProjectRiskApiClient: updateList'))
      );
  }

  private getProjectRiskClient(): IRisksClient {
    if (!this.projectriskClient) {
      this.projectriskClient = new RisksClient(this.appService.http, this.appService.apiUrl);
    }
    return this.projectriskClient;
  }
}
