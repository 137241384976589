<button (click)="ladeWerte()">Lade Werte</button>
<table class="table">
    <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Key</th>
        <th scope="col">Value</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of items; let i = index">
        <th scope="row">{{i}}</th>
        <td>{{item.key}}</td>
        <td>{{item.value}}</td>
      </tr>
    </tbody>
  </table>
