import { ProjectSearchViewModel } from './../../core/models/ProjectSearchViewModel';
import { ProjectLogbookService } from './../details/logbook/project-logbook.service';
import { Component } from '@angular/core';
import { GridDataResult, PageChangeEvent, PageSizeItem } from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy } from "@progress/kendo-data-query";
import { ProjectService } from '../project.service';
import { Project } from '../../core/models/Project.Model';
import { AppConfigService } from 'src/app/core/services/app-config.service'
import { RoutingPath } from 'src/app/app-routing.module';
import { Router } from '@angular/router';
import { UIControlTyp } from 'src/app/core/models/UIControlTyp';
import { ProjectOverviewStatistic } from 'src/app/core/models/ProjectOverviewStatistic';
import { ProjectFilter } from 'src/app/core/models/ProjectFilter';
import { AppService } from 'src/app/service/app.service';
import { LocalStorageService } from '../local-storage.service';
import { environment } from 'src/environments/environment';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'fdb-search-project',
  templateUrl: './search-project.component.html',
  styleUrls: ['./search-project.component.scss']
})
export class SearchProjectComponent {
  public UIControlTyp = UIControlTyp;
  public gridView: GridDataResult;
  public pageSize = 20;
  public pageSizes: boolean | Array<number> = [ 10, 20, 50 ];
  public pageSizesX: PageSizeItem[] = [
    {text: '10', value: 10},
    {text: '20', value: 20},
    {text: '50', value: 50 },
    {text: 'All', value: 'all'}];
  public skip = 0;
  public viewModel: ProjectSearchViewModel = new ProjectSearchViewModel();
  public currentYear = (new Date).getFullYear();
  public assemblyVersion: string = "";
  public versionInfo: string = "";

  private items: Project[];
  public sort: SortDescriptor[] = [
    {
      field: "ProductName",
      dir: "asc",
    },
  ];

  constructor(
      public projectService: ProjectService,
      public logbookService: ProjectLogbookService,
      private router: Router,
      private appService: AppService,
      private localstorageService: LocalStorageService) {
    this.viewModel.statistik = new ProjectOverviewStatistic();
    this.viewModel.numberFilter = 0;
    this.viewModel.year = new Date();
  }

  ngOnInit() {
    const size = parseInt(this.localstorageService.getLastPageSizeItem(), 10);
     if(!isNaN(size)){
      this.pageSize = size;
     }

    if(JSON.parse(this.localstorageService.getLastFilterStateItem()) !== null)
    {
      const filter = ProjectFilter.mapToProjectFilterModel(JSON.parse(this.localstorageService.getLastFilterStateItem()));
      this.viewModel.year = filter.year === undefined || filter.year < 2000 ? null : new Date(filter.year, 2, 1);
      this.viewModel.advisorId = filter.advisorId;
      this.viewModel.orgaUnit = filter.orgaUnit;
      this.viewModel.enbwUnitId = filter.enBWUnitId;
      this.viewModel.stateId = filter.stateId;
      this.viewModel.projectManagerId = filter.projectManagerId;
      this.viewModel.freetext = filter.freeText;
    }
    this.appService.currentProjectId = null;
    this.assemblyVersion = AppConfigService.settings.assemblyVersion;
    const stg = AppConfigService.settings;
    let environmentDisplayName = environment.displayName;
    if ((environmentDisplayName === 'Development Stage') && (stg.stage != 1)) {
      if (stg.stage == 2) {
        environmentDisplayName = 'Test Stage';
      }
      else if (stg.stage == 3) {
        environmentDisplayName = '';  // Production
      }
    }

    this.versionInfo = ((environmentDisplayName != '') ? ' ' + environmentDisplayName + ', ' : '') + 'Version ' + stg.assemblyVersion +
                       ((stg.ignoreMail) ? ', Test-Umgebung: kein Versand von Infomails' :
                             ((stg.mailRedirect && stg.mailRedirect != '') ? ', Test-Umgebung: Mailversand immer an ' + stg.mailRedirect : ''));
    this.startSearch();
  }

  public startSearch() {
    this.localstorageService.setFilterItems(ProjectFilter.mapFromProjectSearchViewModel(this.viewModel));
    this.projectService.getFilteredProjects(ProjectFilter.mapFromProjectSearchViewModel(this.viewModel))
    .subscribe(apiResult => {
      if (!apiResult.isSuccessful) {
        this.projectService.showNotification(apiResult.message, 'error');
      }
        if (apiResult.payload) {
          this.items = apiResult.payload.projects;
          this.viewModel.statistik = apiResult.payload.statistics;
          this.skip = 0;
          this.loadItems();
        }
      },
      err => this.projectService.showNotification(err, 'error'));
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take;
    this.loadItems();
    this.localstorageService.setPageSizeItem(this.pageSize);
  }

  public sortChange(sort: Array<SortDescriptor>): void {
    this.sort = sort;
    this.loadItems();

  }

  public editHandler(event: any) {
    const project: Project = event.dataItem;
    if(project.stateId === 1 || project.stateId === 2)
    {
      this.router.navigate(['/' + RoutingPath.projectDescription, project.id ]);
    }
    else
    {
      this.router.navigate(['/' + RoutingPath.projectLogbook, project.id ]);
    }
  }

  private loadItems(): void {
    this.gridView = {
      data: orderBy(this.items, this.sort).slice(this.skip, this.skip + this.pageSize),
      total: this.items.length,
    };
  }
}


