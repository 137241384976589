import { Injectable } from '@angular/core';
import { ProjectFilter } from '../core/models/ProjectFilter';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  public setFilterItems(filter: ProjectFilter){
    localStorage.setItem('filter', JSON.stringify(filter));
  }

  public setPageSizeItem(pageSize: number){
    localStorage.setItem('pageSize', JSON.stringify(pageSize));
  }

  public getLastPageSizeItem(){
    return localStorage.getItem('pageSize');
  }

  public getLastFilterStateItem(){
    return localStorage.getItem('filter');
  }

}
