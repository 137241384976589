import { ProjectTeamMember } from 'src/app/core/models/ProjectTeamMember';
import { AzurePerson } from 'src/app/core/models/AzurePerson';
import { AppService } from './../app.service';
import { IApiClient } from 'src/app/core/models/IApiClient';
import { Observable } from 'rxjs';
import { IUsersClient, UsersClient } from 'src/app/core/services/api-clients-generated.service';
import { map, catchError } from 'rxjs/operators';
import { ApiResult } from 'src/app/core/models/ApiResult';

export class ProjectTeamMemberApiClient implements IApiClient {
  private userClient: IUsersClient;
  private controller = 'ProjectTeamMembers';
  private roleName = 'Projekt Team Mitglied';

  constructor(private appService: AppService) { }

  getAll(includeInactive: boolean): Observable<ApiResult> {
    return this.getUserClient()
      .get()
      .pipe(
        map(items =>
          this.appService.mapToApiResult(items,
            AzurePerson.mapFromUserModels(items.payload))
        ),
        catchError(err => this.appService.setApiErrorHandling(err, 'ProjectTeamMemberApiClient: getAll'))
      );
  }
  getFromId(id: number): Observable<ApiResult> {
    return this.appService.getErrorApiResult('ProjectTeamMember => Method getFromId not implemented\.');
  }
  getFromProjectId(projectId: number): Observable<ApiResult> {
    return this.getUserClient()
      .getForProject(projectId)
      .pipe(
        map(items =>
          this.appService.mapToApiResult(items,
            ProjectTeamMember.mapFromUserModels(items.payload, projectId))
        ),
        catchError(err => this.appService.setApiErrorHandling(err, 'ProjectTeamMemberApiClient: getFromProjectId'))
      );
  }
  create(model: any): Observable<ApiResult> {
    return this.appService.getErrorApiResult('ProjectTeamMember => Method create not implemented\.');
  }
  createList(models: any[]): Observable<ApiResult> {
    return this.appService.getErrorApiResult('ProjectTeamMember => Method createList not implemented\.');
  }
  remove(id: number): Observable<ApiResult> {
    return this.appService.getErrorApiResult('ProjectTeamMember => Method remove not implemented\.');
  }
  removeList(ids: number[]): Observable<ApiResult> {
    return this.appService.getErrorApiResult('ProjectTeamMember => Method removeList not implemented\.');
  }
  update(model: any): Observable<ApiResult> {
    return this.appService.getErrorApiResult('ProjectTeamMember => Method update not implemented\.');
  }
  updateList(models: any[]): Observable<ApiResult> {
    return this.appService.getErrorApiResult('ProjectTeamMember => Method updateList not implemented\.');
  }

  private getUserClient(): IUsersClient {
    if (!this.userClient) {
      this.userClient = new UsersClient(this.appService.http, this.appService.apiUrl);
    }
    return this.userClient;
  }
}
