import { AppService } from './../app.service';
import { IApiClient } from 'src/app/core/models/IApiClient';
import { AcademicPartner } from 'src/app/core/models/AcademicPartner';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { IAcademicPartnersClient, AcademicPartnersClient } from 'src/app/core/services/api-clients-generated.service';
import { ApiResult } from 'src/app/core/models/ApiResult';

export class AcademicPartnerApiClient implements IApiClient {
  private academicPartnerClient: IAcademicPartnersClient;

  constructor(private appService: AppService) { }

  //#region implemented Methode
  getAll(includeInactive: boolean): Observable<ApiResult> {
    return this.getAcademicPartnerClient()
      .get(includeInactive)
      .pipe(
        map(items =>
          this.appService.mapToApiResult(items, AcademicPartner.mapFromAcademicPartnerModels(items.payload))
        ),
        catchError(err => this.appService.setApiErrorHandling(err, 'Academic Partner: getAll'))
      );
  }
  getFromId(id: number): Observable<ApiResult> {
    return this.appService.getErrorApiResult('Academic Partner => Method getFromId not implemented\.');
  }
  getFromProjectId(projectId: number): Observable<ApiResult> {
    return this.appService.getErrorApiResult('Academic Partner => Method getFromProjectId not implemented\.');
  }
  createList(models: any[]): Observable<ApiResult> {
    return this.getAcademicPartnerClient()
      .insertList(AcademicPartner.mapToAddAcademicPartnerModels(models))
      .pipe(
        map(items => this.appService
          .mapToApiResult(items, AcademicPartner.mapFromAcademicPartnerModels(items.payload))
        ),
        catchError(err => this.appService.setApiErrorHandling(err, 'Academic Partner: createList'))
      );
  }
  removeList(id: number[]): Observable<ApiResult> {
    return this.appService.getErrorApiResult('Academic Partner => Method removeList not implemented\.');
  }
  updateList(models: any[]): Observable<ApiResult> {
    return this.getAcademicPartnerClient()
      .updateList(AcademicPartner.mapToAcademicPartnerModels(models))
      .pipe(
        map(items => this.appService.
          mapToApiResult(items, AcademicPartner.mapFromAcademicPartnerModels(items.payload))
        ),
        catchError(err => this.appService.setApiErrorHandling(err, 'Academic Partner: updateList'))
      );
  }
  //#endregion

  private getAcademicPartnerClient(): IAcademicPartnersClient {
    if (!this.academicPartnerClient) {
      this.academicPartnerClient = new AcademicPartnersClient(this.appService.http, this.appService.apiUrl);
    }
    return this.academicPartnerClient;
  }
}
