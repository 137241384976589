import { Injectable } from '@angular/core';
import { ProjectService } from 'src/app/project/project.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectSetupTeamService{

  constructor(public modulService: ProjectService) {
  }
}
