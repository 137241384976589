import { ProjectTeamMemberComponent } from './project-team-member/project-team-member.component';
import { CoreModule } from './../core/core.module';
import { NgModule } from '@angular/core';
import { EnbwUnitComponent } from './enbwunit/enbwunit.component';
import { ProjectStateComponent } from './project-state/project-state.component';
import { YesnoComponent } from './yesno/yesno.component';
import { TagsComponent } from './tags/tags.component';
import { AcademicPartnersComponent } from './academic-partners/academic-partners.component';
import { SharedService } from './shared.service';
import { ProjectTypeComponent } from './project-type/project-type.component';
import { PublicFundingComponent } from './public-funding/public-funding.component';
import { ProjectRiskRatingComponent } from './project-risk-rating/project-risk-rating.component';
import { AdvisorComponent } from './advisor/advisor.component';
import { ProjectManagerComponent } from './project-manager/project-manager.component';
import { SwitchInputComponent } from './switch-input/switch-input.component';
import { ProjectTransferSuccessComponent } from './project-transfer-success/project-transfer-success.component';
import { ProjectTransferManagerComponent } from './project-transfer-manager/project-transfer-manager.component';
import { AzurePersonComponent } from './azure-person/azure-person.component';

@NgModule({
  declarations: [
    EnbwUnitComponent,
    ProjectStateComponent,
    YesnoComponent,
    TagsComponent,
    AcademicPartnersComponent,
    ProjectTypeComponent,
    PublicFundingComponent,
    ProjectRiskRatingComponent,
    AdvisorComponent,
    ProjectManagerComponent,
    ProjectTeamMemberComponent,
    SwitchInputComponent,
    AzurePersonComponent,
    ProjectTransferSuccessComponent,
    ProjectTransferManagerComponent],
  imports: [
    CoreModule
  ],
  exports: [
    EnbwUnitComponent,
    ProjectStateComponent,
    YesnoComponent,
    TagsComponent,
    AcademicPartnersComponent,
    ProjectTypeComponent,
    PublicFundingComponent,
    ProjectRiskRatingComponent,
    AdvisorComponent,
    ProjectManagerComponent,
    ProjectTeamMemberComponent,
    SwitchInputComponent,
    AzurePersonComponent,
    ProjectTransferSuccessComponent,
    ProjectTransferManagerComponent
  ],
  providers: [SharedService]
})
export class SharedModule { }
