export class Base {
  id: number | string = 0;
  setId(id: number | string) {
    this.id = id;
    return this;
  }
  getArrayFromIds<T extends Base>(type: new () => T, ids: (string | number)[]): T[] {
    const results: T[] = [];
    ids.forEach(id => {
      const tag = new type();
      tag.id = id;
      results.push(tag);
    });
    return results;
  }
}
