import { SharedService } from './../shared.service';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Tag } from 'src/app/core/models/Tag';
import { UIControlTyp } from 'src/app/core/models/UIControlTyp';

@Component({
  selector: 'fdb-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss']
})
export class TagsComponent {
  public UIControlTyp = UIControlTyp;
  /** Hiermit können Sie den UIControlTyp entscheiden => Default DropDownList */
  @Input() uiController: UIControlTyp = UIControlTyp.DropDownList;
  // tslint:disable-next-line: no-input-rename
  @Input('values')
  get values() {
    const val = this.uiController === UIControlTyp.MultiSelectList ?
      this.Tags.map(unit => unit.id.toString()) :
      this.Tag.id.toString();
    return val;
  }
  set values(val: string | number | (string | number)[]) {
    this.uiController === UIControlTyp.MultiSelectList ?
      this.Tags = (val as (string | number)[]).map(id => this.getObjectFromList(id.toString())) :
      this.Tag = this.getObjectFromList(val.toString());
  }
  @Output() valuesChange = new EventEmitter();

  /** Enthält die ausgewählten Werte bei UIControlTyp.MultiSelectList */
  public Tags: Tag[] = [];
  /** Enthält die ausgewählten Wert bei UIControlTyp.DropDownList */
  public Tag: Tag;
  /** Enthält die Werte die angezeigt werden dürfen */
  public TagList: Tag[] = [];
  /** Enthält die Originalliste mit allen Werten */
  private OriginalValues: Tag[] = [];

  constructor(private modulService: SharedService) { this.initTags(); }

  initTags() {
    this.modulService.getTags()
      .subscribe(apiResult => {
        if (!apiResult.isSuccessful) {
          this.modulService.showNotification(apiResult.message, 'error');
        }
        if (apiResult.payload) {
          this.OriginalValues = apiResult.payload;
          this.TagList = this.OriginalValues.filter(s => s.isActive === true);
          if (this.uiController !== UIControlTyp.DropDownList) {
            this.TagList.unshift(new Tag().getEmptyModel());
          }
        }
      },
        err => this.modulService.showNotification(err, 'error'));
  }

  valueNormalizer = (text$: Observable<string>) => text$.pipe(
    map((text: string) => {
      // search for matching item to avoid duplicates

      // search in values
      const matchingValue: any = this.OriginalValues.find((item: Tag) => {
        return item.name.toLowerCase() === text.toLowerCase();
      });

      if (matchingValue) {
        return matchingValue; // return the already selected matching value and the component will remove it
      }

      // search in data
      const matchingItem: any = this.TagList.find((item: Tag) => {
        return item.name.toLowerCase() === text.toLowerCase();
      });

      if (matchingItem) {
        return matchingItem;
      } else {
        return new Tag().setInitValue(text, text);
      }
    }))

  filterTags(value: string) {
    this.TagList = this.OriginalValues
      .filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1 && s.isActive === true);
  }

  valueChanged(): void {
    this.valuesChange.emit(this.values);
  }

  getObjectFromList(id: string): Tag {
    const find = this.OriginalValues.find(unit => unit.id === id);
    return find ? find : new Tag().setInitValue(id.toString(), id);
  }
  getName() {
    const find = this.getObjectFromList(this.Tag.id.toString());
    return find ? find.name : '';
  }
}
