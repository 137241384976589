import { AppService } from './../app.service';
import { IApiClient } from 'src/app/core/models/IApiClient';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IProjectTypesClient, ProjectTypesClient } from 'src/app/core/services/api-clients-generated.service';
import { ApiResult } from 'src/app/core/models/ApiResult';
import { ProjectType } from 'src/app/core/models/ProjectType';

export class ProjectTypeApiClient implements IApiClient {
  private projectTypClient: IProjectTypesClient;
  constructor(private appService: AppService) { }

  getAll(includeInactive: boolean): Observable<ApiResult> {
    return this.GetProjectTypClient().get(includeInactive)
      .pipe(
        map(response => {
          return this.appService.mapToApiResult(response, ProjectType.mapFromProjectTypeModel(response.payload));
        })
      );
  }
  getFromId(id: number): Observable<ApiResult> {
    return this.appService.getErrorApiResult('ProjectType=> Method not implemented\.');
  }
  getFromProjectId(projectId: number): Observable<ApiResult> {
    return this.appService.getErrorApiResult('ProjectType=> Method not implemented\.');
  }
  create(model: any): Observable<ApiResult> {
    return this.appService.getErrorApiResult('ProjectType=> Method not implemented\.');
  }
  createList(models: any[]): Observable<ApiResult> {
    return this.appService.getErrorApiResult('ProjectType=> Method not implemented\.');
  }
  remove(id: number): Observable<ApiResult> {
    return this.appService.getErrorApiResult('ProjectType=> Method not implemented\.');
  }
  removeList(ids: number[]): Observable<ApiResult> {
    return this.appService.getErrorApiResult('ProjectType=> Method not implemented\.');
  }
  update(model: any): Observable<ApiResult> {
    return this.appService.getErrorApiResult('ProjectType=> Method not implemented\.');
  }
  updateList(models: any[]): Observable<ApiResult> {
    return this.appService.getErrorApiResult('ProjectType=> Method not implemented\.');
  }

  private GetProjectTypClient(): IProjectTypesClient {
    if (!this.projectTypClient) {
      this.projectTypClient = new ProjectTypesClient(this.appService.http, this.appService.apiUrl);
    }
    return this.projectTypClient;
  }
}
