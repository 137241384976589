import { ProjectMasterdataComponent } from './../details/masterdata/project-masterdata.component';
import { Component, Input, EventEmitter, Output, OnChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { RoutingPath } from 'src/app/app-routing.module';
import { Location } from '@angular/common';
import { Project } from '../../core/models/Project.Model';
import { ProjectService } from '../project.service';
import { MatOptionSelectionChange } from '@angular/material/core';
import { addMonths, addYears, lastMonthOfYear, lastDayOfMonth, firstDayOfMonth } from '@progress/kendo-date-math';

@Component({
  selector: 'fdb-new-project',
  templateUrl: './new-project.component.html',
  styleUrls: ['./new-project.component.scss']
})

export class NewProjectComponent {
  project = new Project();
  routingPath = RoutingPath;
  private currentStep: string;
  setValidation = false;
  displayMasterDate = false;
  displayDescription = false;

  constructor(private router: Router, private location: Location, private modulService: ProjectService) {
    this.project.stateId = 1;
    let currentDate = new Date();
    this.project.startDate= addMonths(firstDayOfMonth(currentDate),1);
    this.project.endDate = lastDayOfMonth(lastMonthOfYear(addYears(currentDate,1)));
    this.setCurrentStep(this.router.url.substring(1, this.router.url.length));
  }

  goToLocation(url: string) {
    this.setCurrentStep(url);
    if (this.currentStep === url) {
      this.location.go(url);
    }
  }

  onSubmit() {
    if (this.isValid() === false) { return; }

    this.modulService.addProject(this.project)
      .subscribe(project => {
        if (project.isSuccessful) {
          this.modulService.showNotification('Neuanlage erfolgreich durchgeführt', 'success');
          this.cancel();
        } else {
          this.modulService.showNotification(project.message, 'error');
        }
      });
  }

  cancel() {
    this.router.navigate(['/' + RoutingPath.projectSearch]);
  }

  setCurrentStep(url: string) {
    if (url === this.routingPath.projectNewDescription && this.isValid() === false) {
      this.goToLocation(this.routingPath.projectNewMasterdata);
      return;
    } else {
      switch (url) {
        case this.routingPath.projectNewDescription:
          this.displayMasterDate = false;
          this.displayDescription = true;
          break;
        case this.routingPath.projectNewMasterdata:
        default:
          this.displayMasterDate = true;
          this.displayDescription = false;
          break;
      }
      this.currentStep = url;
    }
  }

  public isValid(): boolean {
    if (!this.project.name || this.project.name === '') {
      this.modulService.showNotification('Sie haben keinen Projektnamen vergeben.'
        + ' Gehen Sie zu den Stammdaten und vergeben Sie einen Namen', 'warning');
      this.setValidation = true;
      return false;
    }
    return true;
  }

  public getActiveClass(url: string): string {
    return this.isRouteActive(url) ? 'active' : '';
  }

  public isRouteActive(url: string): boolean {
    return (this.currentStep === url);
  }
}
