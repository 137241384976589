import { ProjectStatisticsModel } from "../services/api-clients-generated.service";

export class ProjectOverviewStatistic{
    year: number = 0;
    activeProjectCount: number = 0;
    closedProjectCount: number = 0;
    plannedProjectCount: number = 0;
    inTransferProjectCount: number = 0;

    static mapFromProjectStatisticsModel(items: ProjectStatisticsModel){
        const newItem = new ProjectOverviewStatistic();
        newItem.year = items.year;
        newItem.activeProjectCount = items.activeProjectCount;
        newItem.closedProjectCount = items.closedProjectCount;
        newItem.plannedProjectCount = items.plannedProjectCount;
        newItem.inTransferProjectCount = items.inTransferProjectCount;
        return newItem;
    }

}