import { DDBase } from './DDBase';
import { InsertEnBWUnitModel, EnBWUnitModel } from '../services/api-clients-generated.service';

export class EnBWUnit extends DDBase {

  static mapToInsertEnBWUnitModel(items: EnBWUnit[]): InsertEnBWUnitModel[] {
    return items.map(item => {
      const newItem = new InsertEnBWUnitModel();
      newItem.name = item.name;
      return newItem;
    });
  }
  static mapFromEnBWUnitModels(items: EnBWUnitModel[]): EnBWUnit[] {
    return items.map(item => EnBWUnit.mapFromEnBWUnitModel(item));
  }
  static mapFromEnBWUnitModel(item: EnBWUnitModel): EnBWUnit {
    const newItem = new EnBWUnit();
    if (item) {
      newItem.id = item.id;
      newItem.name = item.name;
      newItem.isActive = item.isActive;
      return newItem;
    }
    return newItem.getEmptyModel();
  }
  static mapToEnBWUnitModels(items: EnBWUnit[]): EnBWUnitModel[] {
    return items.map(item => {
      const newItem = new EnBWUnitModel();
      newItem.id = Number(item.id);
      newItem.name = item.name;
      newItem.isActive = item.isActive;
      return newItem;
    });
  }
}
