
<kendo-dialog *ngIf="opened" (close)="close()">
  <kendo-dialog-titlebar>
    <div style="font-size: 18px; line-height: 1.3em;">
      <span class="k-icon k-i-warning"></span> Hinweis für Projektleiter
    </div>
  </kendo-dialog-titlebar>
  <p>Externe Leistungen in Forschungsprojekten können aufgrund gesetzlicher Vorgaben ausschreibungspflichtig sein. Das Datenbanksystem
    informiert deshalb den EnBW-Einkauf frühestmöglich über jedes neue Vorhaben, spätestens jedoch, wenn der Projektstatus auf
    "beantragt" wechselt.</p>
  <p>Bitte entscheiden Sie, ob die Vorhabensinhalte jetzt bereits klar genug sind für eine Beurteilung. Der Einkauf
    entscheidet dann, ob er Kontakt mit ihnen aufnimmt.</p>

  <kendo-dialog-actions>
    <button kendoButton (click)="onSetYes()">Ja, die Daten sind vollständig, bitte Infomail jetzt versenden</button>
    <button kendoButton (click)="close()" [primary]="true">Nein, bitte noch nichts versenden, es fehlen noch Informationen</button>
  </kendo-dialog-actions>
</kendo-dialog>
