<form #form="ngForm">

<div class="form-group row">
  <label for="name" class="col-sm-2 col-form-label bold">Projektdauer</label>
</div>

<div class="form-group row">
  <label for="name" class="col-sm-2 col-form-label">Start (geplant)
    <ng-container *ngIf="allHelpToolTips && allHelpToolTips.projectStartDate">
      <img [src]="imgname" [title]="allHelpToolTips.projectStartDate" />
    </ng-container>
  </label>
  <div class="col-sm-3">
    <kendo-datepicker class="form-control" [format]="'MMMM, yyyy'"  [formatPlaceholder]="{ year: 'Jahr', month: 'Monat', day: 'Tag', hour: '0', minute: '0', second: '0' }" [(ngModel)]="project.startDate"
    placeholder="" [bottomView]="'year'" name="startDate" (ngModelChange)="onValuesChange()"></kendo-datepicker>
  </div>
  <div class="col-sm-1">&nbsp;</div>
  <label for="name" class="col-sm-2 col-form-label">Ende (geplant)
    <ng-container *ngIf="allHelpToolTips && allHelpToolTips.projectEndDate">
      <img [src]="imgname" [title]="allHelpToolTips.projectEndDate" />
    </ng-container>
  </label>
  <div class="col-sm-3">
    <kendo-datepicker class="form-control" [format]="'MMMM, yyyy'" [formatPlaceholder]="{ year: 'Jahr', month: 'Monat', day: 'Tag', hour: '0', minute: '0', second: '0' }" [(ngModel)]="project.endDate"
    placeholder="" [bottomView]="'year'" name="endDate" (ngModelChange)="onValuesChange()"></kendo-datepicker>
  </div>
</div>

<div class="form-group row">
  <label for="shortDescription" class="col-sm-2 col-form-label">Kurzbeschreibung
  <ng-container *ngIf="allHelpToolTips && allHelpToolTips.projectShortDescription">
    <img [src]="imgname" [title]="allHelpToolTips.projectShortDescription" />
  </ng-container></label>
  <div class="col-sm-9">
    <textarea class="form-control" [(ngModel)]='project.shortDescription' name="shortDescription" [readOnly]="(project.stateId === 5)? true : null" (ngModelChange)="onValuesChange()"></textarea>
  </div>
</div>

<div class="form-group row">
  <label for="projectWorkspaceUrl" class="col-sm-2 col-form-label">Link auf den Projektworkspace
  <ng-container *ngIf="allHelpToolTips && allHelpToolTips.projectWorkspaceUrl">
    <img [src]="imgname" [title]="allHelpToolTips.projectWorkspaceUrl" />
  </ng-container></label>
  <div class="col-sm-7">
    <input class="form-control" [(ngModel)]='project.projectWorkspaceUrl' name="projectWorkspaceUrl" (ngModelChange)="onValuesChange()">
  </div>
  <div class="col-sm-2"><a [href]="urlToWorkspace" target="_new">Projektworkspace öffnen</a>
  </div>
</div>

<div class="form-group row">
  <label for="shortDescription" class="col-sm-2 col-form-label">Kommerzielle Partner / Lieferanten
    <ng-container *ngIf="allHelpToolTips && allHelpToolTips.projectCommercialPartner">
      <img [src]="imgname" [title]="allHelpToolTips.projectCommercialPartner" />
    </ng-container>
  </label>
  <div class="col-sm-9">
    <textarea class="form-control" [(ngModel)]='project.commercialPartner' name="commercialPartner" (ngModelChange)="onValuesChange()"></textarea>
  </div>
</div>

<div class="form-group row">
  <label for="motivation" class="col-sm-2 col-form-label">Projektmotivation
    <ng-container *ngIf="allHelpToolTips && allHelpToolTips.projectMotivation">
      <img [src]="imgname" [title]="allHelpToolTips.projectMotivation" />
    </ng-container>
  </label>
  <div class="col-sm-9">
    <textarea class="form-control" [(ngModel)]='project.motivation' name="projectMotivation" (ngModelChange)="onValuesChange()"></textarea>
  </div>
</div>

<div class="form-group row">
  <label for="utilization" class="col-sm-2 col-form-label">Geplante Verwertung der Erkenntnisse
    <ng-container *ngIf="allHelpToolTips && allHelpToolTips.projectUtilization">
      <img [src]="imgname" [title]="allHelpToolTips.projectUtilization" />
    </ng-container>
  </label>
  <div class="col-sm-9">
    <textarea class="form-control" [(ngModel)]='project.utilization' name="projectUtilization" (ngModelChange)="onValuesChange()"></textarea>
  </div>
</div>

<div class="form-group row">
  <label for="transfer" class="col-sm-2 col-form-label">Für Transfer vorgesehen
    <ng-container *ngIf="allHelpToolTips && allHelpToolTips.projectIsTransferable">
      <img [src]="imgname" [title]="allHelpToolTips.projectIsTransferable" />
    </ng-container>
  </label>
  <div class="col-sm-4">
    <fdb-yesno (valuesChange)="onValuesChange()" [uiController]='UIControlTyp.DropDownList' [(values)]="project.isTransferableId"></fdb-yesno>
  </div>
  <label for="name" class="col-sm-1 col-form-label">Projekt Typ
    <ng-container *ngIf="allHelpToolTips && allHelpToolTips.projectType">
      <img [src]="imgname" [title]="allHelpToolTips.projectType" />
    </ng-container>
  </label>
  <div class="col-sm-4">
    <fdb-project-type (valuesChange)="onValuesChange()" [uiController]='UIControlTyp.DropDownList' [(values)]="project.projectTypeId"></fdb-project-type>
  </div>
</div>

<div class="form-group row">
  <label for="timeWorkSchedule" class="col-sm-2 col-form-label">Zeit- und Arbeitsplan
    <ng-container *ngIf="allHelpToolTips && allHelpToolTips.projectTimeWorkSchedule">
      <img [src]="imgname" [title]="allHelpToolTips.projectTimeWorkSchedule" />
    </ng-container>
  </label>
  <div class="col-sm-9">
    <textarea class="form-control" [(ngModel)]='project.timeWorkSchedule' name="timeWorkSchedule" (ngModelChange)="onValuesChange()"></textarea>
  </div>
</div>

<div class="form-group row">
  <label for="tags" class="col-sm-2 col-form-label">Stichwörter
    <ng-container *ngIf="allHelpToolTips && allHelpToolTips.projectTags">
      <img [src]="imgname" [title]="allHelpToolTips.projectTags" />
    </ng-container>
  </label>
  <div class="col-sm-9">
    <fdb-tags (valuesChange)="onValuesChange()" [uiController]='UIControlTyp.MultiSelectList' [(values)]="project.tagIds"></fdb-tags>
  </div>
</div>

<div class="form-group row">
  <label for="academicPartner" class="col-sm-2 col-form-label">Akademische Partner
    <ng-container *ngIf="allHelpToolTips && allHelpToolTips.projectAcademicPartner">
      <img [src]="imgname" [title]="allHelpToolTips.projectAcademicPartner" />
    </ng-container>
  </label>
  <div class="col-sm-9">
    <fdb-academic-partners (valuesChange)="onValuesChange()" [uiController]='UIControlTyp.MultiSelectList' [(values)]="project.academicPartnerIds"></fdb-academic-partners>
  </div>
</div>

<div class="form-group row">
  <label for="publicFunding" class="col-sm-2 col-form-label">Öffentliche Förderung
    <ng-container *ngIf="allHelpToolTips && allHelpToolTips.projectPublicFunding">
      <img [src]="imgname" [title]="allHelpToolTips.projectPublicFunding" />
    </ng-container>
  </label>
  <div class="col-sm-9">
    <fdb-public-funding (valuesChange)="onValuesChange()" [uiController]='UIControlTyp.MultiSelectList' [(values)]="project.publicFundingIds"></fdb-public-funding>
  </div>
</div>

<div class="form-group row" *ngIf="project.stateId==1">
  <label for="timeWorkSchedule" class="col-sm-2 col-form-label">Genehmigung beantragen
    <ng-container *ngIf="allHelpToolTips && allHelpToolTips.projectTimeWorkSchedule">
      <img [src]="imgname" [title]="allHelpToolTips.projectTimeWorkSchedule" />
    </ng-container><br/>
    <b>Pflichtangaben bitte bearbeiten</b>
  </label>
  <div class="col-sm-7">
    <textarea class="form-control" [(ngModel)]='requestMsg' name="requestMsg"></textarea>
  </div>
  <div class="col-sm-2">
    <button (click)="onProjectRequest()" class="btn btn-light">beantragen</button>
  </div>
</div>
<div class="form-group row" *ngIf="project.stateId==2">
  <label for="timeWorkSchedule" class="col-sm-2 col-form-label">Genehmigung beantragen
    <ng-container *ngIf="allHelpToolTips && allHelpToolTips.projectTimeWorkSchedule">
      <img [src]="imgname" [title]="allHelpToolTips.projectTimeWorkSchedule" />
    </ng-container><br/>
    <b>Pflichtangaben bitte bearbeiten</b>
  </label>
  <div class="col-sm-7">
    <textarea class="form-control" [(ngModel)]='requestMsg' name="requestMsg"></textarea>
  </div>
  <div class="col-sm-2">
    <button (click)="onProjectRequest()" class="btn btn-light">Freigabe erneut anfragen</button>
  </div>
</div>
</form>
