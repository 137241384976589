import { AppService } from './../app.service';
import { IApiClient } from 'src/app/core/models/IApiClient';
import { Observable } from 'rxjs';
import { IUsersClient, UsersClient } from 'src/app/core/services/api-clients-generated.service';
import { map, catchError } from 'rxjs/operators';
import { ApiResult } from 'src/app/core/models/ApiResult';
import { AzurePerson } from 'src/app/core/models/AzurePerson';

export class AdvisorApiClient implements IApiClient {
  private userClient: IUsersClient;
  private roleName = 'Betreuer';

  constructor(private appService: AppService) { }

  //#region implemented Methode
  getAll(includeInactive: boolean): Observable<ApiResult> {
    return this.getUserClient()
      .get2(this.roleName)
      .pipe(
        map(items =>
          this.appService.mapToApiResult(items, AzurePerson.mapFromUserModels(items.payload))
        ),
        catchError(err => this.appService.setApiErrorHandling(err, 'AdvisorApiClient: getAll'))
      );
  }
  getFromId(id: number): Observable<ApiResult> {
    return this.appService.getErrorApiResult('Advisor => Method not implemented\.');
  }
  getFromProjectId(projectId: number): Observable<ApiResult> {
    return this.appService.getErrorApiResult('Advisor => Method not implemented\.');
  }
  createList(models: any[]): Observable<ApiResult> {
    return this.appService.getErrorApiResult('Advisor => Method not implemented\.');
  }
  removeList(ids: number[]): Observable<ApiResult> {
    return this.appService.getErrorApiResult('Advisor => Method not implemented\.');
  }
  updateList(models: any[]): Observable<ApiResult> {
    return this.appService.getErrorApiResult('Advisor => Method not implemented\.');
  }
  //#endregion

  private getUserClient(): IUsersClient {
    if (!this.userClient) {
      this.userClient = new UsersClient(this.appService.http, this.appService.apiUrl);
    }
    return this.userClient;
  }
}
