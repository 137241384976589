import { DDBase } from './DDBase';
import { TransferSuccessModel } from '../services/api-clients-generated.service';
/** Beschreibt den Erfolg eines Transfers */
export class ProjectTransferSuccess extends DDBase {

  static MapFromTransferSuccessModels(items: TransferSuccessModel[]): ProjectTransferSuccess[]{
    return items.map(item => this.MapFromTransferSuccessModel(item));
  }
  static MapFromTransferSuccessModel(item: TransferSuccessModel): any {
    const newItem = new ProjectTransferSuccess();
    newItem.id = item.id;
    newItem.name = item.name;
    newItem.isActive = item.isActive;
    return newItem;
  }
}
