import { ApprovalComponent } from './approval/approval.component';
import { RoutingPath } from './../app-routing.module';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SearchProjectComponent } from './search/search-project.component';
import { ProjectDetailsComponent } from './details/project-details.component';
import { NewProjectComponent } from './new/new-project.component';
import { MsalGuard } from '@azure/msal-angular';
import { AdloginService } from '../service/adlogin.service';
import { ProjectService } from './project.service';
import { PendingChangesGuard } from '../core/middleware/pending-changes.guard';


const projectsRoutes: Routes = [
  {
    path: RoutingPath.projectSearch, component: SearchProjectComponent, canActivate: [MsalGuard],
    resolve: { currentUser: AdloginService }
  },
  {
    path: RoutingPath.project + '/:id', component: ProjectDetailsComponent, canActivate: [MsalGuard],
    resolve: { currentUser: AdloginService, project : ProjectService }, canDeactivate: [PendingChangesGuard]
  },
  {
    path: RoutingPath.projectDescription + '/:id', component: ProjectDetailsComponent, canActivate: [MsalGuard],
    resolve: { currentUser: AdloginService, project : ProjectService }, canDeactivate: [PendingChangesGuard]
  },
  {
    path: RoutingPath.projectSetup + '/:id', component: ProjectDetailsComponent, canActivate: [MsalGuard],
    resolve: { currentUser: AdloginService, project : ProjectService }, canDeactivate: [PendingChangesGuard]
  },
  {
    path: RoutingPath.projectLogbook + '/:id', component: ProjectDetailsComponent, canActivate: [MsalGuard],
    resolve: { currentUser: AdloginService, project : ProjectService }, canDeactivate: [PendingChangesGuard]
  },
  {
    path: RoutingPath.projectCosts + '/:id', component: ProjectDetailsComponent, canActivate: [MsalGuard],
    resolve: { currentUser: AdloginService, project : ProjectService }, canDeactivate: [PendingChangesGuard]
  },
  {
    path: RoutingPath.projectTransfer + '/:id', component: ProjectDetailsComponent, canActivate: [MsalGuard],
    resolve: { currentUser: AdloginService, project : ProjectService }, canDeactivate: [PendingChangesGuard]
  },
  {
    path: RoutingPath.projectDocuments, component: ProjectDetailsComponent, canActivate: [MsalGuard],
    resolve: { currentUser: AdloginService, project : ProjectService }, canDeactivate: [PendingChangesGuard]
  },
  {
    path: RoutingPath.projectNew, component: NewProjectComponent, canActivate: [MsalGuard],
    resolve: { currentUser: AdloginService }
  },
  {
    path: RoutingPath.projectNewMasterdata, component: NewProjectComponent, canActivate: [MsalGuard],
    resolve: { currentUser: AdloginService }
  },
  {
    path: RoutingPath.projectNewDescription, component: NewProjectComponent, canActivate: [MsalGuard],
    resolve: { currentUser: AdloginService }
  },
  {
    path: RoutingPath.projectApproval + '/:id', component: ApprovalComponent, canActivate: [MsalGuard],
    resolve: { currentUser: AdloginService }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(projectsRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class ProjectRoutingModule { }
