import { DDBase } from './DDBase';
import { PublicFundingModel } from '../services/api-clients-generated.service';

export class PublicFunding extends DDBase {
  static mapFromPublicFundingModel(items: PublicFundingModel[]): PublicFunding[] {
    return items.map(item => {
      const newItem = new PublicFunding();
      newItem.id = item.id;
      newItem.name = item.name;
      newItem.isActive = item.isActive;
      return newItem;
    });
  }
}
