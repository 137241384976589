import { DDBase } from './DDBase';
import { TagModel } from '../services/api-clients-generated.service';
export class Tag extends DDBase {
  static mapToTagsModel(items: (string | number)[]) {
    return items.map(item => new TagModel({ id: 0, name: item.toString() }));
  }
  static mapToTags(items: TagModel[]): Tag[] {
    return items.map(item => new Tag().setInitValue(item.name, item.name));
  }
  setInitValue(id: string, name: string) {
    this.id = id;
    this.name = name;
    return this;
  }
  getArrayFromTags(ids: string[]): Tag[] {
    return ids.map(id => new Tag().setInitValue(id, id));
  }
}
