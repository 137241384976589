import { DDBase } from './DDBase';
import { MilestoneModel } from '../services/api-clients-generated.service';
import * as moment from 'moment';
export class ProjectMilestone extends DDBase {
  plannedDate: Date;
  actualDate: Date;
  projectId: number;
  progressInPercent: number;

  static MapFromMilestoneMilestoneModels(items: MilestoneModel[]): ProjectMilestone[] {
    return items.map(item => this.MapFromMilestoneMilestoneModel(item));
  }

  static MapFromMilestoneMilestoneModel(item: MilestoneModel): ProjectMilestone {
    const newItem = new ProjectMilestone();
    newItem.id = item.id;
    newItem.name = item.name;
    newItem.plannedDate = item.plannedDate ? item.plannedDate.toDate() : null;
    newItem.actualDate = item.actualDate ? item.actualDate.toDate() : null;
    newItem.projectId = item.projectId;
    newItem.progressInPercent = item.progressInPercent ? item.progressInPercent : 0;
    return newItem;
  }
  static MapToMilestoneModels(items: ProjectMilestone[]): MilestoneModel[] {
    return items.map(item => this.MapToMilestoneModel(item));
  }

  static MapToMilestoneModel(item: ProjectMilestone): MilestoneModel {
    const newItem = new MilestoneModel();
    newItem.id = Number(item.id);
    newItem.name = item.name;
    newItem.plannedDate = item.plannedDate ? moment(item.plannedDate) : null;
    newItem.actualDate = item.actualDate ? moment(item.actualDate) : null;
    newItem.projectId = item.projectId;
    newItem.progressInPercent = item.progressInPercent ? item.progressInPercent : 0;
    return newItem;
  }
}
