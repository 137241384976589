import { Injectable } from '@angular/core';
import { AdminService } from '../service/admin.service';
import { ListBehaviorService } from '../../service/list-behavior.service';
import { EnBWUnitApiClient } from 'src/app/service/apiClients/EnBWUnitApiClient';

@Injectable({
  providedIn: 'root'
})
export class ListEnbwUnitService extends ListBehaviorService {

  constructor(adminService: AdminService) {
    super(new EnBWUnitApiClient(adminService), adminService);
  }
}
