import { AppService } from './../service/app.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Project } from '../core/models/Project.Model';
import { ProjectApiClient } from '../service/apiClients/ProjectApiClient';
import { ApiResult } from '../core/models/ApiResult';
import { ProjectCostApiClient } from '../service/apiClients/ProjectCostItemApiClient';
import { ProjectCostAccount } from '../core/models/ProjectCostAccount';
import { ProjectCostItemAmount } from '../core/models/ProjectCostItemAmount';
import { ProjectTeamMemberApiClient } from '../service/apiClients/ProjectTeamMemberApiClient';
import { ProjectFilter } from '../core/models/ProjectFilter';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

@Injectable()
export class ProjectService extends AppService implements Resolve<Observable<ApiResult>> {

  resolve(route: ActivatedRouteSnapshot) {
    return this.getProjectFromId(route.paramMap.get('id') as unknown as number);
  }

  getAllProjects(): Observable<ApiResult> {
    return this.getAll(new ProjectApiClient(this));
  }
  getFilteredProjects(filter: ProjectFilter): Observable<ApiResult> {
    return new ProjectApiClient(this).getFiltered(filter);
  }
  getProjectFromId(id: number): Observable<ApiResult> {
    return this.getFromId(new ProjectApiClient(this), id);
  }

  addProject(project: Project): Observable<ApiResult> {
    return new ProjectApiClient(this).create(project);
  }
  updateProject(project: Project): Observable<ApiResult> {
    return new ProjectApiClient(this).update(project);
  }
  updateState(project: Project): Observable<ApiResult> {
    return new ProjectApiClient(this).updateState(project);
  }
  /** Assigns users to a project. The user ids need to be provided as a comma separated string and
   * must be of type int. The ids are parsed and all users in the list are assigned to the project.
   * IMPORTANT: USERS NOT IN THE LIST ARE REMOVED FROM THE PROJECT, therefore this method
   * can be used for both actions.
   */
  assignUserToProject(projectId: number, userIds: string): Observable<ApiResult> {
    return new ProjectApiClient(this).assignUserToProject(projectId, userIds);
  }
  getProjectTeamMembers(projectId: number): Observable<ApiResult> {
    return new ProjectTeamMemberApiClient(this).getFromProjectId(projectId);
  }
  updateProjectHeader(project: Project): Observable<ApiResult> {
    return new ProjectApiClient(this).updateProjectHeader(project);
  }
  getCostItems(projectId: number) {
    return new ProjectCostApiClient(this).getFromProjectId(projectId);
  }
  createEditValues(projectId: number) {
    return new ProjectCostApiClient(this).createEditValues(projectId);
  }
  updateCostAccount(models: ProjectCostAccount[]) {
    return new ProjectCostApiClient(this).updateCostAccount(models);
  }
  updateCostPlanning(projectId: number, costId: number, models: ProjectCostItemAmount[]) {
    return new ProjectCostApiClient(this).updateCostPlanning(projectId, costId, models);
  }
  requestProject(projectId: number, stateId: number, requestMsg: string) {

    return new ProjectApiClient(this).requestProject(projectId, stateId, requestMsg);
  }
  approveProject(projectId: number, stateId: number, approvalMsg: string) {
    return new ProjectApiClient(this).approveProject(projectId, stateId, approvalMsg);
  }
  rejectProject(projectId: number, stateId: number, rejectionMsg: string) {
    return new ProjectApiClient(this).rejectProject(projectId, stateId, rejectionMsg);
  }
  sendPurchaseDepartmentMail(projectId: number) {
    return new ProjectApiClient(this).sendPurchaseDepartmentMail(projectId);
  }
  deleteProject(projectId: number) {
    return new ProjectApiClient(this).deleteProject(projectId);
  }
}
