<div class="container">
  <h1>Projektgenehmigung</h1>
  <p>Die Budgetfreigabe für das folgende Projekt wurde beantragt:
    <a *ngIf="project" href='/project/{{currentId}}' target="_blank">{{project.name}}</a>.
  </p>
  <p>
    Bitte erteilen Sie ihre Freigabe über den entsprechenden Button oder lehnen Sie es ab. Details finden Sie auf der 
    <a href='/project/{{currentId}}' target="_blank">Projektseite</a>. Bitte kommentieren Sie ihre Entscheidung entsprechend 
    im vorgesehenen Kommentarfeld. Der Kommentar wird dem Projektleiter per E-Mail mitgeteilt und im Fall
    einer Projektfreigabe auch als Infoeintrag gespeichert.
  </p>

  <div class="form-group row">
    <label for="comment" class="col-sm-2 col-form-label">Kommentarfeld</label>
    <div class="col-sm-9">
      <textarea class="form-control" [(ngModel)]='comment' name="comment"></textarea>
    </div>
  </div>
  <div class="row align-items-start">
    <div class="col">
      <button [class]="'btn btn-dark'"
        (click)="approval()">Projektantrag zustimmen</button>
    </div>
    <div class="col">
    </div>
    <div class="col">
      <button [class]="'btn btn-dark'" (click)="rejection()">Projektantrag ablehnen </button>
    </div>
  </div>
</div>
