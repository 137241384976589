import { AppService } from 'src/app/service/app.service';
import { CanDeactivate } from '@angular/router';
import { Injectable, Component } from '@angular/core';
import { Observable } from 'rxjs';

export interface CanDeactivateComponent extends Component {
  hasChanges(): boolean;
}
@Injectable()
export class PendingChangesGuard implements CanDeactivate<CanDeactivateComponent> {
  constructor(private appService: AppService) { }
  canDeactivate(component: CanDeactivateComponent): boolean | Observable<boolean> {
    // if there are no pending changes, just allow deactivation; else confirm first
    if (component.hasChanges()) {
      this.appService.showNotification('Einen Moment es werden noch Änderungen gesichert', 'warning');
    }
    return true;
  }
}
