import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';

@Component({
  selector: 'fdb-dialog-info-purchase',
  templateUrl: './dialog-info-purchase.component.html',
  styleUrls: ['./dialog-info-purchase.component.scss']
})

export class DialogInfoPurchaseComponent implements OnInit {
  @Input() opened = false;
  @Output() closed = new EventEmitter();
  @Output() sendMail = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  public onSetYes() {
    this.sendMail.emit();
  }

  public close() {
    this.closed.emit();
  }

}
