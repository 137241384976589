import { UnknowUserComponent } from './unknow-user/unknow-user.component';
import { RoutingPath } from '../app-routing.module';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { AdloginService } from '../service/adlogin.service';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';


const appcommonRoutes: Routes = [
  {
    path: RoutingPath.unknowUser, component: UnknowUserComponent
  },
  {
    path: RoutingPath.unauthorized, component: UnauthorizedComponent, canActivate: [MsalGuard],
    resolve: { currentUser: AdloginService }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(appcommonRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class AppcommonRoutingModule { }
