<div class="row" style="margin-top: 50px;">
  <div class="col-xs-9 col-md-9">
    <h4>Projekt Risiken</h4>
  </div>

</div>
<div class="row" style="margin-top: 20px">
  <div class="col-sm-12">
    <kendo-grid #grid [data]="view | async" [height]="300" [pageSize]="gridState.take" [skip]="gridState.skip"
      [sort]="gridState.sort" [pageable]="true" [sortable]="true"
      (dataStateChange)="onStateChange($event)"
      (edit)="editHandler($event)"
      (cancel)="cancelHandler($event)"
      (save)="saveHandler($event)"
      (add)="addHandler($event)"
      (remove)="removeHandler($event)"
      [navigable]="true">
      <ng-template kendoGridToolbarTemplate>
        <button kendoGridAddCommand>
          <span class="fa fa-pencil"></span><span class="k-icon k-i-plus"></span></button>
      </ng-template>
      <kendo-grid-column *ngIf="false" field="projectId" title="Proj.Id" [width]="100">
        <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
          {{ dataItem.projectId }}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="name" title="Risiko"></kendo-grid-column>
      <kendo-grid-column field="impact" title="Auswirkung"></kendo-grid-column>
      <kendo-grid-column field="riskRatingId" title="Bewertung" [width]="200">
        <ng-template kendoGridCellTemplate let-dataItem="dataItem">
          <fdb-project-risk-rating [values]="dataItem.riskRatingId" [uiController]="UIControlTyp.GridSingleValueField">
          </fdb-project-risk-rating>
        </ng-template>
        <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup" let-isNew="isNew">
          <fdb-project-risk-rating [(values)]="dataItem.riskRatingId" [uiController]="UIControlTyp.DropDownList">
          </fdb-project-risk-rating>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-command-column title="Aktionen" [width]="120">
        <ng-template kendoGridCellTemplate let-isNew="isNew" let-formGroup="formGroup">
          <button kendoGridEditCommand>
            <span class="k-icon k-i-pencil"></span>
          </button>
          <button kendoGridRemoveCommand>
            <span class="fa fa-pencil"></span><span class="k-icon k-i-delete"></span>
          </button>
          <button kendoGridSaveCommand [disabled]="formGroup?.invalid">
            <ng-container *ngIf='isNew'>
              <span class="fa fa-pencil"></span><span class="k-icon k-i-save"></span>
            </ng-container>
            <ng-container *ngIf='!isNew'>
              <span class="fa fa-pencil"></span><span class="k-icon k-i-save"></span>
            </ng-container>
          </button>
          <button kendoGridCancelCommand>
            <span class="fa fa-pencil"></span><span class="k-icon k-i-close"></span>
          </button>
        </ng-template>
      </kendo-grid-command-column>
    </kendo-grid>
  </div>
</div>
