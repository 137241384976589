import { Injectable } from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';
import { Observable } from 'rxjs';
import { ApiResult } from 'src/app/core/models/ApiResult';
import { HelpToolTip } from 'src/app/core/models/HelpToolTip';
import { AllHelpToolTips } from 'src/app/core/models/AllHelpToolTips';

@Injectable()
export class HelpToolTipService {

  public selector: string;
  public payload: any;

  // tslint:disable-next-line: ban-types
  constructor(private shareService: SharedService) {
  }

  public getHelpToolTips(): Observable<ApiResult> {
    return this.shareService.getHelpToolTips();
  }

  public getAllHelpToolTips(helpToolTips: HelpToolTip[]): AllHelpToolTips {
    let result = new AllHelpToolTips();
    result.projectName = this.setHelpToolTipInternal(helpToolTips, 'fdb-project-details', 'projectName');
    result.projectAdvisorName = this.setHelpToolTipInternal(helpToolTips, 'fdb-project-details', 'projectAdvisorName');
    result.projectManagerName = this.setHelpToolTipInternal(helpToolTips, 'fdb-project-details', 'projectManagerName');
    result.enbwUnit = this.setHelpToolTipInternal(helpToolTips, 'fdb-project-details', 'enbwUnit');
    result.projectOrgaUnit = this.setHelpToolTipInternal(helpToolTips, 'fdb-project-details', 'projectOrgaUnit');
    result.projectManagerEmail = this.setHelpToolTipInternal(helpToolTips, 'fdb-project-details', 'projectManagerEmail');
    result.projectManagerFaxNumber = this.setHelpToolTipInternal(helpToolTips, 'fdb-project-details', 'projectManagerFaxNumber');
    result.projectManagerPhoneNumber = this.setHelpToolTipInternal(helpToolTips, 'fdb-project-details', 'projectManagerPhoneNumber');
    result.projectNumber = this.setHelpToolTipInternal(helpToolTips, 'fdb-project-details', 'projectNumber');

    result.projectStartDate = this.setHelpToolTipInternal(helpToolTips, 'fdb-project-description', 'projectStartDate');
    result.projectEndDate = this.setHelpToolTipInternal(helpToolTips, 'fdb-project-description', 'projectEndDate');
    result.projectShortDescription = this.setHelpToolTipInternal(helpToolTips, 'fdb-project-description', 'projectShortDescription');
    result.projectCommercialPartner = this.setHelpToolTipInternal(helpToolTips, 'fdb-project-description', 'projectCommercialPartner');
    result.projectMotivation = this.setHelpToolTipInternal(helpToolTips, 'fdb-project-description', 'projectMotivation');
    result.projectUtilization = this.setHelpToolTipInternal(helpToolTips, 'fdb-project-description', 'projectUtilization');
    result.projectIsTransferable = this.setHelpToolTipInternal(helpToolTips, 'fdb-project-description', 'projectIsTransferable');
    result.projectType = this.setHelpToolTipInternal(helpToolTips, 'fdb-project-description', 'projectType');
    result.projectTimeWorkSchedule = this.setHelpToolTipInternal(helpToolTips, 'fdb-project-description', 'projectTimeWorkSchedule');
    result.projectTags = this.setHelpToolTipInternal(helpToolTips, 'fdb-project-description', 'projectTags');
    result.projectAcademicPartner = this.setHelpToolTipInternal(helpToolTips, 'fdb-project-description', 'projectAcademicPartner');
    result.projectPublicFunding = this.setHelpToolTipInternal(helpToolTips, 'fdb-project-description', 'projectPublicFunding');

    result.projectTransferResponsible = this.setHelpToolTipInternal(helpToolTips, 'fdb-project-transfer', 'projectTransferResponsible');
    result.projectTransferUnit = this.setHelpToolTipInternal(helpToolTips, 'fdb-project-transfer', 'projectTransferUnit');
    result.projectTransferManagerPhone = this.setHelpToolTipInternal(helpToolTips, 'fdb-project-transfer', 'projectTransferManagerPhone');
    result.projectTransferManagerFax = this.setHelpToolTipInternal(helpToolTips, 'fdb-project-transfer', 'projectTransferManagerFax');
    result.projectTransferManagerEmail = this.setHelpToolTipInternal(helpToolTips, 'fdb-project-transfer', 'projectTransferManagerEmail');
    result.projectTransferResult = this.setHelpToolTipInternal(helpToolTips, 'fdb-project-transfer', 'projectTransferResult');
    result.projectTransferSuccess = this.setHelpToolTipInternal(helpToolTips, 'fdb-project-transfer', 'projectTransferSuccess');

    return result;
  }

  public setHelpToolTip(varName: string): string {
    return this.setHelpToolTipInternal(this.payload, this.selector, varName);
  }

  private setHelpToolTipInternal(helpToolTips: any, angularComponent: string, varName: string): string {
    const htt = helpToolTips.find(htt => htt.fieldId === varName && htt.angularComponent === angularComponent);
    let result: string = null;
    if (htt) {
      result = htt.toolTip;
    }
    return result;
  }
}
