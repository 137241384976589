import { Component, OnInit } from '@angular/core';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';

class ProjectDocument {
  type: string;
  name: string;
  updatedBy: string;
  date: Date;
}

@Component({
  selector: 'fdb-project-documents',
  templateUrl: './project-documents.component.html',
  styleUrls: ['./project-documents.component.scss']
})
export class ProjectDocumentsComponent implements OnInit {

  public gridView: GridDataResult;
  public pageSize: number;
  public skip: number;
  private items: ProjectDocument[];

  constructor() {
    this.pageSize = 10;
    this.skip = 0;
    this.items = [
      {
        type: 'NKV',
        name: '170720_NKV_Test.xlsx',
        date: new Date(2017, 6, 25, 15),
        updatedBy: 'Max Mustermann'
      }
    ];
  }

  ngOnInit() {
    this.loadItems();
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.loadItems();
  }

  private loadItems(): void {
    this.gridView = {
      data: this.items.slice(this.skip, this.skip + this.pageSize),
      total: this.items.length
    };
  }

}
