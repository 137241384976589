import { AppService } from './../app.service';
import { IApiClient } from 'src/app/core/models/IApiClient';
import { Observable } from 'rxjs';
import { ApiResult } from 'src/app/core/models/ApiResult';
import { IRisksClient, RisksClient } from 'src/app/core/services/api-clients-generated.service';
import { map } from 'rxjs/operators';
import { ProjectRiskRating } from 'src/app/core/models/ProjectRiskRating';
import { catchError } from 'rxjs/operators';

export class ProjectRiskRatingApiClient implements IApiClient {
  private projectriskClient: IRisksClient;

  constructor(private appService: AppService) { }

  getAll(includeInactive: boolean): Observable<ApiResult> {
    return this.GetProjectRiskClient()
      .getRatings()
      .pipe(
        map(items =>
          this.appService.mapToApiResult(items, ProjectRiskRating.MapFromRiskRatingModels(items.payload))
        ),
        catchError(err => this.appService.setApiErrorHandling(err, 'ProjectRiskRatingApiClient: getAll'))
      );
  }
  getFromId(id: number): Observable<ApiResult> {
    return this.appService.getErrorApiResult('ProjectRiskRating => Method getFromId not implemented\.');
  }
  getFromProjectId(projectId: number): Observable<ApiResult> {
    return this.appService.getErrorApiResult('ProjectRiskRating => Method getFromProjectId not implemented\.');
  }
  createList(models: any[]): Observable<ApiResult> {
    return this.appService.getErrorApiResult('ProjectRiskRating => Method createList not implemented\.');
  }
  removeList(ids: number[]): Observable<ApiResult> {
    return this.appService.getErrorApiResult('ProjectRiskRating => Method removeList not implemented\.');
  }
  updateList(models: any[]): Observable<ApiResult> {
    return this.appService.getErrorApiResult('ProjectRiskRating => Method updateList not implemented\.');
  }

  private GetProjectRiskClient(): IRisksClient {
    if (!this.projectriskClient) {
      this.projectriskClient = new RisksClient(this.appService.http, this.appService.apiUrl);
    }
    return this.projectriskClient;
  }
}
