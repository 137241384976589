import { SharedService } from '../shared.service';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UIControlTyp } from 'src/app/core/models/UIControlTyp';
import { EnBWUnit } from 'src/app/core/models/EnBWUnit';

@Component({
  selector: 'fdb-enbwunit',
  templateUrl: './enbwunit.component.html',
  styleUrls: ['./enbwunit.component.scss']
})
export class EnbwUnitComponent {
  public UIControlTyp = UIControlTyp;
  /** Hiermit können Sie den UIControlTyp entscheiden => Default DropDownList */
  @Input() uiController: UIControlTyp = UIControlTyp.DropDownList;
  // tslint:disable-next-line: no-input-rename
  @Input('values')
  get values() {
    const val = this.uiController === UIControlTyp.MultiSelectList ?
      this.EnBWUnits.map(unit => Number(unit.id)) :
      Number(this.EnBWUnit.id);
    return val;
  }
  set values(val: string | number | number[]) { // TODO: testen, ob string und number beides klappt.
    this.uiController === UIControlTyp.MultiSelectList ?
      this.EnBWUnits = (val as number[]).map(id => this.getObjectFromList(id)) :
      this.EnBWUnit = this.getObjectFromList(val as number);
  }
  @Output() valuesChange = new EventEmitter();

  /** Enthält die ausgewählten Werte bei UIControlTyp.MultiSelectList */
  public EnBWUnits: EnBWUnit[] = [];
  /** Enthält die ausgewählten Wert bei UIControlTyp.DropDownList */
  public EnBWUnit: EnBWUnit;
  /** Enthält die Werte die angezeigt werden dürfen */
  public EnBWUnitList: EnBWUnit[] = [];
  /** Enthält die Originalliste mit allen Werten */
  private OriginalValues: EnBWUnit[] = [];

  constructor(private modulService: SharedService) { this.initenbwunits(); }

  initenbwunits() {
    this.modulService.getEnBWUnits(true)
      .subscribe(apiResult => {
        if (!apiResult.isSuccessful) {
          this.modulService.showNotification(apiResult.message, 'error');
        }
        if (apiResult.payload) {
          this.OriginalValues = apiResult.payload;
          this.EnBWUnitList = this.OriginalValues.filter(s => s.isActive === true);
          if (this.uiController === UIControlTyp.DropDownList) {
            this.EnBWUnitList.unshift(new EnBWUnit().getEmptyModel());
          }
          if (this.uiController === UIControlTyp.MultiSelectList) {
            this.getRefreshSelectedValue();
          }
        }
      },
        err => this.modulService.showNotification(err, 'error'));
  }

  filterenbwunit(value: string) {
    this.EnBWUnitList = this.OriginalValues
      .filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1 && s.isActive === true);
  }

  valueChanged(): void {
    this.valuesChange.emit(this.values);
  }

  getObjectFromList(id: number): EnBWUnit {
    if (id === 0) { return new EnBWUnit().getEmptyModel(); }
    const find = this.OriginalValues.find(unit => unit.id === id);
    return find ? find : new EnBWUnit().setId(id);
  }
  getName() {
    const find = this.getObjectFromList(Number(this.EnBWUnit.id));
    return find ? find.name : '';
  }
  getRefreshSelectedValue() {
    this.EnBWUnits = this.EnBWUnits.map(x => this.OriginalValues.find(y => y.id === x.id));
  }
}
