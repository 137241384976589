import { Injectable } from '@angular/core';
import { ProjectService } from 'src/app/project/project.service';
import { ProjectRiskApiClient } from 'src/app/service/apiClients/ProjectRiskApiClient';
import { ListBehaviorService } from 'src/app/service/list-behavior.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectSetupRiskService extends ListBehaviorService {

  constructor(modulService: ProjectService) {
    super(new ProjectRiskApiClient(modulService), modulService);
  }
}
