import { AzurePersonRoles } from './AzurePersonRoles';
import { UserModel, RoleModel } from '../services/api-clients-generated.service';
import { DDBase } from './DDBase';

export class AzurePerson extends DDBase {
  id: number;
  image: string;
  phoneNumber: string;
  email: string;
  faxNumber: string;
  roles?: AzurePersonRoles[];

  static mapFromUserModel(userModel: UserModel) {
    const newItem = new AzurePerson();
    newItem.id = userModel.id;
    if (userModel.lastName && userModel.firstName) {
      newItem.name = userModel.lastName + ' ' + userModel.firstName;
    } else if (userModel.lastName) {
      newItem.name = userModel.lastName
    } else {
      newItem.name = userModel.email;
    }
    newItem.image = userModel.imageUrl;
    newItem.email = userModel.email;
    newItem.phoneNumber = userModel.phone;
    newItem.email = userModel.email;
    newItem.faxNumber = userModel.fax;
    newItem.roles = AzurePerson.mapFromRoleModels(userModel.roles);
    return newItem;
  }
  static mapFromUserModels(items: UserModel[]): AzurePerson[] {
    return items.map(item => AzurePerson.mapFromUserModel(item));
  }
  static mapFromRoleModels(roles: RoleModel[]): AzurePersonRoles[] {
    return roles.map(role => AzurePerson.mapFromRoleModel(role));
  }
  static mapFromRoleModel(role: RoleModel): AzurePersonRoles {
    return role.id;
  }

  isUserAdmin() {
    return this.hasUserRole(AzurePersonRoles.Administrator);
  }

  isUserAdvisor() {
    return this.hasUserRole(AzurePersonRoles.Advisor);
  }

  isUserApprovers() {
    return this.hasUserRole(AzurePersonRoles.Approvers);
  }

  isUserPurchasing() {
    return this.hasUserRole(AzurePersonRoles.Purchasing);
  }

  private hasUserRole(findRole: AzurePersonRoles): boolean {
    const found = this.roles.find(role => role === findRole);
    return found ? true : false;
  }
}

