export class AllHelpToolTips {

    public projectName: string;
    public projectAdvisorName: string;
    public projectManagerName: string
    public projectManagerPhoneNumber: string
    public projectManagerFaxNumber: string
    public enbwUnit: string;
    public projectOrgaUnit: string;
    public projectManagerEmail: string
    public projectNumber: string
  
    public projectStartDate: string;
    public projectEndDate: string;
    public projectShortDescription: string;
    public projectCommercialPartner: string;
    public projectMotivation: string;
    public projectUtilization: string;
    public projectIsTransferable: string;
    public projectType: string;
    public projectTimeWorkSchedule: string;
    public projectTags: string;
    public projectAcademicPartner: string;
    public projectPublicFunding: string;

    public projectTransferResponsible: string;
    public projectTransferUnit: string
    public projectTransferManagerPhone: string;
    public projectTransferManagerFax: string;
    public projectTransferManagerEmail: string;
    public projectTransferResult: string;
    public projectTransferSuccess: string;
    public projectWorkspaceUrl: string;
}
