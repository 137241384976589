import { CostTypeArea, InternalCostType } from './../services/api-clients-generated.service';
import { Base } from './Base';
import { CostTypeSource, CostTypeModel } from '../services/api-clients-generated.service';

export class ProjectCostType extends Base {
  name: string;
  source: CostTypeSource;
  area: CostTypeArea;
  type: InternalCostType;
  static MapFromCostTypeModel(item: CostTypeModel): ProjectCostType {
    const newItem = new ProjectCostType();
    newItem.id = item.id;
    newItem.name = item.name;
    newItem.source = item.sourceId;
    newItem.area = item.areaId;
    newItem.type = item.type;
    return newItem;
  }
}
