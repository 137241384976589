<div class="row" style="margin-top: 50px;">
  <div class="col-xs-5 col-md-5">
   <input type="file" class="form-control">
  </div>
  <div class="col-xs-4 col-md-4">
    <select class="col-xs-12 form-control">
      <option value="">Typ</option>
    </select>
   </div>
  <div class="col-xs-3 col-md-3">
    <button class="btn btn-light float-right" style="margin-top: 0">Neues Document hochladen</button>
  </div>
</div>
<div class="row" style="margin-top: 20px">
  <div class="col-sm-12">
    <kendo-grid
      [data]="gridView"
      [pageSize]="pageSize"
      [skip]="skip"
      [pageable]="true"
      [height]="300"
      (pageChange)="pageChange($event)"
    >
    <kendo-grid-column field="type" title="Typ" [width]="150"></kendo-grid-column>
    <kendo-grid-column field="name" title="Name"></kendo-grid-column>
      <kendo-grid-column field="updatedBy" title="Geändert von" [width]="250"></kendo-grid-column>
      <kendo-grid-column
        field="date"
        title="am"
        format="{0: dd.MM.yyyy HH:mm}"
        [width]="250"
      ></kendo-grid-column>
      <kendo-grid-column title="Löschen" [width]="150">
        <ng-template kendoGridCellTemplate>
          <span class="k-icon k-i-trash"></span>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </div>
</div>
