export enum AzurePersonRoles {
  Any = 0,
  ProjectLeader = 1, // Fachl. Rolle
  Advisor = 2, // Techn. Rolle => Betreuer
  ProjectMember = 3, // Fachl. Rolle Teammitglied
  TransferManager = 4, // Fachl. Rolle Transfermanager
  Administrator = 5, // Techn. Rolle => Administrator
  Approvers = 6, // Techn. Rolle => Freigeber
  Purchasing = 7 // Techn. Rolle => Einkauf
}
