import { ProjectTransferSuccess } from './../../core/models/ProjectTransferSuccess';
import { TransferSuccessesClient, ITransferSuccessesClient } from './../../core/services/api-clients-generated.service';
import { AppService } from './../app.service';
import { IApiClient } from 'src/app/core/models/IApiClient';
import { Observable } from 'rxjs';
import { ApiResult } from 'src/app/core/models/ApiResult';
import { map, catchError } from 'rxjs/operators';


export class ProjectTransferSuccesApiClient implements IApiClient {
  private transferSuccessesClient: ITransferSuccessesClient;
  constructor(private appService: AppService) { }

  getAll(includeInactive: boolean): Observable<ApiResult> {
    return this.getTransferSuccessesClient()
      .get(includeInactive)
      .pipe(
        map(items =>
          this.appService.mapToApiResult(items, ProjectTransferSuccess.MapFromTransferSuccessModels(items.payload))
        ),
        catchError(err => this.appService.setApiErrorHandling(err, 'ProjectTransferSuccesApiClient: getAll'))
      );
  }
  getFromId(id: number): Observable<ApiResult> {
    return this.appService.getErrorApiResult('ProjectTransferSucces => Method not implemented\.');
  }
  getFromProjectId(projectId: number): Observable<ApiResult> {
    return this.appService.getErrorApiResult('ProjectTransferSucces => Method not implemented\.');
  }
  create(model: any): Observable<ApiResult> {
    return this.appService.getErrorApiResult('ProjectTransferSucces => Method not implemented\.');
  }
  createList(models: any[]): Observable<ApiResult> {
    return this.appService.getErrorApiResult('ProjectTransferSucces => Method not implemented\.');
  }
  remove(id: number): Observable<ApiResult> {
    return this.appService.getErrorApiResult('ProjectTransferSucces => Method not implemented\.');
  }
  removeList(ids: number[]): Observable<ApiResult> {
    return this.appService.getErrorApiResult('ProjectTransferSucces => Method not implemented\.');
  }
  update(model: any): Observable<ApiResult> {
    return this.appService.getErrorApiResult('ProjectTransferSucces => Method not implemented\.');
  }
  updateList(models: any[]): Observable<ApiResult> {
    return this.appService.getErrorApiResult('ProjectTransferSucces => Method not implemented\.');
  }

  private getTransferSuccessesClient(): ITransferSuccessesClient {
    if (!this.transferSuccessesClient) {
      this.transferSuccessesClient = new TransferSuccessesClient(this.appService.http, this.appService.apiUrl);
    }
    return this.transferSuccessesClient;
  }
}
