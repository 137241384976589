<div class="row" style="margin-top: 50px;">
  <div class="col-xs-9 col-md-9">
    <h4>Logbuch Einträge</h4>
  </div>
</div>
<div kendoDialogContainer></div>
<div class="row" style="margin-top: 20px">
  <div class="col-sm-12">
    <kendo-grid #grid [data]="view | async" [height]="500" [pageSize]="gridState.take" [skip]="gridState.skip"
      [sort]="gridState.sort" [pageable]="true" [sortable]="true" (dataStateChange)="onStateChange($event)"
      (edit)="editHandler($event)" (cancel)="cancelHandler($event)" (save)="saveHandler($event)" (remove)="removeHandler($event)"
      (add)="addHandler($event)" [navigable]="true">
      <ng-template kendoGridToolbarTemplate>
        <button kendoGridAddCommand>
          <span class="fa fa-pencil"></span><span class="k-icon k-i-plus"></span>
        </button>
      </ng-template>
      <kendo-grid-column *ngIf="false" field="projectId" title="Proj.Id" [width]="1">
        <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
          {{ dataItem.projectId }}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="date" title="Datum" format="{0: dd-MM-yyyy}" [width]="150">
        <ng-template kendoGridEditTemplate let-column="column" let-formGroup="formGroup">
          <kendo-datepicker [format]="'dd-MM-yyyy'" [readonly]="true" [formControl]="formGroup.get(column.field)">
          </kendo-datepicker>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="info" title="Info" encoded="false">
        <ng-template kendoGridEditTemplate let-column="column" let-formGroup="formGroup">
              <div class="grow-wrap">
                <textarea name="text"
                class="form-control"
                autofocus
                #input
                [formControl]="formGroup.get(column.field)"
                id="text"
                onmouseover="this.parentNode.dataset.replicatedValue = this.value"
                oninput="this.parentNode.dataset.replicatedValue = this.value"
                rows="4"
                ></textarea>
              </div>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem="dataItem">
          <div [innerHTML]="componentService.CreateAnchorTags(dataItem.info)"></div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="userFullName" title="Durch" [width]="200">
        <ng-template kendoGridEditTemplate let-dataItem="dataItem">
          {{ dataItem.userFullName }}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-command-column title="Aktionen" [width]="120">
        <ng-template kendoGridCellTemplate let-isNew="isNew" let-formGroup="formGroup">
          <button kendoGridEditCommand>
            <span class="k-icon k-i-pencil"></span>
          </button>
          <button kendoGridRemoveCommand *ngIf="userCanRemoveEntries">
            <span class="k-icon k-i-delete"></span>
          </button>
          <button kendoGridSaveCommand [disabled]="formGroup?.invalid">
            <ng-container *ngIf='isNew'>
              <span class="fa fa-pencil"></span><span class="k-icon k-i-save"></span>
            </ng-container>
            <ng-container *ngIf='!isNew'>
              <span class="fa fa-pencil"></span><span class="k-icon k-i-save"></span>
            </ng-container>
          </button>
          <button kendoGridCancelCommand>
            <span class="fa fa-pencil"></span><span class="k-icon k-i-close"></span>
          </button>
        </ng-template>
      </kendo-grid-command-column>
    </kendo-grid>
  </div>
</div>
