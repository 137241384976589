import { Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import { ProjectSetupTeamService } from './project-setup-team.service';
import { UIControlTyp } from 'src/app/core/models/UIControlTyp';
import { ProjectTeamMember } from 'src/app/core/models/ProjectTeamMember';
import { SharedService } from 'src/app/shared/shared.service';
import { AzurePersonRoles } from 'src/app/core/models/AzurePersonRoles';

@Component({
  selector: 'fdb-project-setup-team',
  templateUrl: './project-setup-team.component.html',
  styleUrls: ['./project-setup-team.component.scss'],
  providers: [
    ProjectSetupTeamService
  ]
})
export class ProjectSetupTeamComponent implements OnChanges{

  @Input() projectId: number;
  public UIControlTyp = UIControlTyp;
  public potentialTeamMember: ProjectTeamMember[] = [];
  public teamMember: ProjectTeamMember[] = [];
  public allPotentialTeamMember: ProjectTeamMember[] = [];

  constructor(public componentService: ProjectSetupTeamService, public modulService: SharedService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.projectId.currentValue > 0) {
      this.initValues();
    }
  }

  public initValues() {
    this.modulService.getAzurePerson(AzurePersonRoles.Any)
      .subscribe(apiResult => {
        if (!apiResult.isSuccessful) {
          this.modulService.showNotification(apiResult.message, 'error');
        }
        if (apiResult.payload) {
          let OriginalValues = apiResult.payload;
          let AzurePersonList = OriginalValues.filter(s => s.isActive === true);
          this.allPotentialTeamMember= AzurePersonList.map(person => {
              let member = new ProjectTeamMember();
              member.projectId = this.projectId;
              member.userId = person.id;
              member.name = person.name;
              return member;
          });
          this.potentialTeamMember=this.allPotentialTeamMember;
        }
      },
        err => this.modulService.showNotification(err, 'error'));
      this.componentService.modulService.getProjectTeamMembers(this.projectId)
      .subscribe(apiResult => {
        if (!apiResult.isSuccessful) {
          this.componentService.modulService.showNotification(apiResult.message, 'error');
        }
        if (apiResult.payload) {
          this.teamMember = apiResult.payload;
        }
      },
        err => this.componentService.modulService.showNotification(err, 'error'));
  }

  valueChanged(data){
    this.saveChanges();
  }

  filterPotentialTeamMember(value: any){
    this.potentialTeamMember = this.allPotentialTeamMember
      .filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }
  
  public getStringWithIds(): string {
    return this.teamMember.map(item => item.userId).join();
  }

  getRefreshSelectedValue() {
    this.teamMember = this.teamMember.map(x => this.potentialTeamMember.find(y => y.userId === x.userId));
  }

  public saveChanges(): void {
    const userIds = this.getStringWithIds();
    this.componentService.modulService
      .assignUserToProject(this.projectId, userIds)
      .subscribe(apiResult => {
        if (apiResult.isSuccessful) {
          this.componentService.modulService.showNotification(apiResult.message, 'success');
        } else {
          this.componentService.modulService.showNotification('Fehler : ' + apiResult.message, 'error');
        }
      },
        err => this.componentService.modulService.showNotification('Fehler : ' + err, 'error')
      );
  }
}
