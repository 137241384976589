import { ProjectSearchViewModel } from './ProjectSearchViewModel';
import { ProjectFilterModel } from '../services/api-clients-generated.service';

export class ProjectFilter{
    year: number | undefined;
    projectName: string | undefined;
    advisorId: number | undefined;
    projectManagerId: number | undefined;
    stateId: number | undefined;
    enBWUnitId: number | undefined;
    orgaUnit: string | undefined;
    freeText: string | undefined;

    static mapFromProjectSearchViewModel(model: ProjectSearchViewModel){
       const newItem = new ProjectFilter();
       newItem.year = model.year ? model.year.getFullYear() : undefined;
       newItem.projectName = model.name;
       newItem.advisorId = model.advisorId;
       newItem.projectManagerId = model.projectManagerId;
       newItem.stateId = model.stateId;
       newItem.enBWUnitId = model.enbwUnitId;
       newItem.orgaUnit = model.orgaUnit;
       newItem.freeText = model.freetext;
       return newItem;
    }
    
    static mapToProjectFilterModel(model: ProjectFilter){
        const newItem = new ProjectFilterModel();
        newItem.advisorId = model.advisorId;
        newItem.enBWUnitId = model.enBWUnitId;
        newItem.freeText = model.freeText;
        newItem.orgaUnit = model.orgaUnit;
        newItem.projectManagerId = model.projectManagerId;
        newItem.projectName = model.projectName;
        newItem.stateId = model.stateId;
        newItem.year = model.year;
        return newItem;
    }
}