import { Inject } from '@angular/core';
import { of } from 'rxjs';
import { AzurePerson } from 'src/app/core/models/AzurePerson';
//import { ProjectWorkspaceService } from 'src/app/service/project-workspace.service';
import { UserApiClient } from './apiClients/UserApiClient';
import { AppService } from './app.service';
import { Injectable } from '@angular/core';
import { Subscription, Observable, Observer } from 'rxjs';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { InteractionRequiredAuthError, AuthenticationResult, RedirectRequest, AccountInfo, IPublicClientApplication, EndSessionRequest } from '@azure/msal-browser';
import { Router, Resolve } from '@angular/router';
import { RoutingPath } from '../app-routing.module';
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Client } from '@microsoft/microsoft-graph-client';
import * as MicrosoftGraph from '@microsoft/microsoft-graph-types';

@Injectable({
  providedIn: 'root',
})
export class AdloginService implements Resolve<AzurePerson> {
  static currentUser: AzurePerson;

  loginDisplay = false;
  public loggedIn: boolean;
  private subscription: Subscription;
  private userApiClient: UserApiClient;
  private readonly _destroying$ = new Subject<void>();

  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
              private broadcastService: MsalBroadcastService,
              private authService: MsalService,
              public appService: AppService,
              private router: Router
  ) {
    this.userApiClient = new UserApiClient(this.appService);
  }

  getUser(): AccountInfo {
    let account = this.authService.instance.getActiveAccount();
    if (!account) {
      const allAccounts = this.authService.instance.getAllAccounts();
      if (allAccounts.length > 0) {
        account = allAccounts[0];
      }
    }
    return account;
  }

  public getMsalInstance(): IPublicClientApplication {
    return this.authService.instance;
  }

  resolve(): Observable<AzurePerson> {
    return this.getCurrentUser();
  }

  getCurrentUser(): Observable<AzurePerson> {
    if (!AdloginService.currentUser) {
      // console.log('Wird neu geladen');
      return this.userApiClient
        .getCurrentUser()
        .pipe(
          map(apiResult => {
            if (!apiResult.isSuccessful) {
              console.log('login failed');
              this.router.navigateByUrl('/' + RoutingPath.unknowUser);
              return null;
            }
            AdloginService.currentUser = apiResult.payload;
            return AdloginService.currentUser;
          }));
    } else {
      // console.log('Ist bereits geladen');
      return of(AdloginService.currentUser);
    }
  }

  login() {
    let loginRedirect: Observable<void>;
    
    if (this.msalGuardConfig.authRequest){
      loginRedirect = this.authService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
    } else {
      loginRedirect = this.authService.loginRedirect();
    }

    loginRedirect.subscribe({
      next: (result) => {
        console.log(result);
        this.setLoginDisplay();
      },
      error: (error) => console.log(error)
    });
  }

  logout() { // Add log out function here
    this.authService.logoutRedirect({
      mainWindowRedirectUri: "/"
    } as EndSessionRequest);
  }

  initAuth() {
    return null;
  }

  initAcquireToken() {
  }


  setLoginDisplay() {
    const allAccounts = this.authService.instance.getAllAccounts();
    this.loginDisplay = allAccounts.length > 0;
    if (this.loginDisplay) {
      localStorage.setItem("fdbClientSettingRetrievalCount", "0");
    }
  }

  async getAccessToken(paramScopes: string[]): Promise<string> {

    const result = await this.authService
        .acquireTokenSilent({
          account: this.getUser(),
          scopes: paramScopes // ["user.read"] //OAuthSettings.scopes
        })
        .toPromise()
        .catch((reason) => {
          console.warn(JSON.stringify(reason, null, 2));

          if (reason instanceof InteractionRequiredAuthError) {
            // fallback to interaction when silent call fails
            let result = this.authService.instance.acquireTokenRedirect({ scopes: paramScopes }); //.toPromise();
            //let result = this.authService.instance.acquireTokenPopup({ scopes: paramScopes }); //.toPromise();
            return result;
          }
        });

    if (result) {
      // Temporary to display token in an error box
      return result.accessToken;
    }

    // Couldn't get a token
    return '';
  }

  async getGraphUser(): Promise<string | undefined> {
    if (this.authService.instance.getAllAccounts().length == 0) {
      console.warn("getGraphUser: not authenticated");
      return undefined;
    }

    const graphClient = Client.init({
      // Initialize the Graph client with an auth
      // provider that requests the token from the
      // auth service
      authProvider: async(done) => {
        const token = await this.getAccessToken(["user.read"])
          .catch((reason) => {
            done(reason, null);
          });

        if (token)
        {
          done(null, token);
        } else {
          done("Could not get an access token", null);
        }
      }
    });

    // Get the user from Graph (GET /me)
    const graphUser: MicrosoftGraph.User = await graphClient
      .api('/me')
      .select('displayName,userPrincipalName')
      .get();

    const userDisplayName = graphUser.displayName ?? '';
    // Prefer the mail property, but fall back to userPrincipalName
    const userEmail = graphUser.mail ?? graphUser.userPrincipalName ?? '';
    const userTimeZone = graphUser.mailboxSettings?.timeZone ?? 'UTC';

    return userDisplayName;
  }
}
