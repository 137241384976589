<ng-container *ngIf="uiController === UIControlTyp.MultiSelectList">
  <kendo-multiselect
    textField="name"
    valueField="id"
    [data]="EnBWUnitList"
    [filterable]="true"
    (filterChange)="filterenbwunit($event)"
    [(ngModel)]="EnBWUnits"
    placeholder="Wählen Sie einen Wert aus"
    (valueChange)="valueChanged()">
  </kendo-multiselect>
</ng-container>
<ng-container *ngIf="uiController === UIControlTyp.DropDownList">
  <kendo-dropdownlist
    class="form-control"
    textField="name"
    valueField="id"
    [data]="EnBWUnitList"
    [(ngModel)]="EnBWUnit"
    (valueChange)="valueChanged()">
  </kendo-dropdownlist>
</ng-container>
<ng-container *ngIf="uiController === UIControlTyp.GridSingleValueField">
  {{ getName() }}
</ng-container>
