import { DDBase } from './DDBase';
import { ProjectTypeModel } from '../services/api-clients-generated.service';

export class ProjectType extends DDBase {
  static mapFromProjectTypeModel(items: ProjectTypeModel[]): ProjectType[] {
    return items.map(item => {
      const newItem = new ProjectType();
      newItem.id = item.id;
      newItem.name = item.name;
      newItem.isActive = item.isActive;
      return newItem;
    });
  }
}
