import { ProjectOverviewStatistic } from './ProjectOverviewStatistic';
// ViewModel for search-Project-component
export class ProjectSearchViewModel {
  name: string;
  enbwUnitId: number = 0;
  advisorId: number = 0;
  orgaUnit: string;
  year: Date;
  stateId: number = 0;
  projectManagerId: number = 0;
  freetext: string;
  numberFilter: number;
  statistik: ProjectOverviewStatistic;
}
