 import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, RedirectRequest, InteractionStatus, AccountInfo } from '@azure/msal-browser';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AdloginService } from './service/adlogin.service';

@Component({
  selector: 'fdb-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'EnBW-Forschungsdatenbank';
  isIframe = false;
  isLoggedIn = true;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();

  constructor(private adlogin: AdloginService,
              @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
              private authService: MsalService,
              private broadcastService: MsalBroadcastService) {
  }

  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;

    this.broadcastService.inProgress$
                          .pipe(
                            filter((status: InteractionStatus) => status === InteractionStatus.None),
                            takeUntil(this._destroying$)
                          )
                          .subscribe(() => {
                            this.setLoginDisplay();
                          });

    this.setLoginDisplay();
    if (!this.loginDisplay) {
      this.adlogin.login();
    }
  }

  setLoginDisplay() {
    const allAccounts = this.authService.instance.getAllAccounts();
    this.loginDisplay = allAccounts.length > 0;
    if (this.loginDisplay) {
      localStorage.setItem("fdbClientSettingRetrievalCount", "0");
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }
}
