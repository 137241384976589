import { AdloginService } from './../service/adlogin.service';
import { AdminComponent } from './admin.component';
import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { RoutingPath } from '../app-routing.module';
import { ImportInformationComponent } from './import-information/import-information.component';
import { MsalGuard } from '@azure/msal-angular';

const projectsRoutes: Routes = [
  {
    path: RoutingPath.admin,
    component: AdminComponent,
    canActivate: [MsalGuard],
    resolve: { currentUser: AdloginService }
  },
  {
    path: RoutingPath.importInfo,
    component: ImportInformationComponent,
    canActivate: [MsalGuard],
    resolve: { currentUser: AdloginService }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(projectsRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class AdminRoutingModule { }
