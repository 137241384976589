import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RoutingPath } from '../app-routing.module';
import { DocumentComponent } from './document.component';
import { MsalGuard } from '@azure/msal-angular';
import { AdloginService } from '../service/adlogin.service';

const routes: Routes = [
  {
    path: RoutingPath.document,
    component: DocumentComponent,
    canActivate: [MsalGuard],
    resolve: { currentUser: AdloginService }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DocumentRoutingModule { }
