// General Valuelist Model
export class ApiResult {
  isSuccessful: boolean;
  message?: string | undefined;
  payload?: any | undefined;
  statusCode: number;
  constructor(isSuccessful: boolean, message: string, payload: any, statusCode: number) {
    this.isSuccessful = isSuccessful;
    this.message = message;
    this.payload = payload;
    this.statusCode = statusCode;
  }
}
