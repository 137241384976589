import { AppService } from './../app.service';
import { IApiClient } from 'src/app/core/models/IApiClient';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IUsersClient, UsersClient } from 'src/app/core/services/api-clients-generated.service';
import { ApiResult } from 'src/app/core/models/ApiResult';
import { AzurePerson } from 'src/app/core/models/AzurePerson';

export class ProjectManagerApiClient implements IApiClient {
  private userClient: IUsersClient;
  private roleName = 'Projekt Leiter';

  constructor(private appService: AppService) {}

  getAll(includeInactive: boolean): Observable<ApiResult> {
    return this.getUserClient()
      .get2(this.roleName)
      .pipe(
        map(items =>
          this.appService.mapToApiResult(items, AzurePerson.mapFromUserModels(items.payload))
        )
      );
  }
  getFromId(id: number): Observable<ApiResult> {
    return this.appService.getErrorApiResult('ProjectManager => Method not implemented\.');
  }
  getFromProjectId(projectId: number): Observable<ApiResult> {
    return this.appService.getErrorApiResult('ProjectManager => Method not implemented\.');
  }
  create(model: any): Observable<ApiResult> {
    return this.appService.getErrorApiResult('ProjectManager => Method not implemented\.');
  }
  createList(models: any[]): Observable<ApiResult> {
    return this.appService.getErrorApiResult('ProjectManager => Method not implemented\.');
  }
  remove(id: number): Observable<ApiResult> {
    return this.appService.getErrorApiResult('ProjectManager => Method not implemented\.');
  }
  removeList(ids: number[]): Observable<ApiResult> {
    return this.appService.getErrorApiResult('ProjectManager => Method not implemented\.');
  }
  update(model: any): Observable<ApiResult> {
    return this.appService.getErrorApiResult('ProjectManager => Method not implemented\.');
  }
  updateList(models: any[]): Observable<ApiResult> {
    return this.appService.getErrorApiResult('ProjectManager => Method not implemented\.');
  }
  private getUserClient(): IUsersClient {
    if (!this.userClient) {
      this.userClient = new UsersClient(this.appService.http, this.appService.apiUrl);
    }
    return this.userClient;
  }
}
