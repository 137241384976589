import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { SharedService } from '../shared.service';
import { UIControlTyp } from 'src/app/core/models/UIControlTyp';
import { AzurePersonRoles } from 'src/app/core/models/AzurePersonRoles';
import { AzurePerson } from 'src/app/core/models/AzurePerson';

@Component({
  selector: 'fdb-azure-person',
  templateUrl: './azure-person.component.html',
  styleUrls: ['./azure-person.component.scss']
})
export class AzurePersonComponent implements OnInit {
  public UIControlTyp = UIControlTyp;
  public AzurePersonRoles = AzurePersonRoles;

  /** Hiermit können Sie den UIControlTyp entscheiden => Default DropDownList */
  @Input() uiController: UIControlTyp = UIControlTyp.DropDownList;
  /** Hiermit definieren Sie von welchen Rolen Sie die User möchten
   * => Default ProjectTeamMember
   */
  @Input() roles: AzurePersonRoles = AzurePersonRoles.Any;
  // tslint:disable-next-line: no-input-rename
  @Input('values')
  get values() {
    const val = this.uiController === UIControlTyp.MultiSelectList ?
      this.AzurePersons.map(unit => Number(unit.id)) :
      Number(this.AzurePerson.id);
    return val;
  }
  set values(val: string | number | number[]) {
    this.uiController === UIControlTyp.MultiSelectList ?
      this.AzurePersons = (val as number[]).map(id => this.getObjectFromList(id)) :
      this.AzurePerson = this.getObjectFromList(Number(val));
  }
  @Output() valuesChange = new EventEmitter();
  @Output() objectChange = new EventEmitter();

  /** Enthält die ausgewählten Werte bei UIControlTyp.MultiSelectList */
  public AzurePersons: AzurePerson[] = [];
  /** Enthält die ausgewählten Wert bei UIControlTyp.DropDownList */
  public AzurePerson: AzurePerson;
  /** Enthält die Werte die angezeigt werden dürfen */
  public AzurePersonList: AzurePerson[] = [];
  /** Enthält die Originalliste mit allen Werten */
  private OriginalValues: AzurePerson[] = [];

  constructor(private modulService: SharedService) {
  }

  ngOnInit() {
    this.initAzurePersonList();
  }

  initAzurePersonList() {
    const that = this;
    this.modulService.getAzurePerson(that.roles)
      .subscribe(apiResult => {
        if (!apiResult.isSuccessful) {
          that.modulService.showNotification(apiResult.message, 'error');
        }
        if (apiResult.payload) {
          that.OriginalValues = apiResult.payload;
          that.AzurePersonList = that.OriginalValues.filter(s => s.isActive === true);
          if (that.uiController === UIControlTyp.DropDownList) {
            that.AzurePersonList.unshift(new AzurePerson().getEmptyModel());
          }
          if (that.uiController === UIControlTyp.MultiSelectList) {
            that.getRefreshSelectedValue();
          }
        }
      },
        err => that.modulService.showNotification(err, 'error'));
  }

  getName() {
    const find = this.getObjectFromList(Number(this.AzurePerson.id));
    if (find) {
      return find.id === 0 ? '-- Bitte pflegen --' : find.name;
    }
    return '';
  }

  filterAzurePersons(value: string) {
    this.AzurePersonList = this.OriginalValues
      .filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1 && s.isActive === true);
  }

  valueChanged(): void {
    this.valuesChange.emit(this.values);
    this.objectChange.emit(this.AzurePerson);
  }

  getObjectFromList(id: number): AzurePerson {
    if (id === 0) { return new AzurePerson().getEmptyModel(); }
    const find = this.OriginalValues.find(unit => unit.id === id);
    return find ? find : new AzurePerson().setId(id);
  }
  getRefreshSelectedValue() {
    this.AzurePersons = this.AzurePersons.map(x => this.OriginalValues.find(y => y.id === x.id));
  }
}
