import { ProjectCostAccount } from './../../../core/models/ProjectCostAccount';
import { CostTypeSource, CostAmountModel } from './../../../core/services/api-clients-generated.service';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Project } from 'src/app/core/models/Project.Model';
import { ProjectCostItem } from 'src/app/core/models/ProjectCostItem';
import { ProjectCost } from 'src/app/core/models/ProjectCost';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProjectService } from '../../project.service';
import { ProjectCostItemAmount } from 'src/app/core/models/ProjectCostItemAmount';
import { Observable } from 'rxjs';
import { ApiResult } from 'src/app/core/models/ApiResult';
import { ProjectCostType } from 'src/app/core/models/ProjectCostType';
import { getToday } from '@progress/kendo-angular-dateinputs/esm2015/util';

interface ColumnSetting {
  field?: string;
  title: string;
  width?: number;
  isAction?: boolean;
  format?: string;
  type?: 'text' | 'numeric' | 'boolean' | 'date';
}

@Component({
  selector: 'fdb-project-costs',
  templateUrl: './project-costs.component.html',
  styleUrls: ['./project-costs.component.scss']
})
export class ProjectCostsComponent implements OnChanges {
  @Input() project: Project;

  constructor(private formBuilder: FormBuilder, public modulService: ProjectService) {
  }

  GridEditData: any[] = [];
  Columns: ColumnSetting[] = [];
  Years: string[] = [];
  editedRowIndex: any;
  editedRow: any;
  buttonDisabled = false;
  costId: number;
  currentlyLoading: Boolean = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (this.project && this.project.id > 0) {
      this.initValues();
    }
  }

  getColumns(): ColumnSetting[] {
    const sd = this.project.startDate;
    const ed = this.project.endDate;
    if (! this.currentlyLoading && sd && ed) {
      let l = ed.getFullYear() - sd.getFullYear() + 1;
      if (l < 1 || this.Years.length != l || this.Years[0] != sd.getFullYear().toString()) {
        this.initValues();
      }
    }

    return this.Columns;
  }

  initValues() {
    this.currentlyLoading = true;
    this.modulService.getCostItems(this.project.id)
      .subscribe(apiResult => {
        if (!apiResult.isSuccessful) {
          this.modulService.showNotification(apiResult.message, 'error');
        }

        this.GridEditData = this.distributeValues(apiResult.payload);
        this.currentlyLoading = false;
      },
        (err: any) => {
          this.modulService.showNotification(err, 'error');
          this.currentlyLoading = false;
        }
      );
  }

  public onStateChange(event: any) {
    this.initValues();
  }

  public editHandler({ sender, rowIndex, dataItem }) {

    const projectStatusRequested = 2;

    this.closeEditor(sender);

    if (this.isRowEditable(rowIndex, this.project.stateId)) {
    //if (this.project.stateId <= projectStatusRequested) {
      this.editedRowIndex = rowIndex;
      this.editedRow = Object.assign({}, dataItem);

      sender.editRow(rowIndex, this.createFormGroup(dataItem));
    }
  }

  public cancelHandler({ sender, rowIndex }) {
    this.closeEditor(sender, rowIndex);
  }

  public saveHandler({ sender, formGroup, rowIndex }) {
    let callFunction: Observable<ApiResult>;

    if (this.editedRow.source === -1) {
      callFunction = this.modulService.updateCostAccount(this.GetCostAccount(formGroup.value));
    } else {
      let projectCostItems = this.GetCostItemAmount(formGroup.value);
      callFunction = this.modulService.updateCostPlanning(this.project.id, this.costId, projectCostItems);
    }

    callFunction.subscribe(apiResult => {
      if (!apiResult.isSuccessful) {
        this.modulService.showNotification(apiResult.message, 'error');
      } else {
        this.modulService.showNotification(apiResult.message, 'success');
        this.initValues();
      }
    },
      (err: any) => this.modulService.showNotification(err, 'error')
    );

    this.closeEditor(sender, rowIndex);
  }

  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.editedRow = undefined;
  }

  public createEditValues() {

    this.buttonDisabled = false;
    this.modulService.createEditValues(this.project.id)
      .subscribe(apiResult => {
        if (!apiResult.isSuccessful) {
          this.modulService.showNotification(apiResult.message, 'error');
          this.buttonDisabled = true;
        }
        this.GridEditData = this.distributeValues(apiResult.payload);
        this.modulService.showNotification(apiResult.message, 'success');
      },
        (err: any) => {
          this.buttonDisabled = true;
          this.modulService.showNotification(err, 'error');
        }
      );
  }

  public createFormGroup(dataItem: any): FormGroup {
    const formGroup = {};
    Object.assign(formGroup, { projectId: [dataItem.projectId, Validators.required] });
    this.Years.forEach(year => {
      const fieldNameId = 'id_' + year;
      Object.assign(formGroup, { [fieldNameId]: [dataItem[fieldNameId], Validators.required] });
      const fieldNameAmount = 'value_' + year;
      Object.assign(formGroup, { [fieldNameAmount]: [dataItem[fieldNameAmount], Validators.min(0)] });
    });
    return this.formBuilder.group(formGroup);
  }

  public isRowEditable(rowIndex: number, state: number)
  {
    const projectStatusRequested = 2;
    const projectStatusClosed = 7
    let result: boolean;
    if(rowIndex === 0)
    {
      result = state > projectStatusRequested ? false:true;
    }
    else
    {
      result = state === projectStatusClosed ? false:true;
    }

    return result;
  }

  private distributeValues(projectCost: ProjectCost): any[] {
    if (this.project.startDate && this.project.endDate) {
      this.Years = [];
      for (let y = this.project.startDate.getFullYear(); y <= this.project.endDate.getFullYear(); y++) {
        this.Years.push(y.toString());
      }
    }
    else {
      this.Years = projectCost.accounts.map(acc => acc.year.toString());
    }
    if (projectCost.costs && projectCost.costs.length > 0 && projectCost.costs[0].id) {
      this.costId = Number(projectCost.costs[0].id);
    }
    else {
      let pct = new ProjectCostType();
      pct.id = 1;
      pct.name = 'Kostenplanung initial';
      pct.source = CostTypeSource.EnBW;

      let cost = new ProjectCostItem();
      cost.projectId = this.project.id;
      cost.date = getToday();
      cost.costType = pct;
      projectCost.costs.push(cost);
    }
    this.createGridColumns();
    return this.createGridData(projectCost);
  }

  private createGridData(projectCost: ProjectCost): any[] {
    const accounts = projectCost.accounts;
    const costsPlanning = projectCost.costs.filter(item => item.costType.source === CostTypeSource.EnBW);
    const gridData = [];
    costsPlanning.forEach(costitem => gridData.push(this.getCostPlanningObject(costitem)));
    if (accounts.length > 0) {
      gridData.push(this.getAccountObject(accounts));
      this.buttonDisabled = false;
    } else {
      this.buttonDisabled = true;
    }

    return gridData;
  }

  private createGridColumns() {
    this.Columns = [];
    this.Columns.push({ field: 'name', title: 'Art', isAction: false });
    this.Years.forEach(y => this.Columns.push({ field: 'value_' + y, title: y }));
    this.Columns.push({ field: 'sum', title: 'Summe Jahre', width: 150 });
    this.Columns.push({ field: 'action', title: 'Aktionen', isAction: true, width: 150 });
  }

  private getCostPlanningObject(costItem: ProjectCostItem): any {
    const item = { name: costItem.costType.name };
    Object.assign(item, { source: costItem.costType.source });
    Object.assign(item, { id: costItem.id });
    Object.assign(item, { sum: costItem.sum });
    Object.assign(item, { projectId: costItem.projectId });
    costItem.amounts.forEach(amount => {
      const fieldNameId = 'id_' + amount.year;
      Object.assign(item, { [fieldNameId]: amount.id });

      const fieldNameAmount = 'value_' + amount.year;
      Object.assign(item, { [fieldNameAmount]: amount.amount });
    });
    return item;
  }

  private getAccountObject(accounts: ProjectCostAccount[]): any {
    const obj = { name: 'Abrechnungskonten' };

    Object.assign(obj, { source: -1 });
    accounts.forEach(x => {
      Object.assign(obj, { projectId: x.projectId });
      const fieldNameId = 'id_' + x.year;
      Object.assign(obj, { [fieldNameId]: x.id });
      const fieldNameAmount = 'value_' + x.year;
      Object.assign(obj, { [fieldNameAmount]: x.pspElement });
    });
    return obj;
  }

  private GetCostItemAmount(formValue: any): ProjectCostItemAmount[] {
    const result: ProjectCostItemAmount[] = [];
    this.Years.forEach(year => {
      const id = formValue['id_' + year];
      const newItem = new ProjectCostItemAmount();
      if (id && id > 0) {
        newItem.id = id;
      }
      newItem.amount = Number(formValue['value_' + year]);
      newItem.year = Number(year);
      result.push(newItem);
    });
    return result;
  }

  private GetCostAccount(formValue: any): ProjectCostAccount[] {
    const result: ProjectCostAccount[] = [];
    this.Years.forEach(year => {
      const id: number = formValue['id_' + year];
      const newItem = new ProjectCostAccount();
      if (id && id > 0) {
        newItem.id = id;
      }
      newItem.pspElement = formValue['value_' + year];
      newItem.year = Number(year);
      newItem.projectId = this.project.id;
      result.push(newItem);
    });
    return result;
  }
}
