import { InsertRiskModel, UpdateRiskModel } from './../services/api-clients-generated.service';
import { DDBase } from './DDBase';
import { RiskModel } from '../services/api-clients-generated.service';
export class ProjectRisk extends DDBase {
  projectId: number;
  impact: string;
  riskRatingId: number;
  static MapFromRiskModels(items: RiskModel[]): ProjectRisk[] {
    return items.map(item => this.MapFromRiskModel(item));
  }
  static MapFromRiskModel(item: RiskModel): ProjectRisk {
    const newItem = new ProjectRisk();
    newItem.id = item.id;
    newItem.projectId = item.projectId;
    newItem.name = item.name;
    newItem.riskRatingId = item.riskRatingId;
    newItem.impact = item.impact;
    return newItem;
  }
  static MapToInsertRiskModels(items: ProjectRisk[]): InsertRiskModel[] {
    return items.map(item => this.MapToInsertRiskModel(item));
  }
  static MapToInsertRiskModel(item: ProjectRisk): InsertRiskModel {
    const newItem = new InsertRiskModel();
    newItem.projectId = item.projectId;
    newItem.name = item.name;
    newItem.riskRatingId = item.riskRatingId ? item.riskRatingId : null;
    newItem.impact = item.impact;
    return newItem;
  }
  static MapToUpdateRiskModels(items: ProjectRisk[]): UpdateRiskModel[] {
    return items.map(item => this.MapToUpdateRiskModel(item));
  }
  static MapToUpdateRiskModel(item: ProjectRisk): UpdateRiskModel {
    const newItem = new UpdateRiskModel();
    if (item.id != "") {
      newItem.id = Number(item.id);
    }
    newItem.projectId = item.projectId;
    newItem.name = item.name;
    newItem.riskRatingId = item.riskRatingId ? item.riskRatingId : null;
    newItem.impact = item.impact;
    return newItem;
  }
}
