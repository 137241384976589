<div class="container">
  <div class="align-items-center">
    <div class="col">
      <div class="{{alertClass}}" role="alert">
        <h4 class="alert-heading">{{ header }}</h4>
        <p>{{ content }}</p>
        <hr>
        <p class="mb-0">{{ feed }}</p>
      </div>
    </div>
  </div>
</div>
