import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UIControlTyp } from 'src/app/core/models/UIControlTyp';

@Component({
  selector: 'fdb-switch-input',
  templateUrl: './switch-input.component.html',
  styleUrls: ['./switch-input.component.scss']
})
export class SwitchInputComponent {
  public UIControlTyp = UIControlTyp;
  /** Hiermit können Sie den UIControlTyp entscheiden => Default GridSingleValueField */
  @Input() uiController: UIControlTyp = UIControlTyp.GridSingleValueField;
  @Input() values: any;

  @Output() valuesChange = new EventEmitter();

  constructor() { }

  onChange(newValue: any) {
    this.values = newValue;
    this.valuesChange.emit(this.values);
  }

}
