import { AlertMessageComponent } from './alert-message/alert-message.component';
import { UnknowUserComponent } from './unknow-user/unknow-user.component';
import { NgModule } from '@angular/core';
import { AppcommonRoutingModule } from './appcommon-routing.module';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';

@NgModule({
  declarations: [
    AlertMessageComponent,
    UnknowUserComponent,
    UnauthorizedComponent
  ],
  imports: [
     AppcommonRoutingModule
  ]
})
export class AppcommonModule { }
