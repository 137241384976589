import { Component, Input, EventEmitter, Output } from '@angular/core';
import { UIControlTyp } from 'src/app/core/models/UIControlTyp';
import { AzurePersonRoles } from 'src/app/core/models/AzurePersonRoles';
import { AzurePerson } from 'src/app/core/models/AzurePerson';

@Component({
  selector: 'fdb-advisor',
  templateUrl: './advisor.component.html',
  styleUrls: ['./advisor.component.scss']
})
export class AdvisorComponent {
  /** Hiermit können Sie bestimmen ob eine DropDown Liste angezeigt wird oder eine MultiSelect Eingabefeld */
  @Input() uiController: UIControlTyp = UIControlTyp.DropDownList;
  @Input() values: any;
  roles: AzurePersonRoles = AzurePersonRoles.Advisor;
  @Output() valuesChange = new EventEmitter();
  @Output() objectChange = new EventEmitter<AzurePerson>();

  constructor() {
    this.roles = AzurePersonRoles.Advisor;
  }

  onChange(newValue: any) {
    this.values = newValue;
    this.valuesChange.emit(this.values);
  }

  objectChanged(newValue: any) {
    this.objectChange.emit(newValue);
  }
}
