import { ProjectService } from './project.service';
import { CoreModule } from './../core/core.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NewProjectComponent } from './new/new-project.component';
import { ProjectSetupComponent } from './details/setup/project-setup.component';
import { SearchProjectComponent } from './search/search-project.component';
import { ProjectDetailsComponent } from './details/project-details.component';
import { ProjectLogbookComponent } from './details/logbook/project-logbook.component';
import { ProjectCostsComponent } from './details/costs/project-costs.component';
import { ProjectTransferComponent } from './details/transfer/project-transfer.component';
import { ProjectDocumentsComponent } from './details/documents/project-documents.component';
import { ProjectDescriptionComponent } from './details/description/project-description.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ProjectSetupMilestoneComponent } from './details/setup/milestone/project-setup-milestone.component';
import { ProjectSetupRiskComponent } from './details/setup/risk/project-setup-risk.component';
import { ProjectSetupTeamComponent } from './details/setup/team/project-setup-team.component';
import { ProjectMasterdataComponent } from './details/masterdata/project-masterdata.component';
import { ProjectRoutingModule } from './project-routing.module';
import { SharedModule } from '../shared/shared.module';
import { DialogInfoPurchaseComponent } from './dialog-info-purchase/dialog-info-purchase.component';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { BrowserModule } from '@angular/platform-browser';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { ApprovalComponent } from './approval/approval.component';
 import { StorageServiceModule } from 'ngx-webstorage-service';


@NgModule({
  declarations: [
    NewProjectComponent,
    ProjectSetupComponent,
    SearchProjectComponent,
    ProjectDetailsComponent,
    ProjectLogbookComponent,
    ProjectCostsComponent,
    ProjectTransferComponent,
    ProjectDocumentsComponent,
    ProjectSetupMilestoneComponent,
    ProjectSetupRiskComponent,
    ProjectSetupTeamComponent,
    ProjectDescriptionComponent,
    ProjectMasterdataComponent,
    DialogInfoPurchaseComponent,
    ApprovalComponent
  ],

  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    CoreModule,
    RouterModule,
    ProjectRoutingModule,
    SharedModule,
    ButtonsModule,
    DialogModule,
    StorageServiceModule
  ],
  providers: [
    ProjectService
  ]
})
export class ProjectModule { }
