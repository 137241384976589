import { UpdateProjectModel, ProjectListItemModel, ProjectHeaderModel, UpdateStateModel } from './../services/api-clients-generated.service';
import { Base } from './Base';
import { ProjectModel, InsertProjectModel, ProjectRequestModel } from '../services/api-clients-generated.service';
import * as moment from 'moment';
import { YesNo } from './YesNo';

export class Project extends Base {
  id: number;
  number: string;
  name: string;
  endDate: Date;
  startDate: Date;
  stateId: number;
  advisorId: number | string = 0;
  enbwUnitId: number | string = 0;
  orgaUnit: string;
  projectManagerId: number | string = 0;
  projectManagerPhoneNumber: string;
  projectManagerEmail: string;
  projectManagerFaxNumber: string;
  shortDescription: string;
  commercialPartner: string;
  motivation: string;
  utilization: string;
  timeWorkSchedule: string;
  tagIds: (number | string)[] = [];
  isTransferableId: number | string = 0;
  academicPartnerIds: (number | string)[] = [];
  projectTypeId: number | string = 0;
  publicFundingIds: (number | string)[] = [];
  createdDate: Date;
  createdUserId: number;
  infoToPurchase: boolean;
  applicationDate: Date;
  transferUnit: string;
  transferManagerId: number | string = 0;
  transferManagerPhone: string;
  transferManagerEmail: string;
  transferManagerFax: string;
  transferResult: string;
  transferSuccessId: number | string = 0;
  projectWorkspaceUrl: string;
  readyForMailToPurchasing: boolean = false;
  stateName: string;
  advisorName: string;
  enbwUnitName: string;

  static mapFromProjectListItemModels(items: ProjectListItemModel[]): Project[] {
    return items.map(item => this.mapFromProjectListItemModel(item));
  }

  static mapFromProjectListItemModel(item: ProjectListItemModel): Project {
    const newItem = new Project();
    newItem.id = item.id;
    newItem.number = item.number;
    newItem.name = item.name;
    newItem.endDate = item.endDate ? item.endDate.toDate() : null;
    newItem.shortDescription = item.info;
    newItem.stateId = item.stateId;
    newItem.createdDate = item.infoDate ? item.infoDate.toDate() : null;
    newItem.enbwUnitId = item.enBWunitId;
    newItem.advisorId = item.advisorId;
    newItem.stateName = item.stateName;
    newItem.advisorName = item.advisorName;
    newItem.enbwUnitName = item.enBWUnitName;
    return newItem;
  }

  static mapToInsertProjectModel(model: Project): InsertProjectModel {
    const newItem = new InsertProjectModel();
    newItem.name = model.name;
    newItem.shortDescription = model.shortDescription;
    newItem.advisorId = model.advisorId ? Number(model.advisorId) : null;
    newItem.enBWunitId = model.enbwUnitId ? Number(model.enbwUnitId) : null;
    newItem.orgaUnit = model.orgaUnit;
    newItem.projectManagerId = model.projectManagerId ? Number(model.projectManagerId) : null;
    newItem.projectManagerPhone = model.projectManagerPhoneNumber ? model.projectManagerPhoneNumber : null;
    newItem.projectManagerEmail = model.projectManagerEmail ? model.projectManagerEmail : null;
    newItem.projectManagerFax = model.projectManagerFaxNumber ? model.projectManagerFaxNumber : null;
    newItem.startDate = model.startDate ? moment(model.startDate) : null;
    newItem.endDate = model.endDate ? moment(model.endDate) : null;
    newItem.commercialPartners = model.commercialPartner ? model.commercialPartner : null;
    newItem.motivation = model.motivation ? model.motivation : null;
    newItem.utilization = model.utilization ? model.utilization : null;
    newItem.isTransferable = model.isTransferableId ? YesNo.getBoolFromYesNo(Number(model.isTransferableId)) : false;
    newItem.projectTypeId = model.projectTypeId ? Number(model.projectTypeId) : null;
    newItem.timeWorkSchedule = model.timeWorkSchedule ? model.timeWorkSchedule : null;
    newItem.tags = model.tagIds ? model.tagIds.map(tag => tag.toString()) : [];
    newItem.academicPartnerIds = model.academicPartnerIds ? model.academicPartnerIds.map(s => Number(s)) : [];
    newItem.publicFundingIds = model.publicFundingIds ? model.publicFundingIds.map(ap => Number(ap)) : [];
    newItem.projectWorkspaceUrl = model.projectWorkspaceUrl;
    return newItem;
  }
  
  static mapToUpdateProjectModel(model: Project): UpdateProjectModel {
    const newItem = new UpdateProjectModel();
    newItem.id = Number(model.id);
    newItem.projectTypeId = model.projectTypeId ? Number(model.projectTypeId) : null;
    newItem.name = model.name;
    newItem.shortDescription = model.shortDescription;
    newItem.isTransferable = model.isTransferableId ? YesNo.getBoolFromYesNo(Number(model.isTransferableId)) : false;
    newItem.startDate = model.startDate ? moment(model.startDate) : null;
    newItem.endDate = model.endDate ? moment(model.endDate) : null;
    newItem.commercialPartners = model.commercialPartner ? model.commercialPartner : null;
    newItem.motivation = model.motivation ? model.motivation : null;
    newItem.utilization = model.utilization ? model.utilization : null;
    newItem.academicPartnerIds = model.academicPartnerIds ? model.academicPartnerIds.map(s => Number(s)) : [];
    newItem.publicFundingIds = model.publicFundingIds ? model.publicFundingIds.map(ap => Number(ap)) : [];
    newItem.timeWorkSchedule = model.timeWorkSchedule ? model.timeWorkSchedule : null;
    newItem.tags = model.tagIds ? model.tagIds.map(tag => tag.toString()) : [];
    newItem.transferManagerId = model.transferManagerId ? Number(model.transferManagerId) : null;
    newItem.transferManagerEmail = model.transferManagerEmail;
    newItem.transferManagerFax = model.transferManagerFax;
    newItem.transferManagerPhone = model.transferManagerPhone;
    newItem.transferResult = model.transferResult;
    newItem.transferSuccessId = model.transferSuccessId ? Number(model.transferSuccessId) : null;
    newItem.transferUnit = model.transferUnit;
    newItem.projectWorkspaceUrl = model.projectWorkspaceUrl;
    return newItem;
  }

  static mapFromProjectModel(item: ProjectModel): Project {
    const newItem = new Project();
    newItem.id = item.id;
    newItem.number = item.number;
    newItem.stateId = item.stateId;
    newItem.projectManagerId = item.projectManagerId ? item.projectManagerId : 0;
    newItem.projectTypeId = item.projectTypeId ? item.projectTypeId : 0;
    newItem.enbwUnitId = item.enBWunitId ? item.enBWunitId : 0;
    newItem.orgaUnit = item.orgaUnit;
    newItem.name = item.name;
    newItem.shortDescription = item.shortDescription;
    newItem.startDate = item.startDate ? item.startDate.toDate() : null;
    newItem.endDate = item.endDate ? item.endDate.toDate() : null;
    newItem.projectManagerPhoneNumber = item.projectManagerPhone;
    newItem.projectManagerEmail = item.projectManagerEmail;
    newItem.projectManagerFaxNumber = item.projectManagerFax;
    newItem.commercialPartner = item.commercialPartners;
    newItem.motivation = item.motivation;
    newItem.utilization = item.utilization;
    newItem.isTransferableId = YesNo.getIdFromBool(item.isTransferable);
    newItem.createdDate = item.creationDate ? item.creationDate.toDate() : null;
    newItem.createdUserId = item.creationUserId;
    newItem.advisorId = item.advisorId ? item.advisorId : 0;
    newItem.academicPartnerIds = item.academicPartnerIds ? item.academicPartnerIds : [];
    newItem.publicFundingIds = item.publicFundingIds ? item.publicFundingIds : [];
    newItem.timeWorkSchedule = item.timeWorkSchedule;
    newItem.tagIds = item.tags ? item.tags.map(tag => tag.name) : [];
    newItem.transferManagerId = item.transferManagerId ? item.transferManagerId : 0;
    newItem.transferManagerPhone = item.transferManagerPhone;
    newItem.transferManagerFax = item.transferManagerFax;
    newItem.transferManagerEmail = item.transferManagerEmail;
    newItem.transferSuccessId = item.transferSuccessId ? item.transferSuccessId : 0;
    newItem.transferUnit = item.transferUnit;
    newItem.transferResult = item.transferResult;
    newItem.projectWorkspaceUrl = item.projectWorkspaceUrl;
    newItem.readyForMailToPurchasing = item.readyForMailToPurchasing;
    return newItem;
  }

  static mapToUpdateProjectHeaderModel(item: Project): ProjectHeaderModel {
    const newItem = new ProjectHeaderModel();
    newItem.id = Number(item.id);
    newItem.number = item.number;
    newItem.stateId = item.stateId > 0 ? Number(item.stateId) : null;
    newItem.projectManagerId = item.projectManagerId > 0 ? Number(item.projectManagerId) : null;
    newItem.enBWunitId = item.enbwUnitId > 0 ? Number(item.enbwUnitId) : null;
    newItem.orgaUnit = item.orgaUnit;
    newItem.name = item.name;
    newItem.projectManagerPhone = item.projectManagerPhoneNumber;
    newItem.projectManagerEmail = item.projectManagerEmail;
    newItem.projectManagerFax = item.projectManagerFaxNumber;
    newItem.advisorId = item.advisorId > 0 ? Number(item.advisorId) : null;
    return newItem;
  }
  
  static mapToUpdateStateModel(item: Project): UpdateStateModel {
    const newItem = new UpdateStateModel();
    newItem.projectId = item.id;
    newItem.targetStateId = item.stateId;
    return newItem;
  }
  
  static mapToProjectRequestModel(projectId: number, stateId: number, requestMsg: string): ProjectRequestModel {
    const newItem = new ProjectRequestModel();
    newItem.projectId = projectId;
    newItem.requestMsg = requestMsg;
    newItem.stateId = stateId;
    return newItem;
  }
}
