import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SharedService } from '../shared.service';
import { YesNo } from 'src/app/core/models/YesNo';
import { UIControlTyp } from 'src/app/core/models/UIControlTyp';

@Component({
  selector: 'fdb-yesno',
  templateUrl: './yesno.component.html',
  styleUrls: ['./yesno.component.scss']
})
export class YesnoComponent {
  public UIControlTyp = UIControlTyp;
  /** Hiermit können Sie den UIControlTyp entscheiden => Default DropDownList */
  @Input() uiController: UIControlTyp = UIControlTyp.DropDownList;
  // tslint:disable-next-line: no-input-rename
  @Input('values')
  get values() {
    const val = this.uiController === UIControlTyp.MultiSelectList ?
      this.YesNos.map(unit => Number(unit.id)) :
      Number(this.YesNo.id);
    return val;
  }
  set values(val: string | number | number[]) {
    this.uiController === UIControlTyp.MultiSelectList ?
      this.YesNos = (val as number[]).map(id => this.getObjectFromList(id)) :
      this.YesNo = this.getObjectFromList(val as number);
  }
  @Output() valuesChange = new EventEmitter();

  /** Enthält die ausgewählten Werte bei UIControlTyp.MultiSelectList */
  public YesNos: YesNo[] = [];
  /** Enthält die ausgewählten Wert bei UIControlTyp.DropDownList */
  public YesNo: YesNo;
  /** Enthält die Werte die angezeigt werden dürfen */
  public YesNoList: YesNo[] = [];
  /** Enthält die Originalliste mit allen Werten */
  private OriginalValues: YesNo[] = [];

  constructor(private modulService: SharedService) { this.initYesNo(); }

  initYesNo() {
    this.modulService.getYesNos()
      .subscribe(apiResult => {
        if (!apiResult.isSuccessful) {
          this.modulService.showNotification(apiResult.message, 'error');
        }
        if (apiResult.payload) {
          this.OriginalValues = apiResult.payload;
          this.YesNoList = this.OriginalValues.filter(s => s.isActive === true);
          if (this.uiController === UIControlTyp.DropDownList) {
            this.YesNoList.unshift(new YesNo().getEmptyModel());
          }
          if (this.uiController === UIControlTyp.MultiSelectList) {
            this.getRefreshSelectedValue();
          }
        }
      },
      err => this.modulService.showNotification(err, 'error'));
  }

  filterYesNoList(value: string) {
    this.YesNoList = this.OriginalValues
      .filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1 && s.isActive === true);
  }

  valueChanged(): void {
    this.valuesChange.emit(this.values);
  }

  getObjectFromList(id: number): YesNo {
    if (id === 0) { return new YesNo().getEmptyModel(); }
    const find = this.OriginalValues.find(unit => unit.id === id);
    return find ? find : new YesNo().setId(id);
  }
  getName() {
    const find = this.getObjectFromList(Number(this.YesNo.id));
    return find ? find.name : '';
  }
  getRefreshSelectedValue() {
    this.YesNos = this.YesNos.map(x => this.OriginalValues.find(y => y.id === x.id));
  }
}
