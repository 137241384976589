import { DDBase } from './DDBase';
import { Observable, Observer } from 'rxjs';
import { ApiResult } from './ApiResult';
export class YesNo extends DDBase {

  /** Hiermit erhalten Sie eine Liste mit den Yes No Werten und ein Default Wert bitte auswählen */
  static getAll(): Observable<ApiResult> {
    return Observable.create((observer: Observer<ApiResult>) => {
      observer.next(
        new ApiResult(
          true,
          'Abfrage GetAll von YesNo war erfolgreich.',
          [
            new YesNo().getModel(1, 'Ja'),
            new YesNo().getModel(2, 'Nein')
          ],
          9999));
    });
  }

  /** Hiermit erhalten Sie einen Boolen Wert für die Auswahl */
  static getBoolFromYesNo(id: number): boolean {
    return id === 1 ;
  }
  static getIdFromBool(value: boolean): number {
    return value ? 1 : 2;
  }

  /** Hiermit erhalten Sie ein neues Objekt in dem die Property id und model gesetzt werden */
  getModel(id: number, name: string): YesNo {
    const newItem = new YesNo();
    newItem.id = id;
    newItem.name = name;
    newItem.isActive = true;
    return newItem;
  }
}
