import { AzurePerson } from './../core/models/AzurePerson';
import { ApiException } from './../core/services/api-clients-generated.service';
import { IApiClient } from './../core/models/IApiClient';
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, Observer } from 'rxjs';
import { map } from 'rxjs/operators';
import { NotificationService, NotificationRef, NotificationSettings } from '@progress/kendo-angular-notification';
import { CustomNotificationComponent } from '../core/custom-notification/custom-notification.component';
import { ApiResult } from '../core/models/ApiResult';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  static isProjectChange = false;
  mockApiURL = environment.mockApiUri;
  apiUrl = environment.backendApiUrl;
  public currentProjectId: number | null;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(public http: HttpClient, private notificationService: NotificationService) { }

  setApiErrorHandling(err: ApiException, sourceMessage: string): Observable<ApiResult> {
    if (environment.production === false) {
      console.log('ApiException :', err);
    }
    return this.getErrorApiResult(
      '<p>Status: ' + err.status + '<br />' +
      'Message: ' + err.message + '<br />' +
      'Source: ' + sourceMessage + '</p>');
  }

  /** A help function that generates an Observable<ApiResult> with an error message. */
  getErrorApiResult(message: string): Observable<ApiResult> {
    return Observable.create((observer: Observer<ApiResult>) => {
      observer.next(new ApiResult(false, message, [], 9999));
    });
  }

  showNotification(message: string, type: 'none' | 'success' | 'warning' | 'error' | 'info') {
    let notificationSettings: NotificationSettings = { 
      animation: { type: 'fade', duration: 400}, 
      content: CustomNotificationComponent,
      type: { style: type, icon: false },
    };

    if (type === 'none' || type === 'success' || type === 'info') {
      notificationSettings.hideAfter = 5000;
      notificationSettings.closable = false;
      notificationSettings.position = {horizontal: 'right', vertical: 'top'};
    } else if (type === 'warning') {
      notificationSettings.hideAfter = 10000;
      notificationSettings.closable = false;
      notificationSettings.position = {horizontal: 'right', vertical: 'top'};
    } else {
      notificationSettings.hideAfter = 100000;
      notificationSettings.closable = true;
      notificationSettings.position = {horizontal: 'center', vertical: 'top'};
      message = "<br/><br/><br/>" + message + "<br/><br/><br/>";
    }
    const notificationRef: NotificationRef = this.notificationService.show(notificationSettings);
    if (notificationRef) {
      // tslint:disable-next-line: no-string-literal
      notificationRef.content.instance.message = message;
      // tslint:disable-next-line: no-string-literal
      notificationRef.content.instance.ignore?.subscribe(() => notificationRef.hide());
    }
  }

  mapToApiResult(items: any, payload: any): ApiResult {
    return new ApiResult(items.isSuccessful, items.message, payload, items.statusCode);
  }
  //#region Common Controller
  getAllFromController(controller: string): Observable<ApiResult> {
    return this.http.get<any>(this.mockApiURL + '/' + controller, this.httpOptions)
      .pipe(
        map(items =>
          new ApiResult(true, 'Abfrage GetAll von ' + controller + ' war erfolgreich.', items, 200)
        )
      );
  }

  getFromIdController(controller: string, id: number): Observable<ApiResult> {
    return this.http.get<any>(this.mockApiURL + '/' + controller + '/' + id, this.httpOptions)
      .pipe(
        map(items =>
          new ApiResult(true, 'Abfrage GetFromId von ' + controller + ' war erfolgreich.', items, 200)
        )
      );
  }

  getFromProjectIdController(controller: string, projectId: number): Observable<ApiResult> {
    return this.http.get<any>(this.mockApiURL + '/' + controller + '?projectId=' + projectId, this.httpOptions)
      .pipe(
        map(items =>
          new ApiResult(true, 'Abfrage GetFromProjectId von ' + controller + ' war erfolgreich.', items, 200)
        )
      );
  }

  addController(controller: string, model: any): Observable<ApiResult> {
    return this.http.post<any>(this.mockApiURL + '/' + controller, model, this.httpOptions)
      .pipe(
        map(items =>
          new ApiResult(true, 'Abfrage Add von ' + controller + ' war erfolgreich.', items, 200)
        )
      );
  }

  removeController(controller: string, id: number): Observable<ApiResult> {
    return this.http.delete(this.mockApiURL + '/' + controller + '/' + id, this.httpOptions)
      .pipe(
        map(items =>
          new ApiResult(true, 'Abfrage Remove von ' + controller + ' war erfolgreich.', items, 200)
        )
      );
  }
  updateController(controller: string, model: any): Observable<ApiResult> {
    return this.http.put(this.mockApiURL + '/' + controller + '/' + model.id, model, this.httpOptions)
      .pipe(
        map(items =>
          new ApiResult(true, 'Abfrage Update von ' + controller + ' war erfolgreich.', items, 200)
        )
      );
  }
  //#endregion

  //#region Crud Api Call
  getAll(apiClient: IApiClient, includeInactive: boolean = false): Observable<ApiResult> {
    return apiClient.getAll(includeInactive);
  }
  getFromId(apiClient: IApiClient, id: number): Observable<ApiResult> {
    return apiClient.getFromId(id);
  }
  getFromProjectId(apiClient: IApiClient, projectId: number): Observable<ApiResult> {
    return apiClient.getFromProjectId(projectId);
  }
  addList(apiClient: IApiClient, models: any[]): Observable<ApiResult> {
    return apiClient.createList(models);
  }
  removeList(apiClient: IApiClient, ids: number[]) {
    return apiClient.removeList(ids);
  }
  updateList(apiClient: IApiClient, models: any[]): Observable<ApiResult> {
    return apiClient.updateList(models);
  }
  //#endregion
}
