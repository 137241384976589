<ng-container *ngIf="uiController === UIControlTyp.MultiSelectList">
    <kendo-multiselect
      textField="name"
      valueField="id"
      [data]="ProjectStateList"
      [filterable]="true"
      (filterChange)="filterProjectStateList($event)"
      [(ngModel)]="ProjectStates"
      placeholder="Wählen Sie einen Wert aus"
      (valueChange)="valueChanged()">
    </kendo-multiselect>
  </ng-container>
<ng-container *ngIf="uiController === UIControlTyp.DropDownList">
  <kendo-dropdownlist
    class="form-control"
    textField="name"
    valueField="id"  
    [data]="ProjectStateList"
    [(ngModel)]="ProjectState"
    (valueChange)="valueChanged()">
  </kendo-dropdownlist>
</ng-container>
<ng-container *ngIf="uiController === UIControlTyp.GridSingleValueField">
  {{ getName() }}
</ng-container>
<ng-container *ngIf="uiController === UIControlTyp.InputFieldReadOnly">
    <input class="form-control" readonly value="{{ getName() }}">
</ng-container>

