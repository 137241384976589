import { enableProdMode, APP_INITIALIZER } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { AppConfigService } from './app/core/services/app-config.service';

if (environment.production) {
  console.log('ProductionMode is activ ' + environment.name);
  enableProdMode();
}

AppConfigService.load(() => {

  platformBrowserDynamic([
 
  ]).bootstrapModule(AppModule)
  .catch(
    err => {
      console.error(err);
    }
    );
});
