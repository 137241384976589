<div class="row" style="margin-top: 50px;">
  <div class="col-xs-9 col-md-9">
    <h4>Projekt Meilensteine</h4>
  </div>
</div>
<div class="row" style="margin-top: 20px">
  <div class="col-sm-12">
    <kendo-grid #grid [data]="view | async" [height]="300" [pageSize]="gridState.take" [skip]="gridState.skip"
      [sort]="gridState.sort" [pageable]="true" [sortable]="true" (dataStateChange)="onStateChange($event)"
      (edit)="editHandler($event)" (cancel)="cancelHandler($event)" (save)="saveHandler($event)"
      (add)="addHandler($event)" (remove)="removeHandler($event)" [navigable]="true">
      <ng-template kendoGridToolbarTemplate>
        <button kendoGridAddCommand>
          <span class="fa fa-pencil"></span><span class="k-icon k-i-plus"></span></button>
      </ng-template>
      <kendo-grid-column *ngIf="false" field="projectId" title="Proj.Id" [width]="1">
        <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
          {{ dataItem.projectId }}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="name" title="Meilenstein" [width]="200">
        <ng-template kendoGridEditTemplate let-column="column" let-formGroup="formGroup" let-isNew="isNew">
          <input #input class="k-textbox" [formControl]="formGroup.get(column.field)">
          <kendo-popup [anchor]="input"
            *ngIf="formGroup.get(column.field).invalid && !(isNew && formGroup.get(column.field).untouched)"
            popupClass="k-widget k-tooltip k-tooltip-validation k-invalid-msg">
            <span class="k-icon k-i-warning"></span>
            Name is required
          </kendo-popup>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="plannedDate" title="Plandatum" format="{0: dd-MM-yyyy}" [width]="200">
        <ng-template kendoGridEditTemplate let-column="column" let-formGroup="formGroup">
          <kendo-datepicker [format]="'dd-MM-yyyy'" [formControl]="formGroup.get(column.field)" placeholder="" ></kendo-datepicker>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="actualDate" title="Istdatum" format="{0: dd-MM-yyyy}" [width]="200">
        <ng-template kendoGridEditTemplate let-column="column" let-formGroup="formGroup">
          <kendo-datepicker [format]="'dd-MM-yyyy'" [formControl]="formGroup.get(column.field)" placeholder=""></kendo-datepicker>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="progressInPercent" title="Fortschritt %" [width]="200">
      </kendo-grid-column>
      <kendo-grid-command-column title="Aktionen" [width]="120">
        <ng-template kendoGridCellTemplate let-isNew="isNew" let-formGroup="formGroup">
          <button kendoGridEditCommand>
            <span class="k-icon k-i-pencil"></span>
          </button>
          <button kendoGridRemoveCommand>
            <span class="fa fa-pencil"></span><span class="k-icon k-i-delete"></span>
          </button>
          <button kendoGridSaveCommand [disabled]="formGroup?.invalid">
            <ng-container *ngIf='isNew'>
              <span class="fa fa-pencil"></span><span class="k-icon k-i-save"></span>
            </ng-container>
            <ng-container *ngIf='!isNew'>
              <span class="fa fa-pencil"></span><span class="k-icon k-i-save"></span>
            </ng-container>
          </button>
          <button kendoGridCancelCommand>
            <span class="fa fa-pencil"></span><span class="k-icon k-i-close"></span>
          </button>
        </ng-template>
      </kendo-grid-command-column>
    </kendo-grid>
  </div>
</div>
