import { SharedService } from './../shared.service';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AcademicPartner } from 'src/app/core/models/AcademicPartner';
import { UIControlTyp } from 'src/app/core/models/UIControlTyp';

@Component({
  selector: 'fdb-academic-partners',
  templateUrl: './academic-partners.component.html',
  styleUrls: ['./academic-partners.component.scss']
})
export class AcademicPartnersComponent {
  public UIControlTyp = UIControlTyp;
  /** Hiermit können Sie den UIControlTyp entscheiden => Default DropDownList */
  @Input() uiController: UIControlTyp = UIControlTyp.DropDownList;
  // tslint:disable-next-line: no-input-rename
  @Input('values')
  get values() {
    const val = this.uiController === UIControlTyp.MultiSelectList ?
      this.AcademicPartners.map(unit => Number(unit.id)) :
      Number(this.AcademicPartner.id);
    return val;
  }
  set values(val: number | (string | number)[]) {
    this.uiController === UIControlTyp.MultiSelectList ?
      this.AcademicPartners = (val as (string | number)[]).map(id => this.getObjectFromList(id)) :
      this.AcademicPartner = this.getObjectFromList(val as number);
  }
  @Output() valuesChange = new EventEmitter();

  /** Enthält die ausgewählten Wert bei UIControlTyp.DropDownList */
  public AcademicPartner: AcademicPartner;
  /** Enthält die ausgewählten Werte bei UIControlTyp.MultiSelectList */
  public AcademicPartners: AcademicPartner[] = [];
  /** Enthält die Werte die angezeigt werden dürfen */
  public AcademicPartnerList: AcademicPartner[] = [];
  /** Enthält die Originalliste mit allen Werten */
  private OriginalValues: AcademicPartner[] = [];

  constructor(private modulService: SharedService) { this.initAcademicPartners(); }

  initAcademicPartners() {
    this.modulService.getAcademicPartners(true)
      .subscribe(apiResult => {
        if (!apiResult.isSuccessful) {
          this.modulService.showNotification(apiResult.message, 'error');
        }
        if (apiResult.payload) {
          this.OriginalValues = apiResult.payload;
          this.AcademicPartnerList = this.OriginalValues.filter(s => s.isActive === true);
          if (this.uiController === UIControlTyp.DropDownList) {
            this.AcademicPartnerList.unshift(new AcademicPartner().getEmptyModel());
          }
          if (this.uiController === UIControlTyp.MultiSelectList) {
            this.getRefreshSelectedValue();
          }
        }
      },
        err => this.modulService.showNotification(err, 'error'));
  }

  filterAcademicPartnerList(value: string) {
    this.AcademicPartnerList = this.OriginalValues
      .filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1 && s.isActive === true);
  }

  valueChanged(): void {
    this.valuesChange.emit(this.values);
  }

  getObjectFromList(id: string | number): AcademicPartner {
    if (id === 0 || id === "") {
      return new AcademicPartner().getEmptyModel();
    }
    id = Number(id);
    const find = this.OriginalValues.find(unit => unit.id === id);
    return find ? find : new AcademicPartner().setId(id);
  }
  getName() {
    const find = this.getObjectFromList(Number(this.AcademicPartner.id));
    if (find) {
      return find.id === 0 ? '-- Bitte pflegen --' : find.name;
    }
    return '';
  }
  getRefreshSelectedValue() {
    this.AcademicPartners = this.AcademicPartners.map(x => this.OriginalValues.find(y => y.id === x.id));
  }
}
