import { AcademicPartnerApiClient } from './../../service/apiClients/AcademicPartnerApiClient';
import { Injectable } from '@angular/core';
import { AdminService } from '../service/admin.service';
import { ListBehaviorService } from '../../service/list-behavior.service';

@Injectable()
export class ListAcademicPartnerService extends ListBehaviorService {

  constructor(adminService: AdminService) {
    super(new AcademicPartnerApiClient(adminService), adminService);
  }
}
