<div class="form-group row">
  <label for="name" class="col-sm-2 col-form-label"><span class="k-required">*</span> Name des Projektes:</label>
  <div class="col-sm-9">
    <input class="form-control" [(ngModel)]="project.name" name="name">
    <div *ngIf="setValidation && (!project.name || project.name.length < 1)" class="alert alert-danger">
        Name ist Pflichtfeld.
    </div>
  </div>
</div>
<div class="form-group row">
  <label for="Betreuer" class="col-sm-2 col-form-label">Betreuer</label>
  <div class="col-sm-4">
    <fdb-advisor [uiController]="UIControlTyp.DropDownList" [(values)]='project.advisorId'></fdb-advisor>
  </div>
  <label for="Projektleiter" class="col-sm-1 col-form-label">Projektstatus</label>
  <div class="col-sm-4">
    <fdb-project-state [uiController]="UIControlTyp.InputFieldReadOnly" [(values)]='project.stateId'></fdb-project-state>
  </div>
</div>
<div class="form-group row">
  <label for="enbwEinheit" class="col-sm-2 col-form-label">Verantwortliche EnBW Einheit</label>
  <div class="col-sm-4">
    <fdb-enbwunit [uiController]="UIControlTyp.DropDownList" [(values)]='project.enbwUnitId'></fdb-enbwunit>
  </div>
  <label for="orgEinheit" class="col-sm-1 col-form-label">Org-Einheit</label>
  <div class="col-sm-4">
      <input type="text" class="form-control" id="orgaUnit" [(ngModel)]='project.orgaUnit'>
  </div>
</div>
<div class="form-group row">
  <label for="Projektleiter" class="col-sm-2 col-form-label">Projektleiter</label>
  <div class="col-sm-4">
    <fdb-project-manager [uiController]="UIControlTyp.DropDownList" [(values)]='project.projectManagerId' (objectChange)='changeProjectmanger($event)'></fdb-project-manager>
  </div>
</div>
<div class="form-group row">
  <label for="telefon" class="col-sm-2 col-form-label">Telefon</label>
  <div class="col-sm-4">
    <input type="text" class="form-control" id="telefon" [(ngModel)]='project.projectManagerPhoneNumber'>
  </div>
</div>
<div class="form-group row">
  <label for="email" class="col-sm-2 col-form-label">E-Mail</label>
  <div class="col-sm-4">
    <input type="email" class="form-control" id="email" [(ngModel)]='project.projectManagerEmail'>
  </div>
</div>
<div class="form-group row">
  <label for="fax" class="col-sm-2 col-form-label">Fax</label>
  <div class="col-sm-4">
    <input type="text" class="form-control" id="fax" [(ngModel)]='project.projectManagerFaxNumber'>
  </div>
</div>
