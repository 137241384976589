import { IApiClient } from 'src/app/core/models/IApiClient';
import { ProjectsClient, DefaultRequestResult, ProjectFilterModel } from './../../core/services/api-clients-generated.service';
import { AppService } from './../app.service';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { IProjectsClient } from 'src/app/core/services/api-clients-generated.service';
import { ApiResult } from 'src/app/core/models/ApiResult';
import { Project } from 'src/app/core/models/Project.Model';
import { ProjectOverview } from 'src/app/core/models/ProjectOverview';
import { ProjectFilter } from 'src/app/core/models/ProjectFilter';

export class ProjectApiClient implements IApiClient {
  private projectClient: IProjectsClient;
  constructor(private appService: AppService) { }
  /*
  //#region implemented Methode
  getAll(includeInactive: boolean): Observable<ApiResult> {
    return this.getProjectClient()
      .getList()
      .pipe(
        map(items =>
          this.appService.mapToApiResult(items, Project.mapFromProjectListItemModels(items.payload)))
      );
  }*/
  getAll(includeInactive: boolean): Observable<ApiResult> {
    const model = new ProjectFilterModel();
    const dt = new Date();
    model.year = dt.getFullYear();
    return this.getProjectClient()
      .getOverviewPOST(model)
      .pipe(
        map(items =>
          this.appService.mapToApiResult(items, ProjectOverview.mapFromProjectOverviewModel(items.payload)))
      );
  }

  getFiltered(model: ProjectFilter): Observable<ApiResult> {
    return this.getProjectClient()
      .getOverviewPOST(ProjectFilter.mapToProjectFilterModel(model))
      .pipe(
        map(items => {
          if (items.isSuccessful) {
            let result = this.appService.mapToApiResult(items, ProjectOverview.mapFromProjectOverviewModel(items.payload));
            return result;
          }
          else {
            return new ApiResult(false, items.message, null, 200);
          }
        }
      ));
  }

  getFromId(id: number): Observable<ApiResult> {
    return this.getProjectClient()
      .get(id)
      .pipe(
        map(items =>
          this.appService.mapToApiResult(items, Project.mapFromProjectModel(items.payload))
        ),
        catchError(err => this.appService.setApiErrorHandling(err, 'ProjectApiClient: getFromId'))
      );
  }
  getFromProjectId(projectId: number): Observable<ApiResult> {
    return this.appService.getErrorApiResult('Project => Method getFromProjectId not implemented\.');
  }
  create(model: any): Observable<ApiResult> {
    return this.getProjectClient()
      .insert(Project.mapToInsertProjectModel(model))
      .pipe(
        map(items =>
          this.appService.mapToApiResult(items, Project.mapFromProjectModel(items.payload))
        ),
        catchError(err => this.appService.setApiErrorHandling(err, 'ProjectApiClient: create'))
      );
  }
  createList(models: any[]): Observable<ApiResult> {
    return this.appService.getErrorApiResult('Project => Method createList not implemented\.');
  }
  remove(): Observable<ApiResult> {
    return this.appService.getErrorApiResult('Project => Method remove not implemented\.');
  }
  removeList(ids: number[]): Observable<ApiResult> {
    return this.appService.getErrorApiResult('Project => Method removeList not implemented\.');
  }
  update(model: any): Observable<ApiResult> {
    return this.getProjectClient()
      .update(Project.mapToUpdateProjectModel(model))
      .pipe(
        map(items =>
          this.appService.mapToApiResult(items, Project.mapFromProjectModel(items.payload))
        ),
        catchError(err => this.appService.setApiErrorHandling(err, 'ProjectApiClient: update'))
      );
  }
  updateState(model: any): Observable<ApiResult> {
    return this.getProjectClient()
      .updateState(Project.mapToUpdateStateModel(model))
      .pipe(
        map(items => {
          return items;
        }

        ),
        catchError(err => this.appService.setApiErrorHandling(err, 'ProjectApiClient: update'))
      );
  }
  
  updateList(models: any[]): Observable<ApiResult> {
    return this.appService.getErrorApiResult('Project => Method updateList not implemented\.');
  }
  //#endregion

  /** Assigns users to a project. The user ids need to be provided as a comma separated string and
   * must be of type int. The ids are parsed and all users in the list are assigned to the project.
   * IMPORTANT: USERS NOT IN THE LIST ARE REMOVED FROM THE PROJECT, therefore this method
   * can be used for both actions.
   */
  assignUserToProject(projectId: number, userIds: string): Observable<ApiResult> {
    return this.getProjectClient()
      .assignUsers(projectId, userIds)
      .pipe(
        map((items: DefaultRequestResult) =>
          this.appService.mapToApiResult(items, [])
        ),
        catchError(err => this.appService.setApiErrorHandling(err, 'ProjectApiClient: assignUserToProject'))
      );
  }

  updateProjectHeader(project: Project): Observable<ApiResult> {
    return this.getProjectClient()
      .updateHeader(Project.mapToUpdateProjectHeaderModel(project))
      .pipe(
        map(items =>
          this.appService.mapToApiResult(items, [])
        ),
        catchError(err => this.appService.setApiErrorHandling(err, 'ProjectApiClient: updateProjectHeader'))
      );
  }

  requestProject(projectId: number, stateid: number, requestMsg: string): Observable<ApiResult> {
    return this.getProjectClient()
      .requestProject(Project.mapToProjectRequestModel(projectId, stateid, requestMsg))
      .pipe(
        map(items =>
          this.appService.mapToApiResult(items, [])
        ),
        catchError(err => this.appService.setApiErrorHandling(err, 'ProjectApiClient: projectRequest'))
      );
  }

  approveProject(projectId: number, stateId: number, requestMsg: string): Observable<ApiResult> {
    return this.getProjectClient()
      .approve(Project.mapToProjectRequestModel(projectId, stateId, requestMsg))
      .pipe(
        map(items =>
          this.appService.mapToApiResult(items, [])
        ),
        catchError(err => this.appService.setApiErrorHandling(err, 'ProjectApiClient: approveProject'))
      );
  }

  rejectProject(projectId: number, stateId: number, requestMsg: string): Observable<ApiResult> {
    return this.getProjectClient()
      .reject(Project.mapToProjectRequestModel(projectId, stateId, requestMsg))
      .pipe(
        map(items =>
          this.appService.mapToApiResult(items, [])
        ),
        catchError(err => this.appService.setApiErrorHandling(err, 'ProjectApiClient: rejectProject'))
      );
  }

  sendPurchaseDepartmentMail(projectId: number): Observable<ApiResult> {
    return this.getProjectClient()
      .sendPurchaseDepartmentMail(projectId)
      .pipe(
        map(items => 
          this.appService.mapToApiResult(items, [])
        ),
        catchError(err => this.appService.setApiErrorHandling(err, 'ProjectApiClient: sendPurchaseDepartmentMail'))
      );
  }

  private getProjectClient(): IProjectsClient {
    if (!this.projectClient) {
      this.projectClient = new ProjectsClient(this.appService.http, this.appService.apiUrl);
    }
    return this.projectClient;
  }

  deleteProject(projectId: number): Observable<ApiResult> {
    return this.getProjectClient()
      .delete(projectId)
      .pipe(
        map(items => {
          return items;
        }),
        catchError(err => this.appService.setApiErrorHandling(err, 'ProjectApiClient: deleteProject'))
      );
  }
}
