import { CostAccountModel } from './../services/api-clients-generated.service';
import { Base } from './Base';


export class ProjectCostAccount extends Base {
  projectId: number;
  year: number;
  pspElement: string;

  static MapFromCostAccountModels(costAccounts: CostAccountModel[]): ProjectCostAccount[] {
    return costAccounts.map(account => this.MapFromCostAccountModel(account));
  }

  static MapFromCostAccountModel(item: CostAccountModel): ProjectCostAccount {
    const newItem = new ProjectCostAccount();
    newItem.id = item.id;
    newItem.projectId = item.projectId;
    newItem.year = item.year;
    newItem.pspElement = item.psp;
    return newItem;
  }
  static MapToUpdateCostAccountModels(items: ProjectCostAccount[]): CostAccountModel[] {
    return items.map(item => this.MapToUpdateCostAccountModel(item));
  }
  static MapToUpdateCostAccountModel(item: ProjectCostAccount): CostAccountModel {
    const newItem = new CostAccountModel();
    newItem.id = Number(item.id);
    newItem.year = item.year;
    newItem.psp = item.pspElement ? item.pspElement : '';
    newItem.projectId = item.projectId;
    return newItem;
  }
}
