import { IMilestonesClient, MilestonesClient } from './../../core/services/api-clients-generated.service';
import { AppService } from './../app.service';
import { IApiClient } from 'src/app/core/models/IApiClient';
import { Observable } from 'rxjs';
import { ApiResult } from 'src/app/core/models/ApiResult';
import { catchError, map } from 'rxjs/operators';
import { ProjectMilestone } from 'src/app/core/models/ProjectMilestone';

export class ProjectMilestoneApiClient implements IApiClient {
  private milestoneClient: IMilestonesClient;
  private controller = 'ProjectMilestones';
  constructor(private appService: AppService) { }

  getAll(includeInactive: boolean): Observable<ApiResult> {
    return this.appService.getAllFromController(this.controller);
  }
  getFromId(id: number): Observable<ApiResult> {
    return this.appService.getErrorApiResult('Method not implemented.');
  }
  getFromProjectId(projectId: number): Observable<ApiResult> {
    return this.GetMilestoneClient()
      .get(projectId)
      .pipe(
        map(items => {
          return this.appService.mapToApiResult(items, ProjectMilestone.MapFromMilestoneMilestoneModels(items.payload));
        }
        ),
        catchError(err => this.appService.setApiErrorHandling(err, 'ProjectMilestoneApiClient: getFromProjectId'))
      );
  }
  create(model: any): Observable<ApiResult> {
    return this.appService.getErrorApiResult('Method not implemented.');
  }
  createList(models: any[]): Observable<ApiResult> {
    return this.GetMilestoneClient()
      .insertList(ProjectMilestone.MapToMilestoneModels(models))
      .pipe(
        map(items =>
          this.appService.mapToApiResult(items, ProjectMilestone.MapFromMilestoneMilestoneModels(items.payload))
        ),
        catchError(err => this.appService.setApiErrorHandling(err, 'ProjectMilestoneApiClient: createList'))
      );
  }
  remove(id: number): Observable<ApiResult> {
    return this.appService.getErrorApiResult('Method not implemented.');
  }
  removeList(ids: number[]): Observable<ApiResult> {
    return this.GetMilestoneClient()
      .deleteList(ids.join())
      .pipe(
        map(items =>
          this.appService.mapToApiResult(items, [])
        ),
        catchError(err => this.appService.setApiErrorHandling(err, 'ProjectMilestoneApiClient: removeList'))
      );
  }
  update(model: any): Observable<ApiResult> {
    return this.appService.getErrorApiResult('Method not implemented.');
  }
  updateList(models: any[]): Observable<ApiResult> {
    return this.GetMilestoneClient()
      .updateList(ProjectMilestone.MapToMilestoneModels(models))
      .pipe(
        map(items =>
          this.appService.mapToApiResult(items, ProjectMilestone.MapFromMilestoneMilestoneModels(items.payload))
        ),
        catchError(err => this.appService.setApiErrorHandling(err, 'ProjectMilestoneApiClient: updateList'))
      );
  }
  private GetMilestoneClient() {
    if (!this.milestoneClient) {
      this.milestoneClient = new MilestonesClient(this.appService.http, this.appService.apiUrl);
    }
    return this.milestoneClient;
  }
}
