import { ProjectCostAccount } from './../../core/models/ProjectCostAccount';
import { AppService } from './../app.service';
import { IApiClient } from 'src/app/core/models/IApiClient';
import { Observable } from 'rxjs';
import { ApiResult } from 'src/app/core/models/ApiResult';
import { ICostsClient, CostsClient } from 'src/app/core/services/api-clients-generated.service';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { ProjectCost } from 'src/app/core/models/ProjectCost';
import { ProjectCostItemAmount } from 'src/app/core/models/ProjectCostItemAmount';

export class ProjectCostApiClient implements IApiClient {
  private costItemClient: ICostsClient;

  private controller = 'ProjectCostItems';
  constructor(private appService: AppService) { }

  getAll(includeInactive: boolean): Observable<ApiResult> {
    return this.appService.getAllFromController(this.controller);
  }
  getFromId(id: number): Observable<ApiResult> {
    return this.appService.getErrorApiResult('ProjectCostItem => Method not implemented\.');
  }
  getFromProjectId(projectId: number): Observable<ApiResult> {
    return this.getCostClient()
      .getForProject(projectId)
      .pipe(
        map(items =>
          this.appService.mapToApiResult(items, ProjectCost.MapFromProjectCost(items.payload))
        ),
        catchError(err => this.appService.setApiErrorHandling(err, 'ProjectCostItemApiClient: getFromProjectId'))
      );
  }
  create(model: any): Observable<ApiResult> {
    return this.appService.getErrorApiResult('ProjectCostItem => Method not implemented\.');
  }
  createList(models: any[]): Observable<ApiResult> {
    return this.appService.getErrorApiResult('ProjectCostItem => Method not implemented\.');
  }
  remove(id: number): Observable<ApiResult> {
    return this.appService.getErrorApiResult('ProjectCostItem => Method not implemented\.');
  }
  removeList(ids: number[]): Observable<ApiResult> {
    return this.appService.getErrorApiResult('ProjectCostItem => Method not implemented\.');
  }
  update(model: any): Observable<ApiResult> {
    return this.appService.getErrorApiResult('ProjectCostItem => Method not implemented\.');
  }
  updateList(models: any[]): Observable<ApiResult> {
    return this.appService.getErrorApiResult('ProjectCostItem => Method not implemented\.');
  }

  createEditValues(projectId: number) {
    return this.getCostClient()
      .createEmptyEditValues(projectId)
      .pipe(
        map(items =>
          this.appService.mapToApiResult(items, ProjectCost.MapFromProjectCost(items.payload))
        ),
        catchError(err => this.appService.setApiErrorHandling(err, 'ProjectCostItemApiClient: createEditValues'))
      );
  }
  updateCostPlanning(projectId: number, costId: number, models: ProjectCostItemAmount[]) {
    return this.getCostClient()
      .updateListAmounts(projectId, ProjectCostItemAmount.MapToUpdateCostAmountModels(costId, models))
      .pipe(
        map(items =>
          this.appService.mapToApiResult(items, items.payload)
        ),
        catchError(err => this.appService.setApiErrorHandling(err, 'ProjectCostItemApiClient: updateCostPlanning'))
      );
  }
  updateCostAccount(models: ProjectCostAccount[]) {
    return this.getCostClient()
      .updateListAccounts(ProjectCostAccount.MapToUpdateCostAccountModels(models))
      .pipe(
        map(items =>
          this.appService.mapToApiResult(items, items.payload)
        ),
        catchError(err => this.appService.setApiErrorHandling(err, 'ProjectCostItemApiClient: updateCostAccount'))
      );
  }

  private getCostClient() {
    if (!this.costItemClient) {
      this.costItemClient = new CostsClient(this.appService.http, this.appService.apiUrl);
    }
    return this.costItemClient;
  }
}
