import { ProjectCostAccount } from './ProjectCostAccount';
import { ProjectCostItem } from './ProjectCostItem';
import { ProjectCostsModel } from '../services/api-clients-generated.service';

export class ProjectCost {
  costs: ProjectCostItem[] = [];
  accounts: ProjectCostAccount[] = [];

  static MapFromProjectCost(item: ProjectCostsModel): ProjectCost {
    const newItem = new ProjectCost();
    newItem.costs = item.costs ? ProjectCostItem.MapFromCostModels(item.costs) : [];
    newItem.accounts = item.costAccounts ? ProjectCostAccount.MapFromCostAccountModels(item.costAccounts) : [];
    return newItem;
  }
}
