import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
// import 'rxjs/operator/catch';
import { ActivatedRoute } from '@angular/router';
import { AppService } from 'src/app/service/app.service';
import { AdloginService } from '../service/adlogin.service';
import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RoutingPath } from '../app-routing.module';
import { AzurePerson } from '../core/models/AzurePerson';
import { ReportClient } from '../core/services/api-clients-generated.service';

@Component({
  selector: 'fdb-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent {
  public AppService = AppService;
  public routingPath = RoutingPath;
  public username = 'unbekannt';
  public isAdmin = false;
  public isAdvisor = false;
  public isApprover = false;

  public hasRoles = false;

  constructor(private adLoginService: AdloginService,
              public appService: AppService,
              private router: ActivatedRoute,
              private http: HttpClient) {
    let currentUserObservable = adLoginService.getCurrentUser();
    if (currentUserObservable) {
      currentUserObservable.subscribe((currentUser: AzurePerson) => {
          if (currentUser) {
            this.username = currentUser.name + ' (' + currentUser.email + ')';
            this.isAdmin = currentUser.isUserAdmin();
            this.isAdvisor = currentUser.isUserAdvisor();
            this.isApprover = currentUser.isUserApprovers();
            this.hasRoles = currentUser.roles.length === 0 ? false : true;
          }
        });
    }
    if (router.pathFromRoot.toString() === this.routingPath.project)
    {

    }
  }

  public logout() {
    this.adLoginService.logout();
  }

  downloadReportAlleProjekte() {

    const reportService = new ReportClient(this.appService.http, this.appService.apiUrl);

    return reportService.getAll()
      .pipe(
          map(res => {
            return {
              filename: 'ReportAlleProjekte.pdf',
              data: res.data
            };
          }))
      .subscribe(res => {
          console.log('start download:',res);
          if (res.data.size > 0) {
            this.downloadHttpResult(res); // TODO: im Else-Zweig noch die Info, dass der Download nicht geklappt hat
          }
        }, error => {
          console.log('download error:', JSON.stringify(error));
        }, () => {
          console.log('Completed file download.')
        });
  }

  downloadReportAktuellesProjekt() {

    const reportService = new ReportClient(this.appService.http, this.appService.apiUrl);

    return reportService.get(this.appService.currentProjectId)
      .pipe(
          map(res => {
            return {
              filename: 'ReportProjekt-' + this.appService.currentProjectId.toString() + '.pdf',
              data: res.data
            };
          }))
      .subscribe(res => {
          console.log('start download:',res);
          if (res.data.size > 0) {
            this.downloadHttpResult(res);
          }
          else
          {
            console.log('downloading current project failed, no data');
          }
       }, error => {
          console.log('download error:', JSON.stringify(error));
        }, () => {
          console.log('Completed file download.')
        });
  }

  downloadReportMeineProjekte() {

    const reportService = new ReportClient(this.appService.http, this.appService.apiUrl);

    return reportService.getMine()
      .pipe(
            map(res => {
              return {
                filename: 'ReportMeineProjekte.pdf',
                data: res.data
              };
            }))
      .subscribe(res => {
          console.log('start download:',res);
          if (res.data.size > 0) {
            this.downloadHttpResult(res);
          }
          else
          {
            console.log('downloading my projects failed, no data');
          }
        }, error => {
          console.log('download error:', JSON.stringify(error));
        }, () => {
          console.log('Completed file download.')
        });
  }

  downloadReportBlacklist() {

    const reportService = new ReportClient(this.appService.http, this.appService.apiUrl);

    return reportService.getBlacklist()
      .pipe(
          map(res => {
            return {
              filename: 'ReportBlacklist.pdf',
              data: res.data
            };
          }))
      .subscribe(res => {
          if (res.data.size > 0) {
            console.log('start download blacklist:',res);
            this.downloadHttpResult(res);
          }
          else
          {
            console.log('downloading blacklist failed, no data');
          }
        }, error => {
          console.log('download error:', JSON.stringify(error));
        }, () => {
          console.log('Completed blacklist file download.')
        });
  }

  downloadReportManagementBlacklist() {

    const reportService = new ReportClient(this.appService.http, this.appService.apiUrl);

    return reportService.getManagementBlacklist()
      .pipe(
          map(res => {
            return {
              filename: 'ReportManagementBlacklist.pdf',
              data: res.data
            };
          }))
      .subscribe(res => {
          if (res.data.size > 0) {
            console.log('start download management blacklist:',res);
            this.downloadHttpResult(res);
          }
          else
          {
            console.log('downloading blacklist failed, no data');
          }
        }, error => {
          console.log('download error:', JSON.stringify(error));
        }, () => {
          console.log('Completed blacklist file download.')
        });
  }

  public downloadReportAlleProjekteExcel(){
    const reportService = new ReportClient(this.appService.http, this.appService.apiUrl);
    // to  be tested
    return reportService.getAllInCSV()
      .pipe(
          map(res => {
            return {
              filename: 'ReportAlleProjekte.csv',
              data: res.data
            };
          }))
      .subscribe(res => {
          console.log('start download:',res);
          if (res.data.size > 0) {
            this.downloadHttpResult(res);
          }
          else
          {
            console.log('downloading blacklist failed, no data');
          }
        }, error => {
          console.log('download error:', JSON.stringify(error));
        }, () => {
          console.log('Completed file download.')
        });
  }

  private downloadHttpResult(res: { filename: string; data: Blob; }) {
    var url = window.URL.createObjectURL(res.data);
    var a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.href = url;
    a.download = res.filename;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }
}
