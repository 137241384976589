import { UpdateLogbookModel } from './../services/api-clients-generated.service';
import { Base } from './Base';
import { LogbookModel, InsertLogbookModel } from '../services/api-clients-generated.service';

export class LogbookItem extends Base {
  projectId: number;
  userFullName: string;
  date: Date;
  info: string;

  static MapFromLogbookModels(items: LogbookModel[]): LogbookItem[] {
    return items.map(item => this.MapFromLogbookModel(item));
  }

  static MapFromLogbookModel(item: LogbookModel): LogbookItem {
    const newItem = new LogbookItem();
    newItem.id = item.id;
    newItem.projectId = item.projectId;
    newItem.userFullName = item.user ? item.user.firstName + ' ' + item.user.lastName : '';
    newItem.date = item.date ? item.date.toDate() : null;
    newItem.info = item.info;
    return newItem;
  }

  static MapToInsertLogbookModels(items: LogbookItem[]): InsertLogbookModel[] {
    return items.map(item => this.MapToInsertLogbookModel(item));
  }

  static MapToInsertLogbookModel(item: LogbookItem): InsertLogbookModel {
    const newItem = new InsertLogbookModel();
    newItem.projectId = item.projectId;
    newItem.info = item.info;
    return newItem;
  }
  
  static MapToUpdateLogbookModels(items: LogbookItem[]): UpdateLogbookModel[] {
    return items.map(item => this.MapToUpdateLogbookModel(item));
  }

  static MapToUpdateLogbookModel(item: LogbookItem): UpdateLogbookModel {
    const newItem = new UpdateLogbookModel();
    newItem.id = Number(item.id);
    newItem.info = item.info;
    return newItem;
  }
}
